import React from 'react';
import { Input, Tooltip, Select } from 'antd';

// interface NumericInputProps {
//     style: React.CSSProperties;
//     value: string;
//     onChange: (value: string) => void;
//   }

  const formatNumber = (value: number) => new Intl.NumberFormat().format(value);

  const NumericInput = (props) => {
    const { value, onChange, keynum, handleontype, hasdel, handledel, typeId, isPay } = props;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value: inputValue } = e.target;
      const reg = /^-?\d*(\.\d*)?$/;
      if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
        onChange(inputValue, keynum);
      }
    };

    // '.' at the end or only '-' in the input box.
    const handleBlur = () => {
    //   let valueTemp = value;
    //   if (value.charAt(value.length - 1) === '.' || value === '-') {
    //     valueTemp = value.slice(0, -1);
    //   }
    //   onChange(valueTemp.replace(/0*(\d+)/, '$1'), keynum);
    };
    const handleSelect = (value) => {
        // console.log(`selected ${value}`);
        handleontype(value, keynum)
      };
    // const title = !value && (
    //   '请输入购买数量'
    // );
    const title = '';
    return (
      <Tooltip trigger={['focus']} title={title} placement="topLeft" overlayClassName="numeric-input">
        <div className="choose-box">
            {(hasdel && !isPay) && <div className="sc">
                <i
                    className="iconfont iconpicture-delet"
                    onClick={() => {
                        // console.log(hasdel)
                        if(hasdel) {
                            handledel(keynum)
                        }
                    }}
                />
            </div>}
            <div className="lx">
                <p>会员类型</p>
                {typeId ? <Select
                    disabled={isPay}
                    defaultValue="请选择会员类型"
                    className="choose-select"
                    onChange={ handleSelect }
                    value={typeId}
                    options={[
                        // { value: '1', label: '学生会员' },
                        { value: '2', label: '个人会员' },
                        { value: '4', label: '尊享会员' },
                        { value: '3', label: '家庭会员'},
                    ]}
                /> : <Select
                    disabled={isPay}
                    // defaultValue="请选择会员类型"
                    value="请选择会员类型"
                    className="choose-select"
                    onChange={ handleSelect }
                    options={[
                        // { value: '1', label: '学生会员' },
                        { value: '2', label: '个人会员' },
                        { value: '4', label: '尊享会员' },
                        { value: '3', label: '家庭会员'},
                    ]}
                />}
            </div>
            <div className="num">
                <p>购买数量</p>
                <div className="r">
                    {!isPay && <button
                        className="subtract"
                        onClick={() => {
                            if(Number(value) >= 1) {
                                const val =Number(value) - 1;
                                onChange(val, keynum);
                            }
                        }}
                    >
                        -
                    </button>}
                    <Input
                    // {...props}
                    disabled={isPay}
                    value={value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    // placeholder="请输入购买数量"
                    maxLength={100}
                    style={{ width: '5rem', backgroundColor: '#fff', borderWidth: '1px',borderRadius:0, height: '2.5rem', boxShadow: 'unset', borderColor:'#d9d9d9' }}
                    />
                    {!isPay && <button
                        className='add'
                        onClick={() => {
                            if(!value) {
                                onChange(1, keynum);
                            } else {
                                const val =Number(value) + 1;
                                onChange(val, keynum);
                            }
                        }}
                    >
                        +
                    </button>}
                </div>
            </div>
        </div>
      </Tooltip>
    );
  };

  export default NumericInput;
