/* eslint-disable complexity */
/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { localDS } from '../../storage';
import {
    memberBasicInfo,
    queryMemberInfoById,
    queryMemberTypeById,
    queryCertList,
    getFamilyInfo,
    checkCanRenew,
    checkHasUnpaidOrder
} from '../../service';
import './style.scss';
import family from '@/assets/personal/family.png';
import student from '@/assets/personal/student.png';
import premium from '@/assets/personal/premium.png';
import regular from '@/assets/personal/regular.png';
import hornor from '@/assets/personal/hornor.png';
import headImg from '@/assets/personal/headpic.png';
import family2 from '@/assets/personal/family2.png';
import student2 from '@/assets/personal/student2.png';
import premium2 from '@/assets/personal/premium2.png';
import regular2 from '@/assets/personal/regular2.png';
import hornor2 from '@/assets/personal/hornor2.png';
import { Modal, Image } from 'antd';
import QRcode from 'qrcode.react';
import { history } from '../../routes';
import app, { $action } from '../../model';

const DealId = (id) => {
    const val = encodeURIComponent(id + Date.now() + '?type=1');
    return val;
}

const Personal = () => {
    const memberId = localDS.get('memberId');
    const [memberInfo, setMemberInfo] = useState({});
    const [typeInfo, setTypeInfo] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const memberPic = [student, regular, family, premium, hornor];
    const memberPic2 = [student2, regular2, family2, premium2, hornor2];
    const name = localDS.get('username');
    const [familyinfo, setFamilyinfo] = useState();
    const [ewmCode, setEwmCode] = useState('');
    const [time, setTime] = useState(60);
    useEffect(() => {
        (async function() {})();
        queryCertList().then((res) => {
            // 实名认证身份证号、手机号
            let documentNumber = res?.data && res?.data[0]?.showDocumentNumber;
            // window.documentTypeName = documentNumber;
            // let phoneNumber = res?.data && res?.data[0]?.phoneNumber;
            getFamilyInfo(documentNumber).then((res) => {
                setFamilyinfo(res?.data);
            });
            memberBasicInfo(documentNumber).then((res) => {
                console.log(res)
                if(!res) {
                    app.dispatch(
                        $action('alert').emit({
                            show: true,
                            title: '提示',
                            content: '未获取到会员信息'
                        })
                    );
                } else {
                    if(!res?.data || (res?.data === {})) {
                        app.dispatch(
                            $action('alert').emit({
                                show: true,
                                title: '提示',
                                // content: `暂无信息,${res?.data},${res?.fullres?.data},${JSON.stringify(res?.fullres?.data)},${JSON.stringify(res?.fullres?.data?.data)}`
                                content: '未查询到会员信息'
                            })
                        );
                    }
                }
                if(res?.data) {
                    const id = res?.data?.id;
                    setMemberInfo(res?.data);
                    if (id) {
                        localDS.set('memberId', id);
                        queryInfo(id);
                    } else {
                        queryInfo(memberId);
                    }

                }
            });
        });
        setEwmCode(DealId(memberId))

        // 清除申请缓存
        localDS.remove('orderParam');
    }, []);

    const queryInfo = (id) => {
        if (!id) return;
        (async function() {
            let info = await queryMemberInfoById({ id });
            // console.log(info?.data)
            // if(info?.data) {
            //     setMemberInfo(info?.data);
            // }
            let typeId = info?.data?.typeId;
            let typeInfo = await queryMemberTypeById({ id: typeId });
            setTypeInfo(typeInfo?.data);
        })();
    };

    useEffect(() => {
        if(memberInfo?.isVip === 1) {
            const timer = setInterval(() => {
                if(time>0){
                    setTime(time-1);
                }else{
                    setTime(60);
                    setEwmCode(DealId(memberId))
                }
            }, 1000);
            return () => {
                clearInterval(timer);
            }
        }
    }, [time, memberInfo]);
    const cert = localDS.get('cert');
    return (
        <>
            <div className="personal">
                <div className="headpic">
                    {/* <i className='iconfont icon-user-full' /> */}
                    {memberInfo && memberInfo?.photo ? (
                        <Image src={window.FILE_IMG_URL + memberInfo?.photo} className="headimg" />
                    ) : (
                        <Image src={headImg} preview={false} className="headimg" />
                    )}
                </div>
                <div className="personal-box">
                    <div className="personmsg">
                        <div className="identify">
                            {/* <span>{memberInfo?.memberName || name || cert?.customerName}</span> */}
                            <span>{(memberInfo?.memberName || name || cert?.customerName)}</span>
                            {memberInfo?.typeId && memberInfo?.isVip === 1 && (
                                <div>
                                    <img src={memberPic[memberInfo?.typeId - 1]} alt="" />
                                </div>
                            )}
                            {memberInfo?.typeId && memberInfo?.state === 2 && (
                                <div>
                                    <img src={memberPic2[memberInfo?.typeId - 1]} alt="" />
                                </div>
                            )}
                        </div>
                        {memberInfo && memberInfo?.isVip === 1 && memberInfo?.cardNo && (
                            <p className="cardid">实体卡号：{memberInfo?.cardNo}</p>
                        )}
                        {memberInfo?.expireTime && (memberInfo?.isVip === 1 || memberInfo?.state === 2) && (
                            <p className="expire">会员到期时间：{memberInfo?.expireTime}</p>
                        )}
                    </div>
                    {memberInfo && (
                        <div className="qrcode">
                            {memberInfo?.isVip === 1 && (
                                <div
                                    className="hym"
                                    onClick={() => {
                                        setTime(60);
                                        setEwmCode(DealId(memberId));
                                        setIsModalOpen(true);
                                    }}
                                >
                                    <i className="iconfont icon-huiyuanma-" />
                                    <p>会员电子码</p>
                                </div>
                            )}
                            <Modal
                                title=""
                                open={isModalOpen}
                                onCancel={() => {
                                    setIsModalOpen(false);
                                }}
                                footer={null}
                                // width='20rem'
                                style={{ width: '2px' }}
                                className="ewm-model"
                                centered
                            >
                                <div
                                    className="qrcode-box"
                                    style={{ display: 'flex', justifyContent: 'center' }}
                                >
                                    <p className="title">清华艺博会员电子码</p>
                                    <QRcode includeMargin value={ewmCode} />
                                    <p
                                        className="sx"
                                        onClick={() => {
                                            setTime(60);
                                            setEwmCode(DealId(memberId));
                                        }}
                                    >点此刷新二维码</p>
                                </div>
                            </Modal>
                            {(memberInfo?.isVip === 1 || memberInfo?.state === 2) && (
                                <div className="qxf">
                                    <span
                                        onClick={() => {
                                            checkCanRenew(memberInfo?.id).then(res => {
                                                if(res?.data) {
                                                    // history.push('/vipclassify')
                                                    checkHasUnpaidOrder(memberInfo?.id).then(rs => {
                                                        if(rs?.data) {
                                                            // app.dispatch(
                                                            //     $action('alert').emit({
                                                            //         show: true,
                                                            //         content: '您有尚未支付的订单，请先支付后再进行操作'
                                                            //     })
                                                            // );
                                                            app.dispatch(
                                                                $action('confirm').emit({
                                                                    open: true,
                                                                    content: '您有一笔待支付的会员申请订单，请勿重复提交，并于15分钟内完成支付。若想取消该订单，请点击“申请记录”取消订单后再进行其他操作。',
                                                                    okText: '申请记录',
                                                                    cancelText: '关闭',
                                                                    okCallback: () => {
                                                                        history.push('/applyrecords');
                                                                    }
                                                                })
                                                            );
                                                        } else {
                                                            // history.push('/vipclassify?renew=true')
                                                            app.dispatch(
                                                                $action('alert').emit({
                                                                    show: true,
                                                                    title: '提示',
                                                                    content: '尊敬的会员，您好，当前会员到期后不再支持会员实体卡入馆，续费成功后，在新的会员周期，请使用会员中心右上角会员电子码入馆，感谢您的支持！',
                                                                    callback: () => {
                                                                        history.push('/vipclassify?renew=true')
                                                                    }
                                                                })
                                                            );
                                                        }
                                                    })
                                                } else {
                                                    app.dispatch(
                                                        $action('alert').emit({
                                                            show: true,
                                                            title: '提示',
                                                            content: res?.msg
                                                        })
                                                    );
                                                }
                                            })
                                        }}
                                    >
                                        会员续费
                                        <i className="iconfont icon-jiantou" />
                                    </span>
                                    <Modal
                                        title="提示"
                                        open={open}
                                        onOk={() => {
                                            setOpen(false);
                                            history.push('/vipclassify')
                                        }}
                                        onCancel={() => {
                                            setOpen(false);
                                        }}
                                        okText="确认"
                                        cancelText="取消"
                                    >
                                        <p>{memberInfo?.state === 2 ? '续费成功后，立即享有相关会员权益，是否确认续费？' : '您当前已是会员，续费后的会员权益将在当前会员过期后立即生效，是否确认续费？'}</p>
                                    </Modal>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <ul className="lists">
                {memberInfo && memberInfo?.memberName && (
                    <li className="list-item list-item1">
                        <Link to="/myinfo">
                            <i className="iconfont icon-xinxi" />
                            <span>个人信息</span>
                            <i className="iconfont icon-jiantou" />
                        </Link>
                    </li>
                )}
                {/* <li className="list-item list-item2">
                    <Link to="/purchasecard">
                        <i className="iconfont icon-lipinka" />
                        <span>购买分享卡</span>
                        <i className="iconfont icon-jiantou" />
                    </Link>
                </li> */}
                <li className="list-item list-item3">
                    <Link to="/applyrecords">
                        <i className="iconfont icon-wodeshenqing" />
                        <span>申请记录</span>
                        <i className="iconfont icon-jiantou" />
                    </Link>
                </li>
                {((typeInfo?.typeName === '家庭会员' &&
                    (memberInfo?.isVip === 1 || memberInfo?.state === 2)) ||
                    familyinfo?.length > 0) && (
                    <li className="list-item list-item4">
                        <Link
                            to={
                                typeInfo?.typeName === '家庭会员' &&
                                (memberInfo?.isVip === 1 || memberInfo?.state === 2)
                                    ? '/familymsg'
                                    : `/familymsg?memberId=${familyinfo[0]?.memberId}`
                            }
                        >
                            <i className="iconfont icon-jiatinggongji" />
                            <span>家人信息</span>
                            <i className="iconfont icon-jiantou" />
                        </Link>
                    </li>
                )}

                {typeInfo?.typeName === '尊享会员' &&
                    (memberInfo?.isVip === 1 || memberInfo?.state === 2) && (
                        <li className="list-item list-item5">
                            <Link to="/takemember">
                                <i className="iconfont icon-tianchongxing-" />
                                <span>携带成员</span>
                                <i className="iconfont icon-jiantou" />
                            </Link>
                        </li>
                    )}
                <li className="list-item list-item7">
                    <Link to="/gifts">
                        <i className="iconfont icon-lipin" />
                        <span>我的礼物</span>
                        <i className="iconfont icon-jiantou" />
                    </Link>
                </li>
                <li className="list-item list-item6">
                    <Link to="/giftcard">
                        <i className="iconfont icon-lipinka" />
                        <span>分享卡</span>
                        <i className="iconfont icon-jiantou" />
                    </Link>
                </li>
                {/* <li className="list-item list-item8">
                    <Link to="/MemberEquity?type=physical">
                        <i className="iconfont icon-zhanghaobangding" />
                        <span>绑定会员卡</span>
                        <i className="iconfont icon-jiantou" />
                    </Link>
                </li> */}
                {memberInfo?.id && (
                    <li className="list-item list-item9">
                        <Link to="/track">
                            <i className="iconfont icon-zuji" />
                            <span>我的足迹</span>
                            <i className="iconfont icon-jiantou" />
                        </Link>
                    </li>
                )}

                {/* 记得注掉 */}
                {/* <li onClick={() => {
                    const jumpUrl=encodeURIComponent(`/cardsinfor?id=4&cardId=666`);
                    const initUrl=encodeURIComponent(`/giftcard`);
                    wx.miniProgram.navigateTo({url: `../main/shareVipCard?isWebviewBack=true&jumpUrl=${jumpUrl}&initUrl=${initUrl}`})
                }}>去赠送测试按钮</li>
                <li onClick={() => {
                     wx.miniProgram.navigateTo({url: `../main/vipQinghuaren?isWebviewBack=true&id=2&memberId=666&cost=777&orderId=888&openType=1`})
                }}> <h1>清华人跳转按钮</h1> </li> */}
            </ul>
        </>
    );
};

export default Personal;
