import React, { Component } from 'react';
import { Button }from '../../shared/Button';
import { queryMemberActPageList, memberBasicInfo } from '../../service';
import { history } from '../../routes';
import { Link } from 'react-router-dom';
import imgsrc from "@/assets/zwsj.png";
import { Modal } from 'antd';
import { localDS } from '../../storage';
import './style.scss';

class Dynamic extends Component {
    state = {
        data:[],
        loading: true,
        memberInfor: null,
        open:false,
    };
    componentDidMount() {
        queryMemberActPageList({ pageSize:0, pageNum:0 }).then(res => {
            this.setState({data: res?.data?.records, loading: false})
        })
        let documentNumber = localDS.get('documentNumber');
        !this.state.memberInfor && memberBasicInfo(documentNumber).then(res => {
            if(res?.data) {
                this.setState({memberInfor: res?.data})
            }
        })
    }
    render() {
        const { data, loading, memberInfor, open } = this.state;
        const isVip = new URLSearchParams(window.location.search).get('isVip');
        return (
            <div className="dynamic-page">
                {!loading ? <ul>
                {data?.length > 0 ? data?.map((item, index) => {
                    return (
                        <li key={index}>
                            <Link to={`/dynamic/${item.id}`}>
                            <p className="title">{item.actName}</p>
                            <p className="time">{item.pubdate}</p>
                            </Link>
                        </li>
                    )
                }) : <div className="zwsj"><img src={imgsrc} alt="" /></div>}
                </ul> : <div className="loading">loading...</div>}
                {/* {isVip ? <div className="btn-group">
                    <Button
                        clsname='reverse'
                        onClick={() => {
                            history.push(`/charter`)
                        }}
                    >
                        会员章程
                    </Button>
                    <Button
                        onClick={() => {
                            history.push(`/personal`)
                        }}
                    >
                        会员中心
                    </Button>
                </div> : } */}
                <div className="fullbtn">
                    <Button
                        onClick={() => {
                            if(memberInfor?.isVip === 1) {
                                this.setState({open: true})
                            } else {
                                history.push(`/charter?isvip=true`)
                            }
                        }}
                    >申请成为会员</Button>
                </div>
                <Modal
                    title="提示"
                    open={open}
                    onOk={() => {
                        this.setState({open: false})
                    }}
                    onCancel={() => { this.setState({open: false})}}
                    okText="确认"
                    cancelButtonProps={{ style: { display: 'none' } }}
                >
                <p>您已是清华大学艺术博物馆会员。</p>
                </Modal>
            </div>
        );
    }
}

export default Dynamic;
