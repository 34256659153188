import React, { useEffect, useState } from 'react';
import { memberBasicInfo, checkCanRenew, checkHasUnpaidOrder, queryMemberInfoById } from '../../service';
import { history } from '../../routes';
import { Link } from 'react-router-dom';
import app, { $action } from '../../model';
import { localDS } from '../../storage';
import './style.scss';

const Vipclassify = () => {
    // const [viplist, setViplist] = useState([]);
    // useEffect(() => {
    //     queryMemberType().then(res => {
    //         const records = res?.data?.records;
    //         if(records) {
    //             setViplist(records.slice(0, 4))
    //         }
    //     })
    // }, [])

    const [memberInfo, setMemberInfo] = useState({});
    const [isRequest, setIsRequest] = useState(false);
    useEffect(() => {
        // 清除申请缓存
        localDS.remove('orderParam');

        let documentNumber = localDS.get('documentNumber');
        memberBasicInfo(documentNumber).then((res) => {
            setMemberInfo(res?.data);
            setIsRequest(true);
        });
    }, [])
    const checkOrder = (type) => {
        checkHasUnpaidOrder(memberInfo?.id).then(rs => {
            queryMemberInfoById({id: memberInfo?.id}).then(r => {
                const state = r?.data?.state;
                if(state && state === 4) {
                    app.dispatch(
                        $action('alert').emit({
                            show: true,
                            title: '提示',
                            content: '当前会员处于待审核状态，请等待审核完成后再操作。',
                            btnText: '关闭',
                        })
                    );
                } else {
                    if(rs?.data) {
                        app.dispatch(
                            $action('confirm').emit({
                                open: true,
                                content: '您有一笔待支付的会员申请订单，请勿重复提交，并于15分钟内完成支付。若想取消该订单，请点击“申请记录”取消订单后再进行其他操作。',
                                okText: '申请记录',
                                cancelText: '关闭',
                                okCallback: () => {
                                    history.push('/applyrecords');
                                }
                            })
                        );
                    } else {
                        history.push(`/vipchoose?id=${type}`);
                    }

                }
            })
        })
    }
    const handleJump = (type) => {
        const param = new URLSearchParams(window.location.search);
        const renew = param.get('renew');
        if(memberInfo?.id && !renew) {
            if(memberInfo?.isVip === 1) {
                app.dispatch(
                    $action('alert').emit({
                        show: true,
                        title: '提示',
                        content: '您已是清华大学艺术博物馆会员。'
                    })
                );
            } else {
                checkOrder(type);
            }
        } else {
            history.push(`/vipchoose?id=${type}`);
        }
    }
    return (
        <div className="vip-classify">
            {/* <Link className="jumpcard" to="/purchasecard" />
            <div className="top-bg">
                <div className="img" />
            </div> */}
            {isRequest && <ul className="vip-list">
                {/* <li onClick={() => {history.push(`/vipchoose?id=1`)}} /> */}
                <li
                    onClick={() => {
                        // history.push(`/vipchoose?id=2`);
                        handleJump('2')
                    }}
                />
                <li
                    onClick={() => {
                        // history.push(`/vipchoose?id=4`);
                        handleJump('4')
                    }}
                />
                <li
                    onClick={() => {
                        // history.push(`/vipchoose?id=3`);
                        handleJump('3')
                    }}
                />
                <li
                    onClick={() => {
                        app.dispatch(
                            $action('alert').emit({
                                show: true,
                                title: '提示',
                                content: '“荣誉会员”实行邀请制，为本馆作出杰出贡献者，可受邀成为。暂不支持线上办理，感谢您的理解。'
                            })
                        );
                    }}
                />
            </ul>}
            <div className="jump-box">
                <Link className="jump-btn" to="/purchasecard">购买分享卡</Link>
            </div>
        </div>
    );
};

export default Vipclassify;
