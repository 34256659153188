import React, { useState, useEffect } from 'react';
import { queryFamilyInfoById } from '../../service';
import QRcode from 'qrcode.react';
import {hideId} from '../../utils/validator'
import './style.scss';

const DealId = (id) => {
    const val = encodeURIComponent(id + Date.now() + '?type=1');
    // console.log(val , '修改')
    return val;
}

const FamilyMemberUse = (props) => {
    const param = new URLSearchParams(props.location.search);
    const id = param.get('id');
    const type = param.get('type');
    const [data, setData] = useState();
    const [ewmCode, setEwmCode] = useState('');
    const [time, setTime] = useState(60);
    useEffect(() => {
        queryFamilyInfoById({id}).then((res) => {
            setData(res?.data)
        })
        setEwmCode(DealId(id))
    }, []);
    useEffect(() => {
        const timer = setInterval(() => {
            console.log(time)
            if(time>0){
                setTime(time-1);
            }else{
                setTime(60);
                setEwmCode(DealId(id))
            }
        }, 1000);
        return () => {
            clearInterval(timer);
        }
    }, [time]);
    return (
        <div className="family-member-use allcenter">
            <div className="ewm">
                {/* <p>{`${type ? '携带' : '家庭'}成员会员电子码`}</p> */}
                <p>清华艺博会员电子码</p>
                <div className="qrcode-box" style={{display: 'flex', justifyContent: 'center'}}>
                    <QRcode includeMargin size={120} value={ewmCode} />
                    <p
                        className="sx"
                        onClick={() => {
                            setTime(60);
                            setEwmCode(DealId(id))
                        }}
                    >点此刷新二维码</p>
                </div>
            </div>
            {data && <div className="infor">
                <div className="pic">
                    <img src={window.FILE_IMG_URL + data?.photo} alt="" />
                </div>
                <div className="detail">
                    <h2>{data?.name} {!type && <span>{data?.ageType ? ' (已成年)' : ' (未成年)'}</span>}</h2>
                    <p>联系电话：{hideId(data?.phone)}</p>
                    <p>证件号码：{hideId(data?.documentNumber)}</p>
                </div>
            </div>}
        </div>
    )
}

export default FamilyMemberUse;
