/* eslint-disable no-undef */
/* eslint-disable complexity */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, message } from 'antd';
import { getOrderById, checkCanInvoicing } from '../../service';
import { history } from '../../routes';
import app, { $action } from '../../model';
const types = {
    memberType: {
        '1': '学生会员',
        '2': '个人会员',
        '3': '家庭会员',
        '4': '尊享会员',
        '5': '荣誉会员'
    },
    // state: {
    //     '0': '',
    //     '1': 'record-nokeep',
    //     '2': 'record-overtime'
    // },
    payLink: {
        '0': '/memberEdit?type=student',
        '1': '/familymsg'
    },
    payWord: {
        '0': '待提交>',
        '1': '待支付>',
        '2': '已完成',
        '3': '已取消',
        '4': '待审核',
        '5': '已拒绝'
    },
    payColor: {
        '0': 'willSubmit',
        '1': 'willSubmit',
        '2': 'greeen',
        '3': 'gray',
        '4': 'gray',
        '5': 'gray'
    }
};

const PayLink = (props) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [tsOpen, setTsOpen] = useState(false);
    const Rest = () => {
        setTsOpen(false);
        // eslint-disable-next-line no-unused-expressions
        props?.handleReset();
    };
    const {isQHR} = props;
    const addStr = isQHR?.length > 0 ? `&isQHR=${isQHR}` : '';
    return (
        <>
            {props?.payCode === 1 && (
                <Modal
                    title="提示"
                    open={tsOpen}
                    onOk={() => {
                        Rest();
                    }}
                    onCancel={() => {
                        Rest();
                    }}
                    okText="确定"
                    cancelButtonProps={{ style: { display: 'none' } }}
                >
                    <p>该订单已取消，请重新下单。</p>
                </Modal>
            )}
            {props?.data?.operationType === 'POI0106' && props?.payCode === 1 ? (
                <span className="paylink">
                    {contextHolder}
                    <a
                        className="willSubmit"
                        onClick={(e) => {
                            e.preventDefault();
                            getOrderById(props?.data?.id).then((res) => {
                                console.log(res);
                                if (res?.data?.ttl > 0 && res?.data?.showState === 1) {
                                    history.push(
                                        `/purchasecard?orderId=${props?.data?.id}&isPay=true`
                                    );
                                } else {
                                    setTsOpen(true);
                                }
                            });
                            // createOrder({id: props?.data?.id}).then(res => {
                            //     if(res?.code !== 200){
                            //         messageApi.open({
                            //             type: 'error',
                            //             content: res?.msg,
                            //         });
                            //         return;
                            //     }else{
                            //         console.log('跳转', res.data);
                            //         // 支付跳转
                            //         const obj = encodeURIComponent(JSON.stringify(res?.data))
                            //         // eslint-disable-next-line no-undef
                            //         wx.miniProgram.navigateTo({url: `../main/vipPay?isWebviewBack=true&openType=2&obj=${obj}&orderId=${props?.data?.id}&type=cardspass`})
                            //     }
                            // })
                        }}
                    >
                        待支付&gt;
                    </a>
                </span>
            ) : (
                <span className="paylink">
                    {props?.payCode === 1 && (
                        <a
                            // to={`/memberEdit?id=${props?.data?.memberType}&memberId=${props?.data?.memberId}&cost=${props?.data?.payAmount}&orderId=${props?.data?.id}`}
                            className={types?.payColor?.[props?.payCode]}
                            onClick={(e) => {
                                e.preventDefault();
                                getOrderById(props?.data?.id).then((res) => {
                                    console.log(res);
                                    if (res?.data?.ttl > 0 && res?.data?.showState === 1) {
                                        history.push(
                                            `/memberEdit?id=${props?.data?.memberType}&memberId=${props?.data?.memberId}&cost=${props?.data?.payAmount}&orderId=${props?.data?.id}${addStr}`
                                        );
                                    } else {
                                        setTsOpen(true);
                                    }
                                });
                            }}
                        >
                            {types?.payWord?.[props?.payCode]}
                        </a>
                    )}
                    {props?.payCode === 0 && (
                        <Link
                            to={`/addMembers?orderId=${props?.data?.id}${addStr}`}
                            className={types?.payColor?.[props?.payCode]}
                        >
                            {types?.payWord?.[props?.payCode]}
                        </Link>
                    )}
                    {props?.payCode !== 0 && props?.payCode !== 1 && (
                        <Link
                            to={types?.payLink?.[props?.payCode]}
                            className={types?.payColor?.[props?.payCode]}
                        >
                            {types?.payWord?.[props?.payCode]}
                        </Link>
                    )}
                </span>
            )}
        </>
    );
};

const Record = (props) => {
    const [itemActive, setItemActive] = useState('');
    const [arrowActive, setArrowActive] = useState('');
    const [open, setOpen] = useState(false);
    const {isQHR} = props;
    const datas = [
        props?.data?.operationType && (
            <p key="operationType" className="operationType">
                <span>操作类型：</span>
                {props?.operation?.[props?.data?.operationType]}
                {types?.payWord?.[props?.data?.showState] && (
                    <PayLink
                        isQHR={isQHR}
                        handleReset={props?.handleReset}
                        payCode={props?.data?.showState}
                        data={props?.data}
                    />
                )}
            </p>
        ),
        props?.data?.operationType === 'POI0106' && (
            <p className="flex-p" key="memberType2">
                <span className="span-title">会员类型：</span>
                <p>
                    {props?.data?.memberTypeDetails?.map((item, index) => {
                        return (
                            <span className="whole" key={index}>
                                {item}
                            </span>
                        );
                    })}
                </p>
            </p>
        ),
        props?.data?.memberType && props?.data?.operationType !== 'POI0106' && (
            <p key="memberType">
                <span>会员类型：</span>
                {types?.memberType?.[props?.data?.memberType]}
            </p>
        ),
        props?.data?.effectiveStartDate && props?.data?.showState !== 4 && props?.data?.showState !== 5 && (
            <p key="effectiveDate">
                <span>生效时间：</span>
                {props?.data?.effectiveStartDate}至{props?.data?.effectiveEndDate}
            </p>
        ),
        props?.data?.payAmount && (
            <p key="payAmount">
                <span>支付金额：</span>￥{props?.data?.payAmount}
            </p>
        ),
        // props?.data?.handledBy && (
        //     <p key="handledBy">
        //         <span>办理人：</span>
        //         {props?.data?.handledBy}
        //     </p>
        // ),
        // props?.data?.handledByPhone && (
        //     <p key="handledByPhone">
        //         <span>办理人联系方式：</span>
        //         {props?.data?.handledByPhone}
        //     </p>
        // ),
        props?.data?.source && (
            <p key="source">
                <span>来源：</span>
                {props?.source?.[props?.data?.source]}
            </p>
        ),
        props?.data?.orderNo && (
            <p key="orderNo">
                <span>订单编号：</span>
                {props?.data?.orderNo}
            </p>
        ),
        props?.data?.createTime && (
            <p key="createTime">
                <span>申请时间：</span>
                {props?.data?.createTime}
            </p>
        ),
        props?.data?.refundReason && props?.data?.showState.toString() === '5' && (
            <p key="createTime">
                <span>拒绝原因：</span>
                {props?.data?.refundReason}
            </p>
        )
    ].filter(Boolean);
    const cancel = () => {
        // eslint-disable-next-line no-unused-expressions
        props?.handleCancel(props?.data?.id, props?.data?.payState);
    };
    return (
        <li
            className={`record-item item-shadow ${
                types?.state?.[props?.data?.showState] ? types?.state?.[props?.data?.showState] : ''
            } ${itemActive}`}
        >
            <div className="record-collapse-head">{datas.slice(0, 4)}</div>
            <div className="record-collapse-body">{datas.slice(4)}</div>
            <i
                className={`iconfont icon-xiajiantou ${arrowActive} `}
                onClick={() => {
                    setItemActive(itemActive === 'record-active' ? 'record-blur' : 'record-active');
                    setArrowActive(arrowActive === 'arrow-rotate' ? 'arrow-back' : 'arrow-rotate');
                }}
            />
            {(props?.data?.showState === 0 || props?.data?.showState === 1) && (
                <>
                    <Modal
                        title="提示"
                        open={open}
                        onOk={() => {
                            setOpen(false);
                            cancel();
                        }}
                        onCancel={() => {
                            setOpen(false);
                        }}
                        okText="确认"
                        cancelText="取消"
                    >
                        <p>确认取消订单？</p>
                    </Modal>
                    <button
                        className="cancel-btn"
                        onClick={() => {
                            // setOpen(true);
                            app.dispatch(
                                $action('confirm').emit({
                                    open: true,
                                    content: '确认取消订单？',
                                    okText: '确认',
                                    cancelText: '关闭',
                                    okCallback: () => {
                                        cancel();
                                    }
                                })
                            );
                        }}
                    >
                        取消订单
                    </button>
                </>
            )}
            {/* 去掉按钮 */}
            {([2,4,5].includes(props?.data?.showState) && props?.data?.payAmount && (Number(props?.data?.payAmount) > 0)) ? <button
                className="cancel-btn"
                onClick={() => {
                    if(props?.data?.invoiceState === 1 || props?.data?.invoiceState === 2) {
                        const id = encodeURIComponent(`${props?.data?.orderNo}#${props?.data?.random}`);

                        // h5详情页
                        // history.push({
                        //     pathname: `/invoicedetail`,
                        //     search: `?id=${id}`
                        // });

                        // 跳转小程序详情页
                        console.log('跳详情')
                        wx.miniProgram.navigateTo({
                            url: `../main/invoiceDetail?isWebviewBack=true&id=${id}`
                        });
                    } else {
                        // 暂时关闭开票验证，2024/3/19 chensiqi
                        // checkCanInvoicing(props?.data?.id).then(res => {
                        //     if(res?.code === 200 && res?.data) {
                        //         history.push({
                        //             pathname: `/invoicing`,
                        //             search: `?id=${props?.data?.id}&price=${props?.data?.payAmount}&random=${props?.data?.random}&orderNo=${props?.data?.orderNo}`
                        //         });
                        //     } else {
                        //         app.dispatch(
                        //             $action('alert').emit({
                        //                 show: true,
                        //                 title: '当前订单不符合开票条件',
                        //                 content: <div className='temp-box'>
                        //                     <p className="title">提示：</p>
                        //                     <p className="desc">若您需要开取发票，请于会员申请当月23日24点前完成发票开取申请。</p>
                        //                 </div>
                        //             })
                        //         );
                        //     }
                        // })
                        history.push({
                            pathname: `/invoicing`,
                            search: `?id=${props?.data?.id}&price=${props?.data?.payAmount}&random=${props?.data?.random}&orderNo=${props?.data?.orderNo}`
                        });
                    }
                }}
            >
                申请开票
            </button> : null}
        </li>
    );
};

export default Record;
