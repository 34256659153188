import React from 'react';
import './style.scss';

const btnArr = [
    {
        title: '待领取',
        color: 'active'
    },
    {
        title: '已领取',
        color: 'gray'
    },
    {
        title: '已结束',
        color: 'gray'
    },
    {
        title: '未开始',
        color: 'gray'
    },
]

const Gift = (props) => {
    return (
        <div className="giftItem item-shadow">
            <img src={`${window.FILE_IMG_URL}${props?.data?.photo}`} alt="" />
            <div className="giftMsg">
                <p className='title'>{props?.data?.name}</p>
                <p className="date">领取期限：{props?.data?.startTime}至{props?.data?.endTime}</p>
                <p className='address'>领取地点：{props?.data?.place}</p>
                {props?.data?.overTime && <p>领取时间：{props?.data?.overTime}</p>}
            </div>
            <button
                className={`giftBtn ${btnArr?.[props?.data?.showState]?.color}`}
                onClick={() => {
                    if(props?.data?.showState === 0) {
                        props.handleGet(props?.data?.id)
                    }
                }}
            >{btnArr?.[props?.data?.showState]?.title}</button>
        </div>
    )
}

export default Gift;
