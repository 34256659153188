/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Loading } from '../Icons';
import cls from 'classnames';
import app, { $action } from '../../model';
import { withRouter } from 'react-router-dom';
class Button extends Component {
    componentDidMount() {
        const enter = this.props.enter;
        if (enter) {
            document.addEventListener('keyup', this.handleEnter);
        }
    }
    componentWillUnmount() {
        const enter = this.props.enter;
        if (enter) {
            document.removeEventListener('keyup', this.handleEnter);
        }
    }
    handleEnter = (event) => {
        if (event.code === 'Enter') {
            this.onItClick();
        }
    };
    onItClick = () => {
        const { onClick } = this.props;
        onClick();
    };

    render() {
        const { children, disable, loading, style = {}, clsname } = this.props;
        return (
            <button
                type="button"
                onClick={this.onItClick}
                style={style}
                className={cls('g-button', { disable: disable || loading }, clsname)}
            >
                {loading ? <Loading /> : children}
            </button>
        );
    }
}
export default withRouter(Button);
