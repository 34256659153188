/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import {
    queryFamilyInfo,
    queryMemberTypeById,
    createOrder,
    cancelOrder,
    getOrderById,
    queryMemberInfoById
} from '../../service';
import Members from './members';
import { history } from '../../routes';
import { Modal, message } from 'antd';
import { localDS } from '../../storage';
import jtImgsrc from "@/assets/lpk-jt.png";
import zxImgsrc from "@/assets/lpk-zx.png";
import app, { $action } from '../../model';
import './style.scss';

const AddMembers = (props) => {
    const param = new URLSearchParams(props.location.search);
    const orderId = param.get('orderId');
    const isQHR = param.get('isQHR');
    // const memberId = param.get('memberId');
    const isQ = localDS.get('isQ');

    const [takeMembers, setTakeMembers] = useState([]);
    const [memberTotal, setMemberTotal] = useState(0);
    const [vipinfor, setVipinfor] = useState();
    const [orderInfor, setOrderInfor] = useState();
    const [tempInfo, setTempInfo] = useState();
    const [open, setOpen] = useState(false);
    const [qhrOpen, setQhrOpen] = useState(false);
    const [memberInfo, setMemberInfo] = useState();
    const [messageApi, contextHolder] = message.useMessage();
    const delMember = function(id) {
        const nowlist = takeMembers?.filter((item) => item.id !== id);
        let res = nowlist;
        setTakeMembers(nowlist);
        setMemberTotal(memberTotal - 1);
        return res;
    };
    useEffect(() => {
        // memberBasicInfo().then(res => {
        //     const data = res?.data
        //     if(data) {
        //         setOrderInfor(data);
        //         queryMemberTypeById({id: data?.typeId}).then(res => {
        //             setVipinfor(res?.data)
        //         })
        //         queryFamilyInfo({memberId: data?.id}).then((res) => {
        //             setTakeMembers(res?.data?.records);
        //             setMemberTotal(res?.data?.total);
        //         })
        //     }
        // })
        getOrderById(orderId).then((res) => {
            const data = res?.data;
            if (data) {
                const tempInfo = JSON.parse(data?.tempInfo);
                setTempInfo(tempInfo);
                setOrderInfor(data);
                queryMemberTypeById({ id: data?.memberType }).then((res) => {
                    setVipinfor(res?.data);
                });
                queryFamilyInfo({ memberId: data?.memberId, typeId: data?.memberType }).then(
                    (res) => {
                        setTakeMembers(res?.data?.records);
                        setMemberTotal(res?.data?.total);
                    }
                );
                queryMemberInfoById({ id: data?.memberId }).then((res) => {
                    res?.data && setMemberInfo(res.data);
                });
            }
        });
        // queryFamilyInfo({memberId}).then((res) => {
        //     setTakeMembers(res?.data);
        //     setMemberTotal(res?.data?.total);
        // })
    }, []);
    const submit = () => {
        if(vipinfor?.id === '3') {
            app.dispatch(
                $action('confirm').emit({
                    open: true,
                    content: '提交成功后，将不可增减或修改家庭成员信息，请您确认是否继续提交？',
                    okText: '确认',
                    okCallback: () => {
                        finalsubmit();
                    }
                })
            );
        } else {
            const yong = takeMembers.filter((i) => i.ageType === 0);
            const adult = takeMembers.filter((i) => i.ageType === 1);
            if (yong?.length === vipinfor?.childrenNum && adult?.length === vipinfor?.adultNum) {
                // createOrder({id: orderId}).then(res => {
                //     if(res?.code !== 200){
                //         messageApi.open({
                //             type: 'error',
                //             content: res?.msg,
                //         });
                //         return;
                //     }else{
                //         history.push(`/memberEdit?id=${orderInfor.memberType}&memberId=${orderInfor?.memberId}&cost=${orderInfor?.payAmount}&orderId=${orderInfor?.id}`)
                //     }
                // })
                finalsubmit();
            } else {
                // 弹框
                // setOpen(true);
                app.dispatch(
                    $action('confirm').emit({
                        open: true,
                        content: '成员信息未添加全，是否继续提交申请？',
                        okText: '确认',
                        cancelText: '取消',
                        okCallback: () => {
                            finalsubmit();
                        }
                    })
                );
            }
        }
    };
    const finalsubmit = () => {
        // if(memberInfo?.isSchoolFriend === "1" ) {
        //     setQhrOpen(true);
        // } else {
        //     create();
        // }
        create();
    };
    const create = () => {
        createOrder({ id: orderId }).then((res) => {
            if (res?.code !== 200) {
                messageApi.open({
                    type: 'error',
                    content: res?.msg
                });
                return;
            } else {
                // history.push(`/memberEdit?id=${orderInfor.memberType}&memberId=${orderInfor?.memberId}&cost=${orderInfor?.payAmount}&orderId=${orderInfor?.id}`)
                // if (
                //     (memberInfo?.isSchoolFriend === 1 || memberInfo?.isSchoolFriend === '1') &&
                //     !isQ
                // ) {
                //     // 清华人判断跳转
                //     // eslint-disable-next-line no-undef
                //     wx.miniProgram.navigateTo({
                //         url: `../main/vipQinghuaren?isWebviewBack=true&id=${orderInfor?.memberType}&memberId=${orderInfor?.memberId}&cost=${orderInfor?.payAmount}&orderId=${orderInfor?.id}&openType=1`
                //     });
                // } else {
                // }
                const addStr = isQHR?.length > 0 ? `&isQHR=${isQHR}` : '';
                history.push({
                    pathname: `/memberEdit`,
                    search: `?id=${orderInfor?.memberType}&memberId=${orderInfor?.memberId}&cost=${orderInfor?.payAmount}&orderId=${orderInfor?.id}${addStr}`
                });
            }
        });
    };
    return (
        <div className="apply-add-member allcenter">
            {contextHolder}
            <div className="apply-infor">
                <div className="img">
                    {vipinfor?.id === '3' && <img src={jtImgsrc} alt="" />}
                    {vipinfor?.id === '4' && <img src={zxImgsrc} alt="" />}
                </div>
                {vipinfor && (
                    <ul>
                        <li>
                            <span className='l'>会员类型</span><span className='r'>{vipinfor?.typeName}</span>
                        </li>
                        <li>
                            <span className='l'>会员姓名</span>
                            <span className='r'>{memberInfo?.isVip === 1
                                ? tempInfo?.memberName
                                : memberInfo?.memberName}</span>
                        </li>
                        <li>
                            <span className='l'>证件号</span>
                            <span className='r'>{memberInfo?.isVip === 1
                                ? tempInfo?.documentNumber
                                : memberInfo?.documentNumber}</span>
                        </li>
                        <li>
                            <span className='l'>手机号码</span>
                            <span className='r'>{memberInfo?.isVip === 1 ? tempInfo?.phone : memberInfo?.phone}</span>
                        </li>
                    </ul>
                )}
            </div>
            <div className="member-box">
                {vipinfor &&
                    (vipinfor?.id === '4' ? (
                        <p className="tips">
                            携带成员信息<span>{`（可添加${vipinfor?.adultNum}名携带成员）`}</span>
                        </p>
                    ) : (
                        <p className="tips">
                            家庭成员信息
                            <span>{`（可添加${Number(vipinfor?.adultNum) + 1}名成年人，${vipinfor?.childrenNum}名18岁以下未成年人，包括申请人在内不可超过${Number(vipinfor?.adultNum) + Number(vipinfor?.childrenNum) + 1}人。绑定后家庭成员不可修改。）`}</span>
                        </p>
                    ))}
                <ul className="member-list">
                    {takeMembers?.map((item) => {
                        return (
                            <Members
                                data={item}
                                key={item?.id}
                                del={delMember}
                                type="with"
                                isGJ={vipinfor?.id === '4'}
                            />
                        );
                    })}
                </ul>
                <div className="btn-box">
                    {/* <button
                        className="share"
                        onClick={(e) => {
                            // 分享跳转
                            const searchUrl = `&typeId=${vipinfor?.id}`;
                            const jumpUrl = encodeURIComponent(
                                `/addshare?memberid=${orderInfor.memberId}&orderId=${orderId}${searchUrl}`
                            );
                            const initUrl = encodeURIComponent(`/addMembers?orderId=${orderId}`);
                            wx.miniProgram.navigateTo({
                                url: `../main/shareMembers?isWebviewBack=true&jumpUrl=${jumpUrl}&initUrl=${initUrl}`
                            });
                        }}
                    >
                        分享
                    </button> */}
                    <button
                        className="share"
                        onClick={(e) => {
                            const yong = takeMembers.filter((i) => i.ageType === 0);
                            const adult = takeMembers.filter((i) => i.ageType === 1);
                            if (yong?.length >= vipinfor?.childrenNum && adult?.length >= vipinfor?.adultNum) {
                                app.dispatch(
                                    $action('alert').emit({
                                        show: true,
                                        title: '提示',
                                        content: '家庭成员人数已满'
                                    })
                                );
                            } else {
                                const searchUrl = `&typeId=${vipinfor?.id}`;
                                const id = tempInfo?.id || orderInfor?.memberId;
                                e.preventDefault();
                                history.push(`/addfamily?memberid=${id}${searchUrl}`);
                            }
                        }}
                    ><span>+</span>添加成员</button>
                </div>
            </div>
            <Modal
                title="提示"
                open={qhrOpen}
                onOk={() => {
                    setQhrOpen(false);
                    create();
                }}
                onCancel={() => {
                    setQhrOpen(false);
                }}
                okText="确认"
                cancelText="关闭"
            >
                <p>
                您未通过“清华人小程序”的校友身份验证，请上传校友身份证件照片，本馆审核通过后即刻生效会员资格。审核未通过，您的支付金额将于7个工作日内原路径退回。
                </p>
            </Modal>
            <div className="btn-group">
                <Modal
                    title="成员信息未添加全，是否继续提交申请？"
                    open={open}
                    onOk={() => {
                        setOpen(false);
                        finalsubmit();
                    }}
                    onCancel={() => {
                        setOpen(false);
                    }}
                    okText="确认"
                    cancelText="取消"
                />
                <button
                    className="cancel"
                    onClick={(e) => {
                        e.preventDefault();
                        cancelOrder(orderId, orderInfor?.payState).then((res) => {
                            if (res?.code !== 200) {
                                messageApi.open({
                                    type: 'error',
                                    content: res?.msg
                                });
                                return;
                            } else {
                                messageApi.info('取消成功！');
                                setTimeout(() => {
                                    history.push('/applyrecords');
                                }, 100);
                            }
                        });
                    }}
                >
                    取消
                </button>
                <button
                    className="confirm"
                    htmltype="submit"
                    onClick={() => {
                        submit();
                    }}
                >
                    提交申请
                </button>
            </div>
        </div>
    );
};

export default AddMembers;
