import React, { useState, useEffect } from 'react';
import MemberForm from './memberForm';
import AddMemberForm from './addMemberForm';
import { memberBasicInfo, queryMemberInfoById, getOrderById } from '../../service';
import { localDS } from '../../storage';
import './style.scss';

const MemberTitle = ({type}) => {
    const memberType = [
        '',
        '学生会员',
        '个人会员',
        '家庭会员',
        '尊享会员',
        '荣誉会员'
    ]
    return (
        <div className="title">
            {/* <h3>会员申请资料</h3> */}
            <h3>{`成为${type ? memberType[Number(type)] : '会员'}`}</h3>
        </div>
    );
};

const PhysicalTitle = () => {
    return (
        <div className="title physical-bg">
            <p>
                <span className="iconfont icon-jinggao"> </span>
                实体卡未激活，请填写以下信息，激活实体卡
            </p>
        </div>
    );
};

const MemberEdit = (props) => {
    const search = new URLSearchParams(window.location.search);
    const type = search.get('type');
    const id = search.get('id');
    const orderId = search.get('orderId');
    const memberId = search.get('memberId');
    const isPhysical = type === 'physical';
    const [memberInfo, setMemberInfo] = useState();
    const [tempInfo, setTempInfo] = useState();
    const [orderInfo, setOrderInfo] = useState();
    const [isrequest, setisrequest] = useState(false);
    const [isrequest2, setisrequest2] = useState(false);
    let documentNumber = localDS.get('documentNumber');

    useEffect(() => {
        if (memberId && memberId !== 'judge') {
            queryMemberInfoById({ id: memberId }).then((res) => {
                res?.data && setMemberInfo(res.data);
                setisrequest(true);
            });
        } else {
            memberBasicInfo(documentNumber).then((res) => {
                res?.data && setMemberInfo(res.data);
                setisrequest(true);
            });
        }
    }, []);
    useEffect(() => {
        if (orderId) {
            getOrderById(orderId).then((res) => {
                const data = res?.data;
                if (data) {
                    setOrderInfo(data);
                    const tempInfo = JSON.parse(data?.tempInfo);
                    setTempInfo(tempInfo);
                }
                setisrequest2(true);
                // 根据订单请求个人信息
                if(data?.memberId) {
                    queryMemberInfoById({ id: data?.memberId }).then((res) => {
                        res?.data && setMemberInfo(res.data);
                        setisrequest(true);
                        console.log(res, '个人信息')
                    });
                } else if(memberId && memberId !== 'judge') {
                    queryMemberInfoById({ id: memberId }).then((res) => {
                        res?.data && setMemberInfo(res.data);
                        setisrequest(true);
                    });
                } else {
                    memberBasicInfo(documentNumber).then((res) => {
                        res?.data && setMemberInfo(res.data);
                        setisrequest(true);
                    });
                }
            });
        }
        // if (memberId && memberId !== 'judge') {
        //     queryMemberInfoById({ id: memberId }).then((res) => {
        //         res?.data && setMemberInfo(res.data);
        //         setisrequest(true);
        //     });
        // } else {
        //     memberBasicInfo(documentNumber).then((res) => {
        //         res?.data && setMemberInfo(res.data);
        //         setisrequest(true);
        //     });
        // }
    }, [orderId]);
    console.log(isrequest, isrequest2, orderId)
    return (
        <div className="memberEdit">
            {isPhysical ? <PhysicalTitle /> : <MemberTitle type={id} />}

            {id === '3' ? (
                <AddMemberForm
                    isPhysical={isPhysical}
                    type={type}
                    infor={memberInfo}
                    isrequest={isrequest && (isrequest2 || !orderId)}
                    orderInfo={orderInfo}
                    tempInfo={tempInfo}
                />
            ) : (
                <MemberForm
                    isPhysical={isPhysical}
                    type={type}
                    infor={memberInfo}
                    isrequest={isrequest && (isrequest2 || !orderId)}
                    tempInfo={tempInfo}
                    orderInfo={orderInfo}
                    setisrequest={setisrequest}
                />
            )
            // <MemberForm isPhysical={isPhysical} type={type} isrequest={isrequest && (isrequest2 || !orderId)} tempInfo={tempInfo} orderInfo={orderInfo}/>
            }
        </div>
    );
};

export default MemberEdit;
