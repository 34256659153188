import React, { useEffect, useState, useCallback, useRef } from 'react';
import { addGiftCardBatch, createOrder, queryMemberType, getOrderById,queryMemberRules } from '../../service'
import NumericInput from './input';
import { message, Checkbox, Modal } from 'antd';
import { history } from '../../routes';
import app, { $action } from '../../model';
import { localDS } from '../../storage';
import './style.scss';

const Vipclassify = (props) => {
    const param = new URLSearchParams(props.location.search);
    const orderId = param.get('orderId');
    const isPay = param.get('isPay');
    const [messageApi, contextHolder] = message.useMessage();
    const [priceObj, setPriceObj] = useState();
    const [price, setPrice] = useState();
    const [confirm, setConfirm] = useState(false);
    const [isConfirm, setIsconfirm] = useState((confirm || isPay) ? true : false);
    const [tkopen, setTkopen] = useState(false);
    const [charter, setCharter] = useState('');
    const [orderInfor, setOrderInfor] = useState({});
    const [orderArr, setOrderArr] = useState([{
        typeId:'',
        // num: ''
        num: 1,
    }])
    useEffect(() => {
        queryMemberType().then(res => {
            const arr = res?.data?.records;
            let obj = {};
            arr && arr.forEach(item => {
                obj['no' + item.id] =item.memberCost
            });
            setPriceObj(obj)
        })
        queryMemberRules('POI0702').then((res) => {
            setCharter(res?.data?.content);
        });
    }, [])
    useEffect(() => {
        orderId && getOrderById(orderId).then(res => {
            // console.log(res?.data)
            const data = res?.data
            if(data) {
                setOrderInfor(data);
                const vipArr=['个人会员', '家庭会员', '尊享会员']
                const arr = data?.memberTypeDetails?.map(item => {
                    const type = item.slice(0, 4)
                    const typeId = type && (vipArr.indexOf(type) + 2).toString();
                    return {num: item.match(/\d+/g)[0], typeId }
                })
                // console.log(arr, 666)
                setOrderArr(arr);
                setCountdown(data?.ttl)
            }
        })
    }, [orderId])
    const calPrice = (data) => {
        let cost=0;
        (data || orderArr).forEach(item =>{
            if(item.num && item.typeId) {
                cost = cost + Number(item.num) * Number(priceObj['no' + item.typeId])
            }
        })
        setPrice(cost)
    }

    const handleOnchange = useCallback((num, index) => {
        let data = [...orderArr];
        data[index].num = num;
        setOrderArr(data);
        calPrice();
    }, [orderArr])

    const handleontype = (typeId, index) => {
        if(typeId) {
            let data = [...orderArr];
            data[index].typeId = typeId;
            setOrderArr(data);
            calPrice();
        }
    }

    const handledel = (index) => {
        let data = [...orderArr];
        data.splice(index, 1);
        setOrderArr(data);
        calPrice(data);
    }

    // const timeCount = 10;
    let [countdown, setCountdown] = useState(0);
    useEffect(() => {
        if(isPay) {
            const timer = setInterval(() => {
                if(countdown>0){
                    setCountdown(countdown-1);
                }else{
                    // props.changeSubmit();
                }
            }, 1000);
            return () => {
                clearInterval(timer);
            }
        }
    }, [countdown, isPay]);
    const timeFormat = (countdown) => {
        const minute = Math.floor(countdown / 60);
        const second = countdown % 60;
        return `${(minute >= 10) ? minute : '0' + minute}分${(second >= 10) ? second : '0' + second}秒`
    }

    const onChange = (e) => {
        setIsconfirm(e.target.checked);
    };
    const formRef = useRef();
    const pageView = useRef(null);
    // 滚动到勾选框
    function handleCheckScroll() {
        pageView.current && pageView.current.scrollIntoView({ behavior: 'smooth' });
    }
    return (
        <div className="vip-choose common-bg purchasecard">
            {contextHolder}
            <div className="vip-choose-cont">
                <div className="img-box" />
                <div className="tips">
                    <h2>分享卡办理说明：</h2>
                    <p>1、个人可以不记名方式购买多张个人会员、尊享会员、家庭会员卡，购买成功后，可于一年内赠与他人使用（限转赠一次，过期失效）；</p>
                    <p>2、被赠予者需激活会员卡，完善个人信息后方可使用。激活后会员资格有效期为一年；</p>
                    <p>3、会员卡为实名制，仅限激活开卡人持有，在享用会员权益时请出示会员电子码；</p>
                    <p>4、分享卡一经售出，不予退换；</p>
                    <p>5、相关会员权益及管理办法详见《会员章程》。</p>
                </div>
                {orderArr.map((item, index) => {
                    return <NumericInput keynum={index} key={index} value={orderArr[index].num} typeId={orderArr[index].typeId} onChange={handleOnchange} hasdel={orderArr?.length > 1 ? 'true' : ''} handledel={handledel} handleontype={handleontype} isPay={isPay}/>
                })}
                {!isPay && <div
                    className="add"
                    onClick={() => {
                        const data = [...orderArr];
                        data.push({
                            typeId:'',
                            // num: ''
                            num: 1
                        })
                        setOrderArr(data);
                    }}
                >
                    +新增
                </div>}
                <div className="confirm-check" id="pageView" ref={pageView}>
                    <Checkbox onChange={onChange} checked={isConfirm} disabled={confirm || isPay} />
                    我已阅读
                    <span
                        className="hytk"
                        onClick={() => {
                            setTkopen(true);
                        }}
                    >
                        《会员章程》
                    </span>
                    并同意
                </div>
                <Modal
                    title="清华大学艺术博物馆会员章程"
                    open={tkopen}
                    className="hytkmodel"
                    onOk={() => {
                        setIsconfirm(true);
                        setTkopen(false);
                    }}
                    onCancel={() => {
                        setTkopen(false);
                    }}
                    okText="确认"
                    cancelButtonProps={{ style: { display: 'none' } }}
                >
                    <div className="cont" dangerouslySetInnerHTML={{ __html: charter }} />
                </Modal>
            </div>
            {(confirm || isPay) ? <div className={(orderInfor?.payAmount) ? 'paybox' : 'paybox loadorder'}>
                <div className='cost'>
                    支付费用&nbsp;&nbsp;<span>￥{orderInfor?.payAmount}</span>
                    {countdown > 0 ? <p>{`支付中（付款倒计时：${timeFormat(countdown)}）`}</p> :  <p>订单已超时取消</p> }
                </div>
                <button
                    onClick={() => {
                        if(countdown > 0 && orderId) {
                            createOrder({id: orderId}).then(res => {
                                if(res?.code !== 200){
                                    messageApi.open({
                                        type: 'error',
                                        content: res?.msg,
                                    });
                                    return;
                                }else{
                                    // 支付跳转
                                    const obj = encodeURIComponent(JSON.stringify(res?.data))
                                    // eslint-disable-next-line no-undef
                                    wx.miniProgram.navigateTo({url: `../main/vipPay?isWebviewBack=true&openType=2&obj=${obj}&orderId=${orderId}&type=cardspass`})
                                }
                            })
                        } else {
                            setOrderArr([{
                                typeId:'',
                                // num: ''
                                num: 1
                            }])
                            history.push({
                                pathname: `/purchasecard`,
                            });
                        }
                    }}
                >
                {countdown > 0 ? '立即支付' : '重新申请'}
                </button>
            </div> :
            <div className="paybox single">
                {/* <div>{price}</div> */}
                <button
                    onClick={() => {
                        if (!isConfirm) {
                            app.dispatch(
                                $action('alert').emit({
                                    show: true,
                                    title: '提示',
                                    content: '请认真阅读《会员章程》，勾选确认后再申请办理。'
                                })
                            );
                            handleCheckScroll();
                            return;
                        }
                        const arr = orderArr.filter(item => {
                            return !item.num || !item.typeId
                        })
                        if(arr?.length > 0) {
                            messageApi.open({
                                type: 'error',
                                content: '请填写完整信息',
                            });
                            return;
                        }
                        let documentNumber = localDS.get('documentNumber');
                        addGiftCardBatch({
                            arr: orderArr,
                            cost: price,
                            documentNumber
                        }).then(res => {
                            const id = res?.data?.id
                            if(res?.code !== 200){
                                messageApi.open({
                                    type: 'error',
                                    content: res?.msg,
                                });
                                return;
                            }else{
                                id && createOrder({id}).then(res => {
                                    if(res?.code !== 200){
                                        messageApi.open({
                                            type: 'error',
                                            content: res?.msg,
                                        });
                                        return;
                                    }else{
                                        history.push({
                                            pathname: `/purchasecard`,
                                            search: `?orderId=${id}&isPay=true`
                                        });
                                    }
                                })
                            }
                        })
                    }}
                >
                    确定
                </button>
            </div>}
        </div>
    );
}

export default Vipclassify;
