import React, { useState, useEffect } from 'react';
import { history } from '../../routes';
import { queryMemberTypeById, getOrderById } from '../../service';
import './style.scss';

const CardPass = (props) => {
    // const state = new URLSearchParams(window?.location?.search)?.get("state");
    const orderId = props?.orderId;
    const [memberType, setMemberType] = useState({});
    const [orderInfor, setOrderInfor] = useState({});
    const state = 'success';
    // const state = 'fail';

    useEffect(() => {
        getOrderById(orderId).then((res) => {
            const data = res?.data;
            // console.log(data)
            if (data) {
                setOrderInfor(res?.data);
                queryMemberTypeById({ id: data?.memberType }).then((res) => {
                    setMemberType(res?.data);
                });
            }
        });
    }, []);
    return (
        <div className="applypass">
            {state === 'success' && (
                <>
                    <div className="apply-pic gm"> </div>
                    <div className="apply-msg">
                        <p className="vip-alltype">
                            <span className="vip-allname">会员类型：</span>
                            <p>
                                {orderInfor.memberTypeDetails?.map((item, index) => {
                                    return (
                                        <span className="whole" key={index}>
                                            {item}
                                        </span>
                                    );
                                })}
                            </p>
                        </p>
                        {/* <p>购买数量：<span>{orderInfor?.giftCardNum}</span></p> */}
                        <p>
                            支付金额：<span>￥{orderInfor?.payAmount}</span>
                        </p>
                    </div>
                </>
            )}
            {state === 'fail' && (
                <p className="welcome fail">支付失败，请在会员中心 &gt; 申请记录中查看订单！</p>
            )}
            <div className="back">
                <button
                    className="confirm"
                    onClick={() => {
                        history.push(`${state === 'fail' ? '/applyrecords' : '/giftcard'}`);
                    }}
                >
                    {state === 'fail' ? '申请记录' : '分享卡'}
                </button>
                <button
                    onClick={() => {
                        history.push('/personal');
                    }}
                >
                    会员中心
                </button>
            </div>
            <div className="empty"> </div>
        </div>
    );
};

export default CardPass;
