import React, { useEffect, useState } from 'react';
import Alert from './shared/Alert';
import Confirm from './shared/Confirm';
import { Switch, Route, useLocation } from 'react-router-dom';
// import ErrorWrap from './shared/ErrorWrap';
import { mainRoutes } from './routes';
import { memberBasicInfo, queryCertList, queryIsQingHuaPersonByCustomerId } from './service';
import { localDS } from './storage';
import { message } from 'antd';
// import 'moment/dist/locale/zh-cn';

function App() {
    // const testToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwaG9uZSI6IjE1NTI3NjkwMTIzIiwicGhvbmVJbnZhbGlkIjowLCJuYW1lIjoiMTU1Mjc2OTAxMjMiLCJjdXN0b21lcklkIjoiYTRlMmEyNGI4YTU3Mzc2YTY5YmI2YzliZDBhN2UxM2QifQ.qaKvnxCxkt-ziMhcw-FIyKAGNthN8jDJlplAyRzRCQE" // 熊全军

    // const testToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwaG9uZSI6IjE1NTQ5NDg1MjA4IiwicGhvbmVJbnZhbGlkIjowLCJuYW1lIjoiMTU1NDk0ODUyMDgiLCJjdXN0b21lcklkIjoiOWYyYzcyNzE5MTUzY2JmYmZiY2Y4Y2M1ZDVjYjg0ZTcifQ._e2UL-ZoK6Hu082MHMVT7ZesHKF6QPPOnu1pC0ji598" // 陈思奇 尊享

    // const testToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwaG9uZSI6IjEzMDM3MTczNTU2IiwicGhvbmVJbnZhbGlkIjowLCJuYW1lIjoiMTMwMzcxNzM1NTYiLCJjdXN0b21lcklkIjoiZGUzYjEyNTFmYjA3MjYxOGI4OTQ3ODIyMmJkZmU5ODgifQ.DFWVqKIvjmH27NXIQfA3Zf01ARFodCc5EqfXx_Jy1L4" // 测试2号

    // const testToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwaG9uZSI6IjE4MTU0MzI0OTQwIiwicGhvbmVJbnZhbGlkIjowLCJuYW1lIjoiMTgxNTQzMjQ5NDAiLCJjdXN0b21lcklkIjoiYzJhMzRhOGI4MTI1OWQyM2E0N2NmM2NhN2UyYzUyYmYifQ.-mFjXGi7Ocj2LOkcNID4inPU_vrWB_Wq4x-dfD5Kyn0" // 我自己

    // const testToken="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwaG9uZSI6IjE1NjM5MzE3ODkwIiwicGhvbmVJbnZhbGlkIjowLCJuYW1lIjoiMTU2MzkzMTc4OTAiLCJjdXN0b21lcklkIjoiZjhlZmIyOThmNjc3MTA2OGJkYWExYmI1ZDU3ZGQwNmEifQ.LY99M1RjxcOjvdrGgcdi2-kHUjRqHKw8IBtctOoqIzo"; // 王岩

    // eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwaG9uZSI6IjEzNjU3MTYyNTUwIiwicGhvbmVJbnZhbGlkIjowLCJuYW1lIjoiMTM2NTcxNjI1NTAiLCJjdXN0b21lcklkIjoiMjAyNmEyNjMzZDg2ZmJjM2NjNmRkOWRkMWRjZjBjN2IifQ.XY4yrJas-SDAMisoZGNnQNluY2WRF2bRWmGDp5lxigw  // c奇家庭会员

    // eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwaG9uZSI6IjEzNjU3MTYyNTUwIiwicGhvbmVJbnZhbGlkIjowLCJuYW1lIjoiMTM2NTcxNjI1NTAiLCJjdXN0b21lcklkIjoiMmZlZDMzMmYwYmY3Y2ViM2ZmZjc2NmU3YzYwZmJmZjcifQ.nxvLRverLnCoLOr2CazGyOBijSLwUB0L7DATULhbzs4  // 测试c奇

    // eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwaG9uZSI6IjE4MTU0MzI0OTQwIiwicGhvbmVJbnZhbGlkIjowLCJuYW1lIjoiMTgxNTQzMjQ5NDAiLCJjdXN0b21lcklkIjoiYzJhMzRhOGI4MTI1OWQyM2E0N2NmM2NhN2UyYzUyYmYiLCJleHAiOjE2OTUyNzAzNzZ9.2J8AFY9cEY_mQ6VyVVSJeC4TJBtnM8-hWunjXYJjQ44   // 测试地址我的token
    const [certRequert, setCertRequert] = useState(false);
    const [infoRequert, setInfoRequert] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const location = useLocation();
    useEffect(() => {
        const search = new URLSearchParams(window.location.search);
        const Authorizationc = search.get('token');
        const username = search.get('name');
        // alert(Authorizationc)
        if (Authorizationc) {
            localDS.remove('Authorizationc');
            localDS.set('Authorizationc', Authorizationc);
        }
        if (username) {
            localDS.remove('username');
            localDS.set('username', username);
        }
        queryCertList().then((res) => {
            localDS.remove('cert');
            // 实名认证身份证号、手机号
            const cert = res?.data && res?.data[0];
            if (cert) {
                let documentNumber = res?.data && res?.data[0]?.showDocumentNumber;
                // window.documentTypeName = documentNumber;
                localDS.set('documentNumber', documentNumber);
                localDS.set('cert', cert);
                setCertRequert(true);
                memberBasicInfo(documentNumber).then((res) => {
                    localDS.remove('memberId');
                    const id = res?.data?.id;
                    if (id) {
                        localDS.set('memberId', id);
                    }
                    setInfoRequert(true)
                });
                queryIsQingHuaPersonByCustomerId(cert?.customerCertId).then((res) => {
                    // console.log('是否清华人', res)
                    localDS.set('isQ', res.data);
                });
            } else {
                // alert(Authorizationc)
                // alert(res?.data)
                // messageApi.open({
                //     type: 'error',
                //     content: '请先实名认证',
                // });
                setCertRequert(true);
                return;
            }
        });
    }, []);
    useEffect(() => {
        const arr = mainRoutes.filter((item) => {
            return item.path === location.pathname;
        });
        if (arr[0]?.name) {
            document.title = arr[0]?.name;
        }
        // console.log(arr, location.pathname)
    }, [location.pathname]);
    return (
        ((location.pathname === '/cardsinfor') || (certRequert && infoRequert)) && (
            <>
                {contextHolder}
                <Alert />
                <Confirm />
                <Switch>
                    {mainRoutes.map((route) => (
                        <Route
                            path={route.path}
                            key={route.name}
                            component={route.component}
                            exact={route.exact}
                        />
                    ))}
                </Switch>
            </>
        )
    );
}
export default App;
