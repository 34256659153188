import React, { useState, useEffect } from 'react';
import Record from './record';
import { getDictionaryByCode, cancelOrder, queryMemberOperationRecord, queryIsQingHuaPersonByCustomerId } from '../../service';
import { message } from 'antd';
import { localDS } from '../../storage';
import imgsrc from '@/assets/zwsj.png';
import './style.scss';

const ApplyRecords = () => {
    const [records, setRecords] = useState([]);
    const [operation, setOperation] = useState({});
    const [source, setSource] = useState({});
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(true);
    const [isQHR, setIsQHR] = useState();
    const memberId = localDS.get('memberId');
    useEffect(() => {
        (async function() {
            // 查询会员申请记录
            const records = await queryMemberOperationRecord(memberId);
            setRecords(records?.data?.records);
            setLoading(false);
            // 查询会员操作类型字典值
            const operation = await getDictionaryByCode('POI_OPERATION_TYPE_OPTION', 'POI');
            let operationObj = {};
            operation &&
                operation.data.forEach((item) => {
                    operationObj[item?.id] = item?.text;
                });
            setOperation(operationObj);
            // 查询会员来源
            const source = await getDictionaryByCode('POI_SOURCE_OPTION', 'POI');
            let sourceObj = {};
            source?.data &&
                source.data.forEach((item) => {
                    sourceObj[item?.id] = item?.text;
                });
            setSource(sourceObj);
        })();
        // 重新验证清华人信息
        const cert = localDS.get('cert');
        queryIsQingHuaPersonByCustomerId(cert?.customerCertId).then((res) => {
            localDS.set('isQ', res.data);
            const isqhr = res.data.toString();
            setIsQHR(isqhr)
        });
    }, []);
    const handleCancel = (id, payState) => {
        cancelOrder(id, payState).then((res) => {
            if (res?.code !== 200) {
                messageApi.open({
                    type: 'error',
                    content: res?.msg
                });
                return;
            } else {
                messageApi.info('取消成功！');
                setTimeout(() => {
                    queryMemberOperationRecord(memberId).then((res) => {
                        setRecords(res?.data?.records);
                    });
                }, 100);
            }
        });
    };
    const handleReset = () => {
        queryMemberOperationRecord(memberId).then((res) => {
            setRecords(res?.data?.records);
        });
    };
    return (
        <>
            {contextHolder}
            <div className="apply-tips">
                <i className="iconfont icon-tishi" />
                <p className="text">若您需要开取发票，请于会员申请当月23日24点前完成发票开取申请。</p>
            </div>
            {!loading ? (
                <ul className="records allcenter">
                    {records?.length > 0 ? (
                        records?.map((item, index) => {
                            // item.payAmount = '0';
                            return (
                                <Record
                                    data={item}
                                    operation={operation}
                                    source={source}
                                    key={index}
                                    handleCancel={handleCancel}
                                    handleReset={handleReset}
                                    isQHR={isQHR}
                                />
                            );
                        })
                    ) : (
                        <div className="zwsj">
                            <img src={imgsrc} alt="" />
                        </div>
                    )}
                </ul>
            ) : (
                <div className="loading">loading...</div>
            )}
        </>
    );
};

export default ApplyRecords;
