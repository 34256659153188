import mpa from '@mpfe/mpa';
import alert from './alert';
// import profile from './profile';
// import auth from './auth';
import confirm from './confirm';
import err from './err';
const app = mpa({ silence: true });


app.useModel(alert);
// app.useModel(profile());
// app.useModel(auth());
app.useModel(confirm());
app.useModel(err);
app.run();
// 将就先
Object.assign(app, {
    get dispatch() {
        return app.store.dispatch;
    }
});
export const $action = app.actions;

export default app;
