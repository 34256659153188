import idcard from 'idcard';
// 中国大陆手机号匹配
// const phoneNumRegex = /^(?:\+?86)?1(?:3\d{3}|5[^4\D]\d{2}|8\d{3}|7(?:[01356789]\d{2}|4(?:0\d|1[0-2]|9\d))|9[189]\d{2}|6[567]\d{2}|4[579]\d{2})\d{6}$/;
const phoneNumRegex = /^1[3456789]\d{9}$/;
// const pwdRegex = /^[\w_]{6,16}$/; // 6-16位  字母数字下划线
const pwdRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,16}$/;

const cnNameRegex = /^[\u4e00-\u9fa5]{2,5}(?:·[\u4e00-\u9fa5]+)?$/; // 汉字姓名正则
const enNameRegex = /(^[a-zA-Z]{1}[a-zA-Z\s]{0,20}[a-zA-Z]{1}$)/;
// 护照
// 只能包含数字和字母
const passPortRegex = /^[A-Za-z0-9]+$/;
// 军官证
// 只能包含数字和字母
const officerCardRegex = /^[A-Za-z0-9]+$/;
// 邮箱验证
const emailRegex = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

/**
 * 手机号验证
 * @param {number} phoneNum - 手机号
 */
export const isPhoneNum = (phoneNum) => phoneNumRegex.test(phoneNum);
export const isPassPortCard = (passPortCard) => passPortRegex.test(passPortCard); // 护照
export const isOfficerCard = (officerCard) => officerCardRegex.test(officerCard); // 军官证
export const isIDNum = (IDNum) => idcard.verify(IDNum);
export const isWX = () => navigator.userAgent.toLowerCase().includes('micromessenger');
export const isEmail = (email) => emailRegex.test(email);
export const isUserName = (name) => cnNameRegex.test(name);
export const isAnyUserName = (name) => cnNameRegex.test(name) || enNameRegex.test(name);
export const isPwd = (pwd) => pwdRegex.test(pwd);

function isDate8(sDate) {
    if (!/^[0-9]{8}$/.test(sDate)) {
        return false;
    }
    let year;
    let month;
    let day;
    year = sDate.substring(0, 4);
    month = sDate.substring(4, 6);
    day = sDate.substring(6, 8);
    const iaMonthDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (year < 1700 || year > 2500) return false;
    if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) iaMonthDays[1] = 29;
    if (month < 1 || month > 12) return false;
    if (day < 1 || day > iaMonthDays[month - 1]) return false;
    return true;
}

export const isGAT = (type, num) => {
    const location = [['810000', '820000'], ['830000']];

    if (location[type].findIndex((i) => i === num.slice(0, 6)) === -1) return false;
    // 权重值
    const factorArr = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2, 1];

    // 校验码
    const parityBit = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'];

    let varArray = [];
    let intweightSum = 0;
    let intCheckDigit;

    const intStrLen = num.length;
    const idNumber = num.toString().toUpperCase();

    // 判断每一位字符，顺便计算加权值
    for (let i = 0; i < intStrLen; i++) {
        varArray[i] = idNumber.charAt(i);
        if ((varArray[i] < '0' || varArray[i] > '9') && i !== 17) {
            return false;
        } else if (i < 17) {
            varArray[i] = varArray[i] * factorArr[i];
            intweightSum = intweightSum + varArray[i];
        }
    }

    // 生日期校验
    const date8 = idNumber.substring(6, 14);
    if (isDate8(date8) === false) {
        return false;
    }

    // 校验码验证  余数在校验数组中的值
    intCheckDigit = parityBit[intweightSum % 11];
    //   console.log(intCheckDigit);
    // 最后一位和计算出的校验码是否一致
    if (varArray[17] !== intCheckDigit) {
        return false;
    }
    return true;
};

// 获取当前时间
export function getNowFormatDate() {
    let date = new Date();
    let seperator1 = '-'; // 格式分隔符
    let year = date.getFullYear(); // 获取完整的年份(4位)
    let month = date.getMonth() + 1; // 获取当前月份(0-11,0代表1月)
    let strDate = date.getDate(); // 获取当前日(1-31)
    if (month >= 1 && month <= 9) month = '0' + month; // 如果月份是个位数，在前面补0
    if (strDate >= 0 && strDate <= 9) strDate = '0' + strDate; // 如果日是个位数，在前面补0
    let currentdate = year + seperator1 + month + seperator1 + strDate;
    return currentdate;
}

function repair(i){
    if (i >= 0 && i <= 9) {
        return "0" + i;
    } else {
        return i;
    }
}
export function getCurrentTime() {
    let date = new Date();
    let year = date.getFullYear();
    let month = repair(date.getMonth() + 1);
    let day = repair(date.getDate());
    let hour = repair(date.getHours());
    let minute = repair(date.getMinutes());
    let second = repair(date.getSeconds());

    let curTime = year + "-" + month + "-" + day
            + " " + hour + ":" + minute + ":" + second;
    return curTime;
}

export function getMemberExpire(){
    let date = new Date();
    let year = date.getFullYear();
    let month = repair(date.getMonth() + 1);
    let day = repair(date.getDate());
    let endDate = new Date(date.valueOf() + 365 * 24 * 60 * 60 * 1000);
    let endYear = endDate.getFullYear();
    let endMonth = repair(endDate.getMonth() + 1);
    let endDay = repair(endDate.getDate());
    return [`${year}-${month}-${day}`, `${endYear}-${endMonth}-${endDay}`];
}
// 秒数转时分秒
// export function formatSeconds(value) {
//     let secondTime = parseInt(value, 10);// 秒
//     let minuteTime = 0;// 分
//     let hourTime = 0;// 小时
//     if(secondTime > 60) {// 如果秒数大于60，将秒数转换成整数
//         // 获取分钟，除以60取整数，得到整数分钟
//         minuteTime = parseInt(secondTime / 60, 10);
//         // 获取秒数，秒数取佘，得到整数秒数
//         secondTime = parseInt(secondTime % 60, 10);
//         // 如果分钟大于60，将分钟转换成小时
//         if(minuteTime > 60) {
//             // 获取小时，获取分钟除以60，得到整数小时
//             hourTime = parseInt(minuteTime / 60, 10);
//             // 获取小时后取佘的分，获取分钟除以60取佘的分
//             minuteTime = parseInt(minuteTime % 60, 10);
//         }
//     }
//     let result = parseInt(secondTime, 10) + "秒";

//     if(minuteTime > 0) {
//         result = parseInt(minuteTime, 10) + "分" + result;
//     }
//     if(hourTime > 0) {
//         result = parseInt(hourTime, 10) + "小时" + result;
//     }
//     return result;
// }
export function formatSeconds(value) {
    let secondTime = String(Math.floor((value) / 1000));// 秒
    let minuteTime = 0;// 分
    let hourTime = 0;// 小时
    if(secondTime > 60) {// 如果秒数大于60，将秒数转换成整数
        // 获取分钟，除以60取整数，得到整数分钟
        minuteTime = String(Math.floor(secondTime / 60));
        // 获取秒数，秒数取佘，得到整数秒数
        secondTime = String(Math.floor(secondTime % 60));
        // 如果分钟大于60，将分钟转换成小时
        if(minuteTime > 60) {
            // 获取小时，获取分钟除以60，得到整数小时
            hourTime = String(Math.floor(minuteTime / 60));
            // 获取小时后取佘的分，获取分钟除以60取佘的分
            minuteTime = String(Math.floor(minuteTime % 60));
        }
    }
    let result = Math.floor(secondTime) + "秒";

    if(minuteTime > 0) {
        result = Math.floor(minuteTime) + "分" + result;
    }
    if(hourTime > 0) {
        result = Math.floor(hourTime) + "小时" + Math.floor(minuteTime) + "分";
    }
    return result;
}

export function deleteHtmlTag(str) {
    const str1 = str && str.replace(/<[^>]+>|&[^>]+;/g, '').trim();
    return str1;
}

export function htmltoStr(content, num = 10000, isEn = false) {
    if (content === null) return '';
    // eslint-disable-next-line no-param-reassign
    if (!content) content = '';
    let quot = content
      .replace(/&rdquo;/g, '”')
      .replace(/&ldquo;/g, '“')
      .replace(/&quot;/g, '"')
      .replace(/<[^>]+>|&[^>]+;/g, '')
      .trim();
    // 处理文本中的HTML标签
    const s = quot.replace(/<[^>]+>/g, '').trim();
    // s为空字符串
    if (s === '') return '';
    // 判断是否为英文段落截取
    if (isEn) {
      const str1 = content.replace(/<[^>]+>|&[^>]+;/g, '').trim();
      if (str1.length < num) return str1;
      // 截取最后一个空格前的段落（段尾保留完整的英文单词）
      const text = str1.substr(0, Number(num));
      const n = text.lastIndexOf(' ');
      return `${text.substr(0, n)}...`;
    }
    // const t = s.replace(/ /g, '');

    if (s.length < num) {
      // 如果文字数量少于num，则直接返回
      return `${s}`;
    } else {
      // 需要截断文本长度
      if (num > 0) return `${s.substr(0, Number(num))}...`;
      return `${s}...`;
    }
  }

  export  function hideId(idNumber = ''){
    let prefix = idNumber.slice(0, 3);
    let postfix = idNumber.slice(idNumber?.length - 4);
    let hidefix = new Array((idNumber?.length - 7) > 0 ? idNumber?.length - 7 : 0).fill('*').join('');
    return `${prefix}${hidefix}${postfix}`;
}

// 将2023-5-1转为2023-05-01
export function dealdate(date) {
    if(!date) return;
    const arr = date?.indexOf('/') > 0 ? date.split('/') : date.split('-');
    if(Number(arr[1]) < 10 && arr[1]?.length < 2) {
        arr[1] = '0' + arr[1]
    }
    if(Number(arr[2]) < 10 && arr[2]?.length < 2) {
        arr[2] = '0' + arr[2]
    }
    const res = arr.join('/');
    return res;
}

// 将插件birthday转为yyyy-mm-dd;
export function DealBirthday(birthDate) {
    const y = birthDate?.$y;
    const prem = Number(birthDate?.$M) + 1;
    const m = prem < 10 ? ('0' + prem) : prem;
    const d = birthDate?.$D < 10 ? ('0' + birthDate?.$D) : birthDate?.$D;
    const birthday = `${y}-${m}-${d}`;
    return birthday;
}

// 身份证号码提取出生日期
export function DocToBirth(idCardNumber) {
    if(!idCardNumber) {
        return null
    }
    // 提取出生日期
    let birthDate = idCardNumber.substr(6, 8);

    // 格式化出生日期
    let year = birthDate.substr(0, 4);
    let month = birthDate.substr(4, 2);
    let day = birthDate.substr(6, 2);
    let formattedBirthDate = year + "/" + month + "/" + day;
    return formattedBirthDate;
}

// 身份证判断是否成年
export function DocToAdult(idCardNumber) {
    if(!idCardNumber) {
        return null
    }
    // 提取出生日期
    let birthDate = idCardNumber.substr(6, 8);

    // 获取当前日期
    let currentDate = new Date();

    // 提取当前日期的年、月、日
    let currentYear = currentDate.getFullYear();
    let currentMonth = currentDate.getMonth() + 1;
    let currentDay = currentDate.getDate();

    // 提取身份证中的出生日期的年、月、日
    let birthYear = parseInt(birthDate.substr(0, 4), 10);
    let birthMonth = parseInt(birthDate.substr(4, 2), 10);
    let birthDay = parseInt(birthDate.substr(6, 2), 10);

    // 计算年龄差距
    let age = currentYear - birthYear;
    if (currentMonth < birthMonth || (currentMonth === birthMonth && currentDay < birthDay)) {
        age--;
    }

    // 判断是否满18岁
    let isAdult = age >= 18;

    return isAdult;
}

// 图片url转base64
export function getBase64(imgUrl, callback, token) {
    window.URL = window.URL || window.webkitURL;
    let xhr = new XMLHttpRequest();
    xhr.open("get", imgUrl, true);
    xhr.responseType = "blob";
    xhr.crossOrigin = 'anonymous';
    xhr.onload = function(){
        if(this.status === 200){
            let blob = this.response;
            // 至关重要
            let oFileReader = new FileReader();
            oFileReader.onloadend = function(e){
                // 此处拿到的已经是base64的图片了,可以赋值做相应的处理
                callback(e.target.result, token)
            }
            oFileReader.readAsDataURL(blob);
        }
    }
    xhr.send();
}
export function convertImgToBase64(url, callback, token) {
    let canvas = document.createElement('canvas');
    let ctx = canvas.getContext('2d');
    let img = new Image();
    img.crossOrigin = 'anonymous';// 解决Canvas.toDataURL 图片跨域问题
    img.onload = () => {
      canvas.height = img.height;
      canvas.width = img.width;
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      let ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase(); // 获取到图片的格式
      let dataURL = canvas.toDataURL("image/" + ext); // 得到base64 编码的 dataURL
      callback && callback(dataURL, token);
      canvas = null;
    };
    img.src = url;
}
