import React, { useState, useEffect } from 'react';
import Gift from './gift';
import { queryGiftList, receiveGiftCard } from '../../service';
import { localDS } from '../../storage';
import { message, Modal } from 'antd';
import imgsrc from "@/assets/zwsj.png";
import app, { $action } from '../../model';
import './style.scss';

const Gifts = () => {
    const memberId = localDS.get('memberId');
    const [gifts, setGifts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [cardId, setCardId] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    useEffect(() => {
        queryGiftList(memberId).then((res) => {
            setGifts(res?.data);
            setLoading(false);
        })
    }, []);
    const handleGet = (id) => {
        setCardId(id);
        // setOpen(true);
        app.dispatch(
            $action('confirm').emit({
                open: true,
                content: '请至博物馆大厅服务台领取礼物，与工作人员确认后，再点击"领取”，方可生效（操作失误将无法有效领取）。',
                okText: '领取',
                cancelText: '关闭',
                okCallback: () => {
                    getCard(id);
                }
            })
        );
    }
    const getCard = (id) => {
        receiveGiftCard(id, memberId).then(res => {
            if(res?.code !== 200){
                messageApi.open({
                    type: 'error',
                    content: res?.msg
                })
                return;
            }else{
                messageApi.info('领取成功！');
                setTimeout(() => {
                    queryGiftList(memberId).then((res) => {
                        setGifts(res?.data);
                    })
                }, 200);
            }
        })
    }
    return (
        !loading ? <div className='giftBox allcenter'>
            {contextHolder}
            {
                (gifts?.records?.length > 0) ? gifts?.records?.map((item) => {
                    return (
                        <Gift data={item} key={item.id} handleGet={handleGet}/>
                    )
                }) : <div className="zwsj"><img src={imgsrc} alt="" /></div>
            }
            <Modal
                title="提示"
                open={open}
                onOk={() => {
                    setOpen(false);
                    getCard(cardId);
                }}
                onCancel={() => {setOpen(false);}}
                okText="领取"
                cancelText="关闭"
            >
            <p>请至博物馆大厅服务台领取礼物，与工作人员确认后，再点击&quot;领取&quot;，方可生效（操作失误将无法有效领取）。</p>
            </Modal>
        </div> : <div className="loading">loading...</div>
    )
}

export default Gifts;
