import React, { useState, useEffect } from 'react';
import { history } from '../../routes';
import { message, Modal } from 'antd';
import { delFamily, getDictionaryByCode } from '../../service';
import app, { $action } from '../../model';

function hideId(idNumber = ''){
    let prefix = idNumber.slice(0, 3);
    let postfix = idNumber.slice(idNumber?.length - 4);
    let hidefix = new Array((idNumber?.length - 7) > 0 ? idNumber?.length - 7 : 0).fill('*').join('');
    return `${prefix}${hidefix}${postfix}`;
}

const Members = (props) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [open, setOpen] = useState(false);
    const [docObj, setDocObj] = useState();
    const hideModal = function(){
        setOpen(false);
    }
    const showModal = function(){
        // setOpen(true);
        app.dispatch(
            $action('confirm').emit({
                open: true,
                content: `是否删除该${props?.isGJ ? '携带' : '家庭'}成员？`,
                okText: '确认',
                cancelText: '取消',
                okCallback: () => {
                    del();
                }
            })
        );
    }
    const del = function(){
        delFamily({ "delIds": [props?.data?.id]}).then((res) => {
            if(res?.code === 200){
                messageApi.info('删除成功!');
                setOpen(false);
                setTimeout(() => {
                    let res = props?.del(props?.data?.id);
                    return res;
                }, 1000);
            }
        });
    }
    useEffect(() => {
        getDictionaryByCode("RLY_DOCUMENTTYPE_OPTION", "RLY01").then(res => {
            let docObj = {}
            if(res?.data?.length && res?.data?.length > 0) {
                // eslint-disable-next-line no-unused-expressions
                res?.data?.forEach(item => {
                    docObj[item.id] = item.text
                })
            }
            setDocObj(docObj)
        })
    }, [])
    return (
        <>
        {contextHolder}
        <li className='family-item'>
            <div className='family-msg'>
                <div className="top">
                    <div className='name'><span>姓名</span>{props?.data?.name}{`${props?.data?.ageType ? ' (已成年)' : ' (未成年)'}`}</div>
                    <div className="edit">
                        <i className='iconfont icon-bianji' onClick={ () => { history.push(`/addfamily?id=${props?.data?.id}&type=family&edit=true`) } }/>
                        <i className='iconfont icon-shanchu' onClick={ showModal }/>
                        <Modal
                            title={`是否删除该${props?.isGJ ? '携带' : '家庭'}成员？`}
                            open={open}
                            onOk={del}
                            onCancel={hideModal}
                            okText="确认"
                            cancelText="取消"
                        />
                    </div>
                </div>
                <div className="bottom">
                    <p>
                    {/* <span>身份证号：</span> */}
                    {docObj && <span>{docObj[props?.data?.documentType]}</span>}
                    <span>{hideId(props?.data?.documentNumber)}</span>
                    </p>
                    {/* <p>
                    <span>联系电话：</span>
                    <span>{hideId(props?.data?.phone)}</span>
                    </p> */}
                </div>
            </div>
        </li>
        </>

    )
}

export default Members;
