import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Gift from './gift';
import { queryMemberGiftCardds, queryMemberType, memberBasicInfo, checkCanRenew } from '../../service';
import imgsrc from "@/assets/zwsj.png";
import jhImgsrc from "@/assets/lpk-jh.png";
import gmImgsrc from "@/assets/lpk-gm.png";
import { localDS } from '../../storage';
import app, { $action } from '../../model';
import { history } from '../../routes';
import './style.scss';

const Gifts = () => {
    const [gifts, setGifts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [MemberType, setMemberType] = useState([]);
    const [memberInfor, setMemberInfor] = useState();
    useEffect(() => {
        queryMemberGiftCardds({}).then((res) => {
            setGifts(res?.data);
            setLoading(false);
        })
        queryMemberType().then(res => {
            console.log(res)
            res?.data?.records && setMemberType(res.data.records)
        })
        let documentNumber = localDS.get('documentNumber');
        memberBasicInfo(documentNumber).then(res => {
            if(res?.data) {
                setMemberInfor(res?.data)
            }
        })
    }, []);
    const handleActive = function(id, typeId) {
        const pre = (typeId === '3') ? 'activefamilyvip' : 'activevip';
        if(memberInfor?.id && memberInfor?.isVip === 1) {
            checkCanRenew(memberInfor?.id).then(res => {
                if(res?.data) {
                    history.push(`/${pre}?id=${id}&typeId=${typeId}`);
                } else {
                    app.dispatch(
                        $action('alert').emit({
                            show: true,
                            title: '提示',
                            content: res?.msg
                        })
                    );
                }
            })
        } else {
            history.push(`/${pre}?id=${id}&typeId=${typeId}`);
        }
    }
    return (
        <div className="lpk">
            <div className="lpk-link">
                <Link to="/purchasecard" className="purchasecard">
                    <img src={gmImgsrc} alt="" />
                </Link>
                {/* <Link to="/MemberEquity?type=physical" className="MemberEquity">
                    <img src={jhImgsrc} alt="" />
                </Link> */}
                <span
                    className="MemberEquity"
                    onClick={() => {
                        if(memberInfor?.id && memberInfor?.isVip === 1) {
                            checkCanRenew(memberInfor?.id).then(res => {
                                if(res?.data) {
                                    history.push(`/MemberEquity?type=physical`);
                                } else {
                                    app.dispatch(
                                        $action('alert').emit({
                                            show: true,
                                            title: '提示',
                                            content: res?.msg
                                        })
                                    );
                                }
                            })
                        } else {
                            history.push(`/MemberEquity?type=physical`);
                        }
                    }}
                >
                    <img src={jhImgsrc} alt="" />
                </span>
            </div>
        {!loading ? <div className='giftBox allcenter'>
            {
                (gifts?.records?.length > 0) ? gifts?.records?.map((item, index) => {
                    return (
                        <Gift data={item} key={index} MemberType={MemberType} handleActive={handleActive}/>
                    )
                }) : <div className="zwsj"><img src={imgsrc} alt="" /></div>
            }
        </div> : <div className="loading">loading...</div>}
            <div className="jump-box">
                <Link to="/purchasecard" className="jump-btn">
                    购买分享卡
                </Link>
            </div>
        </div>
    )
}

export default Gifts;
