import React, { useCallback, useRef, useState } from 'react';
import { Form, Input, message, Radio, Modal } from 'antd';
import {bindCards, querySmsVCode, checkHasBind} from '../../service';
import { history } from '../../routes';
import { localDS } from '../../storage';
import cls from 'classnames';
import './style.scss';

const Physical = (props) => {
    const cert = localDS.get('cert');
    const formRef = useRef();
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const onFinish = function(){
        let data = form.getFieldValue();
        let queryParam;
        if(data?.type==='1') {
            queryParam = {
                "type": data?.type,
                "cardNo": data?.cardNo,
                "documentNumber": cert?.showDocumentNumber,
            }
        }  else {
            queryParam = {
                "type": 2,
                "cardNo": data?.cardNo,
                "documentNumber": cert?.showDocumentNumber,
            }
        }
        checkHasBind(queryParam).then(res => {
            if(res?.code !== 200){
                messageApi.open({
                    type: 'error',
                    content: res?.msg,
                });
                return;
            }else{
                if(res?.data === 1) {
                    setIsModalOpen(true);
                } else {
                    handBindCards();
                }
            }
        })
    }
    const handBindCards = ()  => {
        let data = form.getFieldValue();
        let param;
        if(data?.type==='1') {
            param = {
                "type": data?.type,
                "cardNo": data?.cardNo,
                "documentNumber": cert?.showDocumentNumber,
                "smsVCode": data?.smsVCode
            }
        }  else {
            param = {
                "type": 2,
                "cardNo": data?.cardNo,
                "documentNumber": cert?.showDocumentNumber,
            }
        }
        bindCards(param).then((res) => {
            if(res?.code === 200) {
                messageApi.info("绑定成功！");
                setTimeout(() => {
                    history.push('/personal');
                }, 1000);
            } else if(res?.code === 201) {
                // messageApi.info("分享卡待激活");
                // setTimeout(() => {
                //     history.push(`/activevip?id=${res?.data?.cardId}&typeId=${res?.data?.typeId}`);
                // }, 1000);
                const pre = (res?.data?.typeId === '3') ? 'activefamilyvip' : 'activevip';
                history.push(`/${pre}?id=${res?.data?.cardId}&typeId=${res?.data?.typeId}`);
            } else {
                messageApi.open({
                    type: 'error',
                    content: res?.msg,
                });
            }
        })
    }
    const [isSending, setisSending] = useState(false);
    const [time, setTime] = useState(60);
    const timeRef = useRef(time);
    const [cardType,setCardType] = useState('2');
    let timer;
    const vCodeClock = () => {
        timer = setInterval(() => {
            timeRef.current -= 1;
            if(timeRef.current >0) {
                setTime(timeRef.current)
            } else {
                window.clearInterval(timer);
                setisSending(false);
            }
          }, 1000);
    }
    const sendVcode = useCallback(() => {
        if (isSending) return;
        querySmsVCode(cert?.phoneNumber).then(res => {
            if(res?.code !== 200){
                messageApi.open({
                    type: 'error',
                    content: res?.msg,
                });
                return;
            }else{
                messageApi.info(res?.data);
                setisSending(true);
                timeRef.current = 60
                setTime(timeRef.current)
                vCodeClock();
            }
        })
    }, [])
    return (
        <div className='physical-box bindallcard'>
            {contextHolder}
            {/* <div className='physical-bg'> </div> */}
            <div className='physical-card'>
                <Form
                    onFinish={onFinish}
                    form={form}
                    ref={formRef}
                    validateTrigger="onBlur"
                    initialValues={{
                        // type: '1',
                        documentNumber:cert?.showDocumentNumber,
                    }}
                >
                    {/* <Form.Item
                        className="member-item"
                    >
                        <p className='require form-itemtitle'>类型</p>
                        <Form.Item
                            name="type"
                            rules={[{ required: true, message: '请选择类型！' }]}
                            noStyle
                        >
                            <Radio.Group onChange={e => {
                                setCardType(e.target.value)
                            }}>
                                <Radio value="1">会员卡</Radio>
                                <Radio value="2">分享卡</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Form.Item> */}
                    {cardType === '1' && <Form.Item
                        className="member-item"
                    >
                        <p className='require form-itemtitle'>证件号码</p>
                        <Form.Item
                            name="documentNumber"
                            rules={[{ required: true, message: '请输入证件号！' }]}
                            noStyle
                        >
                            <Input placeholder="请输入证件号" disabled/>
                        </Form.Item>
                    </Form.Item>}
                    {cardType === '1' && <Form.Item
                        className="member-item code-member-item"
                        >
                        <p className='require form-itemtitle'>验证码<span>（验证码发送给购买会员时填写的手机号码）</span></p>
                        <Form.Item
                            name="smsVCode"
                            rules={[{ required: true, message: '请输入验证码！' }]}
                            noStyle
                        >
                            <Input className='vcode-input' placeholder="请输入验证码" />
                        </Form.Item>
                        <div
                            onClick={() => {
                                sendVcode();
                            }}
                            className={cls('vcode-btn yzmbox', {
                                disable: isSending
                            })}
                        >
                            {isSending
                                ? `${time}秒后重新发送`
                                : '获取验证码'}
                        </div>
                    </Form.Item>}
                    <Form.Item
                        className="member-item"
                    >
                        <p className={cardType === '2' ? 'require form-itemtitle' : 'form-itemtitle'}>分享卡卡号</p>
                        <Form.Item
                            name="cardNo"
                            rules={[
                                { required: cardType === '2' ? true : false, message: '请输入分享卡卡号！' },
                                { pattern: /\d+/g, message: '请输入正确的分享卡卡号！' },
                            ]}
                            noStyle
                        >
                            <Input placeholder="请输入分享卡卡号"/>
                        </Form.Item>
                    </Form.Item>
                <div className="physical-btn">
                    <button className="confirm" htmltype="submit">立即绑定</button>
                </div>
                </Form>
                <Modal
                    title="提示"
                    open={isModalOpen}
                    onCancel={() => setIsModalOpen(false)}
                    onOk={() => {
                        setIsModalOpen(false);
                        handBindCards();
                    }}
                    okText="确认"
                    cancelText="关闭"
                >
                    <p>已绑定过实体卡号，是否更新？</p>
                </Modal>
            </div>
        </div>
    )
}

export default Physical;
