import React, { useState, useEffect } from 'react';
import { history } from '../../routes';
import { queryMemberTypeById, getOrderById } from '../../service';
// import { getMemberExpire } from '../../utils/validator';
import './style.scss';

const ApplyPass = (props) => {
    const state = new URLSearchParams(window?.location?.search)?.get('state');
    const orderId = props?.orderId;
    const [memberType, setMemberType] = useState({});
    const [orderInfor, setOrderInfor] = useState();
    // const state = 'success';
    // const state = 'fail';
    // const state = 'audit';

    // const [startDate, endDate] = getMemberExpire();
    useEffect(() => {
        getOrderById(orderId).then((res) => {
            const data = res?.data;
            if (data) {
                // const tempInfo = JSON.parse(data?.tempInfo);
                // console.log(tempInfo);
                setOrderInfor(res?.data);
                if(res?.data?.operationType === 'POI0105') {
                    document.title = '会员续费';
                }
                queryMemberTypeById({ id: data?.memberType }).then((res) => {
                    setMemberType(res?.data);
                });
            }
        });
    }, []);

    return (
        <div className="applypass">
            {state === 'success' && orderInfor && (
                <>
                    <div className={`apply-pic ${orderInfor?.showState === 4 ? 'audit' : ''} ${orderInfor?.operationType === 'POI0105' ? 'xf' : ''}`}>
                        {' '}
                    </div>
                    <div className="apply-msg">
                        <p>
                            会员类型：<span>{memberType?.typeName}</span>
                        </p>
                        {orderInfor?.showState !== 4 && (
                            <p>
                                生效时间：
                                <span>
                                    {orderInfor?.effectiveStartDate}至{orderInfor?.effectiveEndDate}
                                </span>
                            </p>
                        )}
                        <p>
                            支付金额：<span>￥{orderInfor?.payAmount}</span>
                        </p>
                    </div>
                    {orderInfor?.showState === 4 ?
                    <p className="welcome">{`您提交的${memberType?.typeName}信息中的“校友身份”正在审核，将于7个工作日反馈结果，本馆审核通过后即刻生效会员资格。审核未通过，您的支付金额将于15个工作日内原路径退回。 `}</p>  :
                    <p className="welcome">
                        {memberType?.typeName === '个人会员' &&
                            `恭喜您已成功办理清华艺博${memberType?.typeName}！会员卡为实名制，限本人持有，在享受会员权益时请出示会员电子码或者身份证件。感谢您的支持！`}
                        {memberType?.typeName === '家庭会员' &&
                            `恭喜您已成功办理清华艺博${memberType?.typeName}！会员卡为实名制，限已注册的家庭成员持有，在享受会员权益时请出示会员电子码或者身份证件。感谢您的支持！`}
                        {memberType?.typeName === '尊享会员' &&
                            `恭喜您已成功办理清华艺博${memberType?.typeName}！会员卡为实名制，限本人持有，在享受会员权益时请出示会员电子码或者身份证件。感谢您的支持！`}
                    </p>}
                </>
            )}
            {state === 'fail' && (
                <p className="welcome fail">支付失败，请在会员中心 &gt; 申请记录中查看订单！</p>
            )}
            <div className="back">
                <button
                    className="confirm"
                    onClick={() => {
                        history.push(`${state === 'fail' ? '/applyrecords' : '/personal'}`);
                    }}
                >
                    {state === 'fail' ? '申请记录' : '会员中心'}
                </button>
                {/* <button onClick={
                    () => { history.push('/personal'); }
                }>会员中心</button> */}
            </div>
            <div className="empty"> </div>
        </div>
    );
};

export default ApplyPass;
