/* eslint-disable complexity */
import React, { useState, useEffect } from 'react';
import { queryMemberInfoById, memberBasicInfo, queryMemberTypeById, getDictionaryByCode, updateInfor } from '../../service';
import { localDS } from '../../storage';
import { Image, Input, Select } from 'antd';
import {isEmail, isPhoneNum} from '../../utils/validator';
import app, { $action } from '../../model';
import './style.scss';

const MyInfo = () => {
    const { Option } = Select;
    const memberId = localDS.get('memberId');
    const cert = localDS.get('cert');
    const [memberInfo, setMemberInfo] = useState({});
    const [typeInfo, setTypeInfo] = useState({});
    const [eduType, setEduType] = useState('');
    const [industryType, setIndustryType] = useState('');
    const [cardType, setCardType] = useState('');
    const [edit, setEdit] = useState(false);
    const [education, setEducation] = useState([]);
    const [industry, setIndustry] = useState([]);

    const [valEdu, setValEdu] = useState(memberInfo?.education);
    const [valEmail, setValEmail] = useState(memberInfo?.email);
    const [valPhone, setValPhone] = useState(memberInfo?.phone);
    const [valIndustry, setValIndustry] = useState(memberInfo?.industry);
    useEffect(() => {
        (async function() {
            let documentNumber = localDS.get('documentNumber');
            let info = await memberBasicInfo(documentNumber);
            setMemberInfo(info?.data);
            setValEdu(info?.data?.education);
            setValEmail(info?.data?.email);
            setValPhone(info?.data?.phone);
            setValIndustry(info?.data?.industry);
            let typeId = info?.data?.typeId;
            let typeInfo = await queryMemberTypeById({ id: typeId });
            setTypeInfo(typeInfo?.data);
            // 学历
            let eduType = await getDictionaryByCode('RLY_EDUCATION_TYPE_OPTION', 'RLY03');
            let eduRes = eduType?.data?.filter((item) => {
                return item?.id === info?.data?.education;
            });
            setEducation(eduType?.data);
            setEduType(eduRes?.[0]?.text);
            // 行业
            let industryType = await getDictionaryByCode('RLY_INDUSTRY_OPTION', 'RLY04');
            setIndustry(industryType?.data);
            let industryRes = industryType?.data?.filter((item) => {
                return item?.id === info?.data?.industry;
            });
            setIndustryType(industryRes?.[0]?.text);

            let cardType = await getDictionaryByCode('RLY_DOCUMENTTYPE_OPTION', 'RLY01');
            let cardRes = cardType?.data?.filter((item) => {
                return item?.id === info?.data?.documentType;
            });
            setCardType(cardRes?.[0]?.text);
        })();
    }, []);
    const handleChange = () => {
        if(valEmail && !isEmail(valEmail)) {
            app.dispatch(
                $action('alert').emit({
                    show: true,
                    title: '提示',
                    content: '请输入正确的邮箱。'
                })
            );
            return;
        }
        if(!isPhoneNum(valPhone)) {
            app.dispatch(
                $action('alert').emit({
                    show: true,
                    title: '提示',
                    content: '请输入正确的联系电话。'
                })
            );
            return;
        }
        updateInfor({
            education: valEdu,
            email: valEmail,
            phone: valPhone,
            industry: valIndustry,
            id: memberInfo?.id
        }).then(res => {
            if(res?.code === 200) {
                app.dispatch(
                    $action('alert').emit({
                        show: true,
                        title: '提示',
                        content: '修改成功。'
                    })
                );
                setEdit(false)
                let documentNumber = localDS.get('documentNumber');
                memberBasicInfo(documentNumber).then(rs => {
                    if(rs?.data) {
                        setMemberInfo(rs?.data);
                        let industryRes = industry?.filter((item) => {
                            return item?.id === rs?.data?.industry;
                        });
                        setIndustryType(industryRes?.[0]?.text);

                        let eduRes = education?.filter((item) => {
                            return item?.id === rs?.data?.education;
                        });
                        setEduType(eduRes?.[0]?.text);
                    }
                })
            } else {
                app.dispatch(
                    $action('alert').emit({
                        show: true,
                        title: '提示',
                        content: res?.msg
                    })
                );
            }
        })
    }
    return (
        <div className="myinfo allcenter">
            <h3>个人信息</h3>
            <ul className="info-list">
                {(memberInfo?.memberName || cert?.customerName) && (
                    <li className="info-item">
                        <span>姓名</span>
                        <span>{memberInfo?.memberName || cert?.customerName}</span>
                    </li>
                )}

                {memberInfo?.birthday && memberInfo?.birthday !== 'false' && (
                    <li className="info-item">
                        <span>出生日期</span>
                        <span>{memberInfo?.birthday}</span>
                    </li>
                )}
                {typeof memberInfo?.gender === 'number' && (
                    <li className="info-item">
                        <span>性别</span>
                        <span>{memberInfo?.gender === 1 ? '男' : '女'}</span>
                    </li>
                )}
                {eduType && (
                    <li className="info-item">
                        <span>学历</span>
                        {edit ?
                        <Select onChange={(val) => {
                            setValEdu(val)
                        }} defaultValue={memberInfo?.education}>
                            {education?.map((item) => {
                                return (
                                    <Option value={item?.id} key={item?.id}>
                                        {item?.text}
                                    </Option>
                                );
                            })}
                        </Select>
                        : <span>{eduType}</span>}
                    </li>
                )}
                {(cardType || cert?.documentTypeName) && (
                    <li className="info-item">
                        <span>证件类型</span>
                        <span>{cardType || cert?.documentTypeName}</span>
                    </li>
                )}
                {(memberInfo?.documentNumber || cert?.documentNumber) && (
                    <li className="info-item">
                        <span>证件号码</span>
                        <span>{memberInfo?.documentNumber || cert?.documentNumber}</span>
                    </li>
                )}
                {memberInfo?.email && (
                    <li className="info-item">
                        <span>电子邮箱</span>
                        {edit ?
                        <Input type="text" defaultValue={memberInfo?.email} onChange={(e) => {
                            setValEmail(e.target.value)
                        }}/>
                        : <span>{memberInfo?.email}</span>}

                    </li>
                )}
                {(memberInfo?.phone || cert?.phoneNumber) && (
                    <li className="info-item">
                        <span>联系电话</span>
                        {edit ?
                        <Input type="text" defaultValue={memberInfo?.phone || cert?.phoneNumber} onChange={(e) => {
                            setValPhone(e.target.value)
                        }}/>
                        : <span>{memberInfo?.phone || cert?.phoneNumber}</span>}
                    </li>
                )}
                {industryType && (
                    <li className="info-item">
                        <span>所在行业</span>
                        {edit ?
                        <Select onChange={(val) => {
                            setValIndustry(val)
                        }} defaultValue={memberInfo?.industry}>
                            {industry?.map((item) => {
                                return (
                                    <Option value={item?.id} key={item?.id}>
                                        {item?.text}
                                    </Option>
                                );
                            })}
                        </Select>
                        : <span>{industryType}</span>}
                    </li>
                )}
                {typeof memberInfo?.isSchoolFriend === 'number' && (
                    <li className="info-item">
                        <span>是否为清华校友</span>
                        <span>{memberInfo?.isSchoolFriend ? '是' : '否'}</span>
                    </li>
                )}
                {memberInfo?.photo && (
                    <li className="info-photo">
                        <span>照片</span>
                        <br />
                        <Image
                            className="memberPic"
                            width={200}
                            src={`${window.FILE_IMG_URL}${memberInfo?.photo}`}
                        />
                    </li>
                )}
                {memberInfo?.studentCardPhoto && (
                    <li className="info-photo">
                        <span>相关校友证件照片</span>
                        <br />
                        <Image
                            className="memberPic"
                            width={200}
                            src={`${window.FILE_IMG_URL}${memberInfo?.studentCardPhoto}`}
                        />
                    </li>
                )}
            </ul>
            <div className="btn-edit">
                {edit ? <button
                    onClick={() => {
                        handleChange()
                    }}
                >
                    确认
                </button> : <button
                    onClick={() => {
                        setEdit(true)
                    }}
                >
                    修改
                </button> }
            </div>
            <div className="empty" />
        </div>
    );
};

export default MyInfo;
