import React, { useState, useEffect } from 'react';
import {
    queryFamilyInfo,
    queryMemberTypeById,
} from '../../service';
import { localDS } from '../../storage';
import Members from './members';
import app, { $action } from '../../model';
import { history } from '../../routes';

const ActivevipAddmember = () => {
    const param = new URLSearchParams(window.location.search);
    const memberId = param.get('memberid') || localDS.get('memberId');
    const [takeMembers, setTakeMembers] = useState([]);
    const [memberTotal, setMemberTotal] = useState(0);
    const [vipinfor, setVipinfor] = useState();
    useEffect(() => {
        queryMemberTypeById({ id: '3' }).then((res) => {
            const data = res?.data;
            setVipinfor(data);
        });
        queryFamilyInfo({ memberId: memberId, typeId: '3' }).then(
            (res) => {
                setTakeMembers(res?.data?.records);
                setMemberTotal(res?.data?.total);
            }
        );
    }, [])
    const delMember = function(id) {
        const nowlist = takeMembers?.filter((item) => item.id !== id);
        let res = nowlist;
        setTakeMembers(nowlist);
        setMemberTotal(memberTotal - 1);
        return res;
    };
    return <div className="activevip-addmember">
        <div className="member-box">
            <p className="tips">
                家庭成员信息
                {(vipinfor?.adultNum || vipinfor?.adultNum === 0) && <span>{`（可添加${Number(vipinfor?.adultNum) + 1}名成年人，${vipinfor?.childrenNum}名18岁以下未成年人，包括申请人在内不可超过${Number(vipinfor?.adultNum) + Number(vipinfor?.childrenNum) + 1}人。绑定后家庭成员不可修改。）`}</span>}
            </p>
            <ul className="member-list">
                {takeMembers?.map((item) => {
                    return (
                        <Members
                            data={item}
                            key={item?.id}
                            del={delMember}
                        />
                    );
                })}
            </ul>
            <div className="btn-box">
                <button
                    className="share"
                    onClick={(e) => {
                        const yong = takeMembers.filter((i) => i.ageType === 0);
                        const adult = takeMembers.filter((i) => i.ageType === 1);
                        if (yong?.length >= vipinfor?.childrenNum && adult?.length >= vipinfor?.adultNum) {
                            app.dispatch(
                                $action('alert').emit({
                                    show: true,
                                    title: '提示',
                                    content: '家庭成员人数已满'
                                })
                            );
                        } else {
                            const searchUrl = `&typeId=${vipinfor?.id}`;
                            const id = memberId;
                            e.preventDefault();
                            history.push(`/addfamily?memberid=${id}${searchUrl}`);
                        }
                    }}
                ><span>+</span>添加成员</button>
            </div>
        </div>
        <div className="btn-confirm">
            <button
                onClick={() => {
                    app.dispatch(
                        $action('confirm').emit({
                            open: true,
                            content: '确认添加后，将不可增减或修改家庭成员信息，请您确认是否完成添加？',
                            okText: '确认',
                            okCallback: () => {
                                history.push('/personal');
                            }
                        })
                    );
                }}
            >
                确认添加
            </button>
        </div>
    </div>
}

export default ActivevipAddmember;
