import React, { useEffect, useState } from 'react';
import { queryMemberRules, memberBasicInfo } from '../../service';
import { Button } from '../../shared/Button';
import { history } from '../../routes';
import app, { $action } from '../../model';
import { localDS } from '../../storage';
import './style.scss';

const Charter = (props) => {
    // const param = new URLSearchParams(props.location.search);
    // const isvip = param.get('isvip');
    const [memberInfor, setMemberInfor] = useState();
    const [charter, setCharter] = useState(null);
    const [isRequest, setIsRequest] = useState(false);
    useEffect(() => {
        queryMemberRules('POI0702').then((res) => {
            // eslint-disable-next-line no-console
            res?.data?.content && setCharter(res?.data?.content);
            setIsRequest(true);
        });
        let documentNumber = localDS.get('documentNumber');
        memberBasicInfo(documentNumber).then(res => {
            if(res?.data) {
                setMemberInfor(res?.data)
            }
        })
    }, []);

    const [isTextScrollEnd, setIsTextScrollEnd] = useState(true);
    // function hasScrollbar(element) {
    //     return element.scrollHeight > element.clientHeight;
    // }
    // const handleScroll = () => {
    //     const element = document.getElementById('text-cont'); // 文字列表框的元素ID

    //     // 判断是否滚动到底部
    //     if (element.scrollHeight - element.scrollTop <= element.clientHeight) {
    //       setIsTextScrollEnd(true);
    //     } else {
    //       setIsTextScrollEnd(true);
    //     }
    // };
    // useEffect(() => {
    //     const element = document.getElementById('text-cont'); // 文字列表框的元素ID
    //     if(element && hasScrollbar(element)) {
    //         setIsTextScrollEnd(true);
    //         element.addEventListener('scroll', handleScroll);
    //         return () => {
    //           element.removeEventListener('scroll', handleScroll);
    //         };
    //     } else {
    //         setIsTextScrollEnd(true);
    //     }
    // }, [charter]);
    return (
        isRequest && (<div className="charter-page">
            <div className="charter-desc allcenter">
                <h2 className="title">清华大学艺术博物馆会员章程</h2>
                <div id="text-cont" className="cont" dangerouslySetInnerHTML={{ __html: charter }} />
            </div>
            <div className="fullbtn">
                <Button
                    disable={!isTextScrollEnd}
                    onClick={() => {
                        // if(memberInfor?.isVip === 1) {
                        //     app.dispatch(
                        //         $action('alert').emit({
                        //             show: true,
                        //             content: '您已是清华大学艺术博物馆会员。'
                        //         })
                        //     );
                        // } else {
                        //     history.push(`/vipclassify`);
                        // }
                        history.push(`/vipclassify`);
                    }}
                >
                    我已阅读章程
                </Button>
            </div>
        </div>)
    );
};

export default Charter;
