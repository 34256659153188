import React, { useState, useEffect } from 'react';
import { queryFamilyInfo, queryMemberTypeById } from '../../service';
import FamilyMsg from '@/components/FamilyMessage/familymsg';
import { history } from '../../routes';
import { localDS } from '../../storage';
import app, { $action } from '../../model';
import './style.scss';

const TakeMember = () => {
    const [takeMembers, setTakeMembers] = useState([]);
    const [memberTotal, setMemberTotal] = useState(0);
    const [vipTypeInfo, setVipTypeInfo] = useState();
    const delMember = function(id) {
        let res = setTakeMembers(takeMembers?.filter((item) => item.id !== id));
        setMemberTotal(memberTotal - 1);
        return res;
    };
    useEffect(() => {
        const memberId = localDS.get('memberId');
        queryFamilyInfo({ memberId, typeId: '4' }).then((res) => {
            setTakeMembers(res?.data?.records);
            setMemberTotal(res?.data?.total);
        });
        queryMemberTypeById({ id: 4 }).then((res) => {
            res?.data && setVipTypeInfo(res.data);
        });
    }, []);
    return (
        <div className="takeMember">
            {/* <h3>提示：携带成员增加1天后才可生效，请提前添加，避免不能享受相关服务权益。</h3> */}
            <div className="addTakeMember">
                {vipTypeInfo && (
                    <button
                        onClick={() => {
                            if(Number(vipTypeInfo?.adultNum) + Number(vipTypeInfo?.childrenNum) <= memberTotal) {
                                app.dispatch(
                                    $action('alert').emit({
                                        show: true,
                                        title: '提示',
                                        content: '携带成员人数已满'
                                    })
                                );
                            } else {
                                history.push('/addfamily?type=premium&typeId=4');
                            }
                        }}
                    >
                        +增加携带成员（可添加
                        {vipTypeInfo?.adultNum
                            ? Number(vipTypeInfo?.adultNum) + Number(vipTypeInfo?.childrenNum)
                            : 0}
                        人，已添加{memberTotal}人）
                    </button>
                )}
            </div>
            <ul className="family-list">
                {takeMembers?.map((item) => {
                    return <FamilyMsg data={item} key={item?.id} del={delMember} type="with" />;
                })}
            </ul>
        </div>
    );
};

export default TakeMember;
