/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import { localDS } from '../storage';
import CryptoJS from 'crypto-js';
// import app, { $action } from '../model';

// TODO 尽可能的隐藏 否则裸奔这么做就没有意义了
// export const apiId = 'e4ff5c080a5f4fb59205d9ccf0cf99f0';
// export const apikey = 'PHJ5E1KOKZ5YG4KMFPGJZ16M3M2KKMIK';
export const apiId = '1b704e30bee34fb380ffc8bce04b1067';
export const apikey = '2EDIZQANNGTL8YRMGK967KI77Q03WS6C';
export const gateWay = window.gateWay;

/**
 *接口加密
 *
 * @export
 * @param {string} key -apiKey
 * @returns {string}
 */
window.CryptoJS = CryptoJS;
export function Encrypt(key) {
    const r = Math.random();

    const randomSix = String(r)
        .split('.')[1]
        .slice(0, 6);
    const text = randomSix + Number(new Date());
    const encrypt = CryptoJS.AES.encrypt(text, CryptoJS.enc.Utf8.parse(key), {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });

    return encrypt.toString();
}
const search = new URLSearchParams(window.location.search);
const Authorizationc = search.get('token');
const testToken = Authorizationc || localDS.get('Authorizationc');
// const testToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwaG9uZSI6IjEzOTExMTEyMjIyIiwicGhvbmVJbnZhbGlkIjowLCJuYW1lIjoic2Fhc3VzZXIiLCJjdXN0b21lcklkIjoiZjg3YzZlMDAxNzcyMjI5MmIwNmJiMWQ3NGRlN2YyNzEifQ.QokNKLu_IIWgQXi7h38Zwq0PSUedOVXuDrKSTLvrPlc"

export const http = axios.create({
    baseURL: window.API
});

export const baiduHttp = axios.create({
    baseURL: 'https://member.artmuseum.tsinghua.edu.cn/bcloud/'
});

http.interceptors.request.use((config) => {
    const token = localDS.get('token');

    // config.headers.common['Authorizationc'] = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwaG9uZSI6IjE1NTI3NjkwMTIzIiwicGhvbmVJbnZhbGlkIjowLCJuYW1lIjoiMTU1Mjc2OTAxMjMiLCJjdXN0b21lcklkIjoiZDYxOTY1ODkxZTEzZGI4NTQ2MmUzYzUzY2IxOWJiY2YifQ.dK9P9W5vrG5QDcIxfTXjszDRApLMjFDK_ZhNIx8en7g";
    config.headers.common['Authorizationc'] = testToken;

    return config;
});
http.interceptors.request.use((config) => {
    config.headers.common['token'] = Encrypt(apikey);
    config.headers.common['appId'] = apiId;
    // config.headers.common['Login-Type'] = 'mobile';
    return config;
});


http.interceptors.response.use(
    (res) => {
        // 未登录跳转至登录界面
        if (res.data.code === 310) {
            // eslint-disable-next-line no-console
            console.log(res)
            // const pathname = window.location.pathname;
            // const search = window.location.search ?? '';
            // if (!exclude.includes(pathname + search)) {
            //     window.location.href = '/';
            // }
        }
        return res;
    },
    (error) => {
        const status = error.response?.status;
        const statusText = error.response?.statusText;
        const message = error.response?.data?.message;
        const pathname = window.location.pathname;
        const search = window.location.search ?? '';
        if(status === 401) {
            localDS.remove('token');
            // app.dispatch(
            //     $action('alert').emit({
            //         show: true,
            //         title: '提示',
            //         content: '登录信息失效，请返回首页重新登录',
            //         callback: () => {
            //             // token过期返回小程序首页
            //             const searchparam = new URLSearchParams(window.location.search);
            //             const Authorizationc = searchparam.get('token');
            //             const oldToken = localDS.get('Authorizationc');
            //             if (Authorizationc && (Authorizationc !== oldToken)) {
            //                 console.log('in')
            //                 localDS.remove('Authorizationc');
            //                 localDS.set('Authorizationc', Authorizationc);
            //             } else {
            //                 console.log('back')
            //                 wx.miniProgram.navigateTo({
            //                     url: '/pages/main/index'
            //                 })
            //                 // wx.navigateToMiniProgram({
            //                 //     appId: 'wx53fcff183e3352aa',
            //                 //     path: '/pages/main/index', // 小程序首页路径
            //                 //     extraData: { // 需要传递的数据
            //                 //       // 可以在小程序首页中获取的数据
            //                 //     },
            //                 //     success(res) {
            //                 //       // 打开成功
            //                 //     }
            //                 // })
            //             }
            //         }
            //     })
            // );
        }
        // eslint-disable-next-line no-console
        console.log(
            `🚑🚑🚑请求失败:错误状态码:${status},错误描述1：${statusText},详细信息：${message}`
        );
    }
);

export const saashttp = axios.create({
    baseURL: window.saasApi
});

saashttp.interceptors.request.use((config) => {
    // const token = localDS.get('token');
    const search = new URLSearchParams(window.location.search);
    const Authorizationc = search.get('token');
    config.headers.common['Authorizationc'] = Authorizationc || testToken;

    return config;
});
saashttp.interceptors.request.use((config) => {
    config.headers.common['token'] = Encrypt(apikey);
    config.headers.common['appId'] = apiId;
    return config;
});


saashttp.interceptors.response.use(
    (res) => {
        // 未登录跳转至登录界面
        if (res.data.code === 310) {
            // eslint-disable-next-line no-console
            console.log(res)
        }
        return res;
    },
    (error) => {
        const status = error.response?.status;
        const statusText = error.response?.statusText;
        const message = error.response?.data?.message;
        const pathname = window.location.pathname;
        const search = window.location.search ?? '';
        if(status === 401) {
            localDS.remove('token');
        }
        // eslint-disable-next-line no-console
        console.log(
            `🚑🚑🚑请求失败:错误状态码:${status},错误描述：${statusText},详细信息：${message}`
        );
    }
);

export const noTokenHttp = axios.create({
    baseURL: window.API
});

noTokenHttp.interceptors.request.use((config) => {
    config.headers.common['token'] = Encrypt(apikey);
    config.headers.common['appId'] = apiId;
    // config.headers.common['Login-Type'] = 'mobile';
    return config;
});


noTokenHttp.interceptors.response.use(
    (res) => {
        // 未登录跳转至登录界面
        if (res.data.code === 310) {
            // eslint-disable-next-line no-console
            console.log(res)
            // const pathname = window.location.pathname;
            // const search = window.location.search ?? '';
            // if (!exclude.includes(pathname + search)) {
            //     window.location.href = '/';
            // }
        }
        return res;
    },
    (error) => {
        const status = error.response?.status;
        const statusText = error.response?.statusText;
        const message = error.response?.data?.message;
        const pathname = window.location.pathname;
        const search = window.location.search ?? '';
        if(status === 401) {
            localDS.remove('token');
        }
        // eslint-disable-next-line no-console
        console.log(
            `🚑🚑🚑请求失败:错误状态码:${status},错误描述：${statusText},详细信息：${message}`
        );
    }
);
