import React from 'react';

export default function Icon(props) {
  const classNames = 'g-icon-container';
  return (
    <i style={{ ...props.style }} className={classNames}>
      {props.children}
    </i>
  );
}
