import React, { useState, useEffect, useRef } from 'react';
import { localDS } from '../../storage';
import {
    uploadAvatar,
    getDictionaryByCode,
    memberBasicInfo,
    activeCardMember,
    queryMemberTypeById,
    queryBaiduTOken,
    queryBaiduVerify,
    queryMemberRules
} from '../../service';
import { Select, Input, Upload, Form, Radio, message, Modal, Image as Img, DatePicker, Checkbox } from 'antd';
import { history } from '../../routes';
import dayjs from 'dayjs';
import { dealdate, DocToBirth, DocToAdult, DealBirthday } from '../../utils/validator';
import 'dayjs/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import app, { $action } from '../../model';
import './style.scss';

// 不同证件号码的正则表达式
// 这里主要根据type值与接口的字段进行匹配，决定使用哪种正则表达式进行校验，接口字段变化时，改type的值即可
const regs = [
    {
        type: '身份证',
        require: { required: true, message: '请输入身份证号!' },
        pattern: {
            pattern: /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|30|31)|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/,
            message: '请输入正确的证件号！'
        }
    },
    {
        type: '护照',
        require: { required: true, message: '请输入护照号码!' },
        pattern: { pattern: /^([a-zA-z]|[0-9]){5,17}$/, message: '请输入正确的护照号码!' }
    },
    {
        type: '台胞',
        require: { required: true, message: '请输入台湾居民来往大陆通行证号码!' },
        pattern: {
            pattern: /^\d{8}|^[a-zA-Z0-9]{10}|^\d{18}$/,
            message: '请输入正确的台湾居民来往大陆通行证号码!'
        }
    },
    {
        type: '港澳',
        require: { required: true, message: '请输入港澳居民来往内地通行证!' },
        pattern: {
            pattern: /^([A-Z]\d{6,10}(\(\w{1}\))?)$/,
            message: '请输入正确的港澳居民来往内地通行证!'
        }
    },
    {
        type: '军官',
        require: { required: true, message: '请输入军官证!' },
        pattern: {
            pattern: /^[\u4E00-\u9FA5](字第)([0-9a-zA-Z]{4,8})(号?)$/,
            message: '请输入正确的军官证!'
        }
    }
];

const activefamilyvip = (props) => {
    const cert = localDS.get('cert');
    const { Option } = Select;
    const formRef = useRef();
    const [cardType, setCardType] = useState([]);
    const [cardDirectionary, setCardDirectionary] = useState([]);
    const [picUrl, setPicUrl] = useState('');
    // const [cardId, setCardId] = useState('');
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    // const [familyInfo, setFamilyInfo] = useState({});
    const [photoShow, setPhotoShow] = useState(true);
    const [form] = Form.useForm();
    const [education, setEducation] = useState([]);
    const [educationId, setEducationId] = useState('');
    const [infor, setInfor] = useState('');
    const [memberType, setMemberType] = useState({});
    const [isrequest, setIsrequest] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [isHuman, setIsHuman] = useState(true);
    const [industry, setIndustry] = useState([]);
    const [industryId, setIndustryId] = useState('');
    const param = new URLSearchParams(props.location.search);
    const [isConfirm, setIsconfirm] = useState(false);
    const [tkopen, setTkopen] = useState(false);
    const [charter, setCharter] = useState('');
    const id = param.get('id');
    const typeId = param.get('typeId');
    // 将文件信息保存到FileReader中
    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    // 控制图片预览窗口关闭
    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    useEffect(() => {
        // 获取会员家庭成员信息或者携带成员信息
        (async function() {
            const cardType = await getDictionaryByCode('RLY_DOCUMENTTYPE_OPTION', 'RLY01');
            let selectOpt = [];
            if (cardType?.data && cardType?.data?.forEach) {
                cardType.data.forEach((item) => {
                    if (item?.text?.includes('行驶')) {
                        return;
                    }
                    selectOpt.push({ value: item?.id, label: item?.text });
                });
                setCardDirectionary(selectOpt);
            }
        })();
        getDictionaryByCode('RLY_EDUCATION_TYPE_OPTION', 'RLY03').then((res) => {
            setEducation(res?.data);
        });
        getDictionaryByCode('RLY_INDUSTRY_OPTION', 'RLY04').then((res) => {
            setIndustry(res?.data);
        });
        let documentNumber = localDS.get('documentNumber');
        memberBasicInfo(documentNumber).then((res) => {
            res?.data && setInfor(res.data);
            setIsrequest(true);
        });
        queryMemberTypeById({ id: typeId }).then((res) => {
            setMemberType(res?.data);
        });
        queryMemberRules('POI0702').then((res) => {
            setCharter(res?.data?.content);
        });
    }, []);

    const onFinish = function() {
        if (!picUrl && !infor?.photo) {
            messageApi.open({
                type: 'error',
                content: '请正确上传图片！'
            });
            return;
        }
        if (!isHuman) {
            messageApi.open({
                type: 'error',
                content: '请露正脸拍照，以便入馆识别，请重新拍照！'
            });
            return;
        }
        if (!isConfirm) {
            app.dispatch(
                $action('alert').emit({
                    show: true,
                    title: '提示',
                    content: '请认真阅读《会员章程》，勾选确认后再申请办理。'
                })
            );
            handleCheckScroll();
            return;
        }
        let data = form.getFieldValue();
        if (
            data?.cardType === 'RLY0101' &&
            !DocToAdult(data.cardId) &&
            ['2', '3', '4'].indexOf(memberType?.id) > -1
        ) {
            messageApi.open({
                type: 'error',
                content: `仅年满18周岁及以上可申请${memberType?.typeName}`
            });
            return;
        }
        let filterData = {
            // memberId: ,
            typeId,
            cardId: id,
            memberName: data?.username,
            // birthday: `${data?.birthday?.$y}/${Number(data?.birthday?.$M) + 1}/${
            //     data?.birthday?.$D
            // }`,
            birthday: data?.birthday && DealBirthday(data?.birthday),
            documentType: data?.cardType,
            documentNumber: data?.cardId,
            phone: data?.phonenumber,
            photo: picUrl || infor?.photo,
            gender: data?.gender,
            isSchoolFriend: data?.isSchoolFriend,
            education: educationId || infor?.education,
            email: data?.email,
            industry: industryId || infor?.industry
        };
        activeCardMember(filterData).then((res) => {
            // if (res?.code !== 200) {
            //     messageApi.open({
            //         type: 'error',
            //         content: res?.msg ? res.msg : `${res?.msg},${res?.code},${res?.data}`
            //     });
            //     return;
            // } else {
            //     messageApi.info('激活成功，请添加家庭成员');
            //     if(res?.data) {
            //         localDS.remove('memberId');
            //         localDS.set('memberId', res?.data);
            //     }
            //     setTimeout(() => {
            //         history.push(`/activevipAddmember?memberid=${res?.data}`);
            //     }, 1000);
            // }

            if (res?.code === 200 && res?.data) {
                messageApi.info('激活成功，请添加家庭成员');
                localDS.remove('memberId');
                localDS.set('memberId', res?.data);
                setTimeout(() => {
                    history.push(`/activevipAddmember?memberid=${res?.data}`);
                }, 1000);
            } else {
                app.dispatch(
                    $action('alert').emit({
                        show: true,
                        title: '提示',
                        content: res?.msg ? res.msg : `${res?.msg},${res?.code},${res?.data}, 激活失败`
                    })
                );
                return;
            }
        });
    };

    const chooseCardType = function(e) {
        let label = '';
        let cardLabel = cardDirectionary.filter((item) => {
            return item?.value === e;
        });
        label = cardLabel?.[0]?.label;
        let filterCardType = regs.filter((item) => {
            return label.includes(item?.type);
        });
        let type = [filterCardType?.[0]?.require, filterCardType?.[0]?.pattern];
        setCardType(type);
        // setCardId(cardLabel?.[0]?.value);
    };

    // 人脸识别验证
    const Verify = (val, access_token) => {
        let data = {
            image: val.replace(/^data:image\/\w+;base64,/, ''),
            image_type: 'BASE64',
            // 'image': val,
            // 'image_type': 'URL',
            max_face_num: 2,
            face_field: 'quality'
        };
        queryBaiduVerify(access_token, data).then((res) => {
            if (res.error_msg === 'SUCCESS') {
                let result = res.result;
                if (result.face_num > 1) {
                    messageApi.open({
                        type: 'error',
                        content: '多人一起拍照将导致无法刷脸入馆，请重新拍照'
                    });
                } else if (result.face_list[0].quality.blur > 0.5) {
                    messageApi.open({
                        type: 'error',
                        content: '照片太模糊，请重新拍照'
                    });
                } else if (result.face_list[0].quality.illumination < 60) {
                    messageApi.open({
                        type: 'error',
                        content: '脸部太暗，请重新拍照'
                    });
                } else if (result.face_list[0].quality.completeness === 0) {
                    messageApi.open({
                        type: 'error',
                        content: '脸部拍摄不完整，请重新拍照'
                    });
                } else if (
                    result.face_list[0].quality.occlusion.left_eye > 0.6 ||
                    result.face_list[0].quality.occlusion.right_eye > 0.6
                ) {
                    messageApi.open({
                        type: 'error',
                        content: '眼睛被遮挡，请重新拍照'
                    });
                } else if (result.face_list[0].quality.occlusion.nose > 0.7) {
                    messageApi.open({
                        type: 'error',
                        content: '鼻子被遮挡，请重新拍照'
                    });
                } else if (result.face_list[0].quality.occlusion.mouth > 0.7) {
                    messageApi.open({
                        type: 'error',
                        content: '嘴巴被遮挡，请重新拍照'
                    });
                } else if (result.face_list[0].quality.occlusion.chin_contour > 0.6) {
                    messageApi.open({
                        type: 'error',
                        content: '下巴被遮挡，请重新拍照'
                    });
                } else if (
                    result.face_list[0].quality.occlusion.right_cheek > 0.5 ||
                    result.face_list[0].quality.occlusion.left_cheek > 0.5
                ) {
                    messageApi.open({
                        type: 'error',
                        content: '脸颊被遮挡，请重新拍照'
                    });
                } else {
                    setIsHuman(true);
                }
            } else {
                if(res.error_code === 222202) {
                    messageApi.open({
                        type: 'error',
                        content: `请露正脸拍照，以便入馆识别，请重新拍照`
                    });
                } else if(res.error_code === 222304) {
                    messageApi.open({
                        type: 'error',
                        content: `上传图片过大,请重新上传`
                    });
                } else {
                    messageApi.open({
                        type: 'error',
                        content: `上传失败，请重新上传`
                    });
                }
            }
        });
    };
    const choosePic = function(e) {
        const size = e.file.size / 1024 / 1024;
        if (size > 10) {
            messageApi.open({
                type: 'error',
                content: '上传图片过大，请上传小于10M的图片'
            });
            e.onError();
        } else {
            form.validateFields(["photo"]);
            setPhotoShow(false);
            let formData = new FormData();
            formData.append('trackData', e.file);
            uploadAvatar(formData, 'VIP_PHOTO').then((res) => {
                setPicUrl(res?.data?.data?.filePath + res?.data?.data?.fileName);
                e.onSuccess(res, e.file);
            });

            // 图片转base64，人脸识别
            setIsHuman(false);
            let reader = new FileReader();
            if (e.file) {
                // 将文件以Data URL形式读入页面
                reader.readAsDataURL(e.file);
                reader.onload = function(e) {
                    const img = new Image();
                    img.src = reader.result;
                    img.onload = () => {
                        // 图片的宽高
                        const w = img.width;
                        const h = img.height;
                        let finalH = h;
                        let finalW = w;
                        do {
                            finalH = finalH*0.8;
                            finalW = finalW*0.8;
                        } while (finalW > 2000 || finalH > 2000);
                        const canvas = document.createElement("canvas");
                        // canvas对图片进行裁剪，这里设置为图片的原始尺寸
                        canvas.width = finalW;
                        canvas.height = finalH;
                        const ctx = canvas.getContext("2d");
                        // canvas中，png转jpg会变黑底，所以先给canvas铺一张白底
                        ctx.fillStyle = "#fff";
                        // fillRect()方法绘制一个填充了内容的矩形，这个矩形的开始点（左上点）在
                        // (x, y) ，它的宽度和高度分别由width 和 height 确定，填充样式由当前的fillStyle 决定。
                        ctx.fillRect(0, 0, canvas.width, canvas.height);
                        // 绘制图像
                        ctx.drawImage(img, 0, 0, finalW, finalH);
                        // canvas转图片达到图片压缩效果
                        // 返回一个包含图片展示的 data URI base64 在指定图片格式为 image/jpeg 或 image/webp的情况下，
                        // 可以从 0 到 1 的区间内选择图片的质量。如果超出取值范围，将会使用默认值 0.92。其他参数会被忽略。
                        const dataUrl = canvas.toDataURL("image/jpeg", 0.5);
                        queryBaiduTOken().then((res) => {
                            Verify(dataUrl, res?.access_token);
                        });
                    };
                    // queryBaiduTOken().then((res) => {
                    //     Verify(reader.result, res?.access_token);
                    // });
                };
            }
        }
    };

    const chooseEducation = function(e) {
        setEducationId(e);
    };
    const chooseIndustry = function(e) {
        setIndustryId(e);
    };

    const dateFormat = 'YYYY/MM/DD';
    const onChange = (e) => {
        setIsconfirm(e.target.checked);
    };
    const pageView = useRef(null);
    // 滚动到勾选框
    function handleCheckScroll() {
        pageView.current && pageView.current.scrollIntoView({ behavior: 'smooth' });
    }
    // 日期禁用
    const disabledDate = (current) => {
        return current && current > dayjs().endOf('day');
    };
    return (
        <>
            {contextHolder}
            {isrequest && (
                <div className="familyEdit active-vip">
                    <Form
                        onFinish={onFinish}
                        form={form}
                        scrollToFirstError={true}
                        validateTrigger="onBlur"
                        ref={formRef}
                        initialValues={{
                            username: infor?.memberName || cert?.customerName,
                            // birthday: infor?.birthday && dayjs(dealdate(infor?.birthday), dateFormat),
                            birthday:
                                (infor?.birthday && dayjs(dealdate(infor?.birthday), dateFormat)) ||
                                (cert?.showDocumentNumber &&
                                    cert?.documentType === 'RLY0101' &&
                                    dayjs(DocToBirth(cert?.showDocumentNumber), dateFormat)) || '',
                            gender: infor?.gender?.toString(),
                            cardType: infor?.documentType || cert?.documentType,
                            cardId: infor?.documentNumber || cert?.showDocumentNumber,
                            phonenumber: infor?.phone || cert?.phoneNumber,
                            education: infor?.education,
                            email: infor?.email,
                            industry: infor?.industry,
                            photo: infor?.photo,
                            isSchoolFriend:  infor?.isSchoolFriend?.toString()
                        }}
                    >
                        <h3>姓名</h3>
                        <Form.Item
                            name="username"
                            className="edit-item"
                            rules={[
                                { required: true, message: '请输入姓名!' },
                                {
                                    pattern:  form.getFieldValue()?.cardType === 'RLY0101' ? /^[\u4E00-\u9FA5\uf900-\ufa2d·s]{2,20}$/ : /^[a-zA-Z\u4e00-\u9fa5]+$/,
                                    message: '请输入正确的姓名！'
                                }
                            ]}
                        >
                            <Input placeholder="请输入姓名" disabled={infor?.state === 1} />
                        </Form.Item>
                        <h3>出生日期</h3>
                        <Form.Item
                            name="birthday"
                            className="edit-item"
                            rules={[{ required: true, message: '请选择出生日期！' }]}
                        >
                            <DatePicker
                                locale={locale}
                                format={dateFormat}
                                placeholder="请选择出生日期"
                                inputReadOnly
                                disabled={infor?.state === 1 || (cert?.showDocumentNumber && cert?.documentType === "RLY0101")}
                                disabledDate={disabledDate}
                                showToday={false}
                            />
                        </Form.Item>
                        <h3>是否为清华校友</h3>
                        <Form.Item
                            name="isSchoolFriend"
                            className="edit-item"
                            rules={[{ required: true, message: '请选择是否为清华校友！' }]}
                        >
                            <Radio.Group
                                disabled={infor?.state === 1}
                            >
                                <Radio value="0">否</Radio>
                                <Radio value="1">是</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <h3>性别</h3>
                        <Form.Item
                            name="gender"
                            className="edit-item"
                            rules={[{ required: true, message: '请选择您的性别！' }]}
                        >
                            <Radio.Group disabled={infor?.state === 1}>
                                <Radio value="1">男</Radio>
                                <Radio value="0">女</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <h3>学历</h3>
                        <Form.Item
                            name="education"
                            className="edit-item"
                            rules={[{ required: true, message: '请选择您的学历！' }]}
                        >
                            <Select placeholder="请选择您的学历" onChange={chooseEducation}>
                                {education?.map((item) => {
                                    return (
                                        <Option value={item?.id} key={item?.id}>
                                            {item?.text}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        <h3>证件类型</h3>
                        <Form.Item
                            className="edit-item"
                            name="cardType"
                            rules={[{ required: true, message: '请选择其中一项！' }]}
                        >
                            <Select
                                disabled={infor?.state === 1 || cert?.documentType}
                                defaultValue="请选择类型"
                                className="edit-select"
                                onChange={chooseCardType}
                                options={cardDirectionary}
                            />
                        </Form.Item>
                        <h3>证件号码</h3>
                        <Form.Item name="cardId" className="edit-item" rules={cardType}>
                            <Input
                                placeholder={cardType?.[0]?.message?.slice(
                                    0,
                                    cardType?.[0]?.message?.length - 1
                                )}
                                disabled={infor?.state === 1 || cert?.showDocumentNumber}
                            />
                        </Form.Item>
                        <h3 className="norequires">电子邮箱</h3>
                        <Form.Item
                            className="edit-item"
                            name="email"
                            rules={[
                                {
                                    required: false,
                                    pattern: /[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/,
                                    message: '请输入正确的邮箱地址！'
                                }
                            ]}
                        >
                            <Input placeholder="请输入电子邮箱地址" />
                        </Form.Item>
                        <h3>联系电话</h3>
                        <Form.Item
                            name="phonenumber"
                            className="edit-item"
                            rules={[
                                { required: true, message: '请输入手机号!' },
                                {
                                    // pattern: /^1(3[0-9]|4[5,7]|5[0,1,2,3,5,6,7,8,9]|6[2,5,6,7]|7[0,1,3,7,8]|8[0-9]|9[1,8,9])\d{8}$/,
                                    pattern: /^1[3456789]\d{9}$/,
                                    message: '请输入正确的手机号！'
                                }
                            ]}
                        >
                            <Input placeholder="请输入手机号" disabled={infor?.state === 1} />
                        </Form.Item>
                        <h3>所在行业</h3>
                        {/* <Form.Item
                            className="edit-item"
                            name="industry"
                            rules={[{ required: true, message: '请输入您所在的行业!' }]}
                        >
                            <Input placeholder="所在行业" />
                        </Form.Item> */}
                        <Form.Item
                            name="industry"
                            // label="所在行业"
                            className="edit-item"
                            rules={[{ required: true, message: '请选择您所在的行业！' }]}
                        >
                            <Select
                                placeholder="请选择所在的行业"
                                onChange={chooseIndustry}
                            >
                                {industry?.map((item) => {
                                    return (
                                        <Option value={item?.id} key={item?.id}>
                                            {item?.text}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        <h3>照片</h3>
                            {/* {photoShow && infor?.photo && (
                                <Img src={`${window.FILE_IMG_URL}${infor?.photo}`} />
                            )} */}
                        <Form.Item
                            className="edit-item"
                            name="photo"
                            rules={[{ required: true, message: '请上传一张照片' }]}
                        >
                            <div className="photoGroup">
                            <Upload
                                action={`${window.FILE_IMG_URL}/file/normalUploadByCode/VIP_PHOTO/vip/800af9b25ca04edcae85ea8616c6ddac`}
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader uploadBox"
                                showUploadList={true}
                                onPreview={handlePreview}
                                // beforeUpload={choosePic}
                                customRequest={choosePic}
                                maxCount={1}
                                capture="user"
                                accept="image/*"
                                defaultFileList={
                                    infor?.photo && [
                                        {
                                            status: 'done',
                                            url: `${window.FILE_IMG_URL}${infor?.photo}`,
                                        },
                                    ]
                                }
                                onRemove={() => {
                                    form.setFieldValue("photo", '');
                                    setPicUrl();
                                }}
                            >
                                <span className="upload-icon">+</span>
                            </Upload>
                            </div>
                        </Form.Item>

                        <Modal
                            open={previewOpen}
                            title={previewTitle}
                            footer={null}
                            onCancel={handleCancel}
                        >
                            <img
                                alt="example"
                                style={{
                                    width: '100%'
                                }}
                                src={previewImage}
                            />
                        </Modal>
                        <Modal
                            title="清华大学艺术博物馆会员章程"
                            open={tkopen}
                            className="hytkmodel"
                            onOk={() => {
                                setIsconfirm(true);
                                setTkopen(false);
                            }}
                            onCancel={() => {
                                setTkopen(false);
                            }}
                            okText="确认"
                            cancelButtonProps={{ style: { display: 'none' } }}
                        >
                            <div className="cont" dangerouslySetInnerHTML={{ __html: charter }} />
                        </Modal>
                        <div className="confirm-check" id="pageView" ref={pageView}>
                            <Checkbox onChange={onChange} checked={isConfirm} />
                            我已阅读
                            <span
                                className="hytk"
                                onClick={() => {
                                    setTkopen(true);
                                }}
                            >
                                《会员章程》
                            </span>
                            并同意
                        </div>
                        <div className="btn-group">
                            <button className="confirm" htmltype="submit">
                                激活
                            </button>
                        </div>
                    </Form>
                    <div className="tips">
                    <h3>提示：</h3>
                    <p>1、请认真阅读《会员章程》，勾选确认后再申请办理会员卡。</p>
                    <p>
                    2、请您如实填写申请表中的各项内容(带*为必填项)，以便我们向您提供相关的会员服务。提交成功后，仅限部分内容可进行修改。
                    </p>
                    <p>3、本馆将妥善保护您所填写的个人信息。</p>
                </div>
                    <div className="empty"> </div>
                </div>
            )}
        </>
    );
};

export default activefamilyvip;
