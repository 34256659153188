/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { queryTitleList, applyInvoicing } from '../../service';
import { history } from '../../routes';
import app, { $action } from '../../model';
import { localDS } from '../../storage';
import {
    Form,
    Input,
    DatePicker,
    Select,
    Radio,
    Upload,
    Modal,
    message,
    Checkbox
} from 'antd';
import './style.scss';
import { id } from 'date-fns/locale';
import Loading from '../../shared/Loading';

const Invoicing = () => {
    const id = new URLSearchParams(window.location.search)?.get('id');
    const orderNo = new URLSearchParams(window.location.search)?.get('orderNo');
    const random = new URLSearchParams(window.location.search)?.get('random');
    const price = new URLSearchParams(window.location.search)?.get('price');

    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const [invoiceType, setInvoiceType] = useState(2);
    const [titleList, setTitleList] = useState([]);
    const [init, setInit] = useState({
        invoiceType: 2,
    });
    const [loading, setLoading] = useState(false);

    useEffect(( ) => {
        queryTitleList().then(res => {
            if(res?.code === 200) {
                setTitleList(res?.data);
                const qylist = res?.data?.filter(item => (item.isDefault === 1 && item.invoiceType === 2))[0];
                const grlist = res?.data?.filter(item => (item.isDefault === 1 && item.invoiceType === 1))[0];
                const initobj = qylist ? qylist : grlist;
                if(initobj) {
                    let obj = {
                        invoiceType: initobj?.invoiceType,
                        invoiceTitle: initobj?.invoiceTitle,
                        taxpayerIdentifier: initobj?.unitTaxNo,
                        openingBank: initobj?.openingBank,
                        bankAccount: initobj?.bankAccount,
                        unitAddress: initobj?.unitAddr,
                        mobile: initobj?.mobile,
                    }
                    setInvoiceType(initobj?.invoiceType)
                    form.setFieldsValue(obj)
                }
            }
        })
    }, [])

    const onFinish = () => {
        let formValues = form.getFieldValue();
        let data
        if(formValues?.invoiceType === 1) {
            data = {
                "id": id,
                "invoiceType": formValues?.invoiceType,
                "invoiceTitle": formValues?.invoiceTitle,
                "email": formValues?.email,
            }
        } else {
            data = {
                "id": id,
                "invoiceType": formValues?.invoiceType,
                "invoiceTitle": formValues?.invoiceTitle,
                "email": formValues?.email,
                "taxpayerIdentifier": formValues?.taxpayerIdentifier,
                "openingBank": formValues?.openingBank,
                "bankAccount": formValues?.bankAccount,
                "unitAddress": formValues?.unitAddress,
                "mobile": formValues?.mobile,
            }
        }
        if(data) {
            setLoading(true);
            applyInvoicing(data).then(res => {
                setLoading(false);
                if(res?.code === 200) {
                    app.dispatch(
                        $action('alert').emit({
                            show: true,
                            title: '提示',
                            content: '开票成功',
                            callback: () => {
                                const no = encodeURIComponent(`${orderNo}#${random}`);

                                // h5详情页
                                // history.push({
                                //     pathname: `/invoicedetail`,
                                //     search: `?id=${no}`
                                // });

                                // 跳转小程序详情页
                                wx.miniProgram.navigateTo({
                                    url: `../main/invoiceDetail?isWebviewBack=true&id=${no}`
                                });
                            }
                        })
                    );
                } else {
                    app.dispatch(
                        $action('alert').emit({
                            show: true,
                            title: '提示',
                            content: res?.msg
                        })
                    );
                }
            })
        }
    }

    return <div className="invoicing-page">
        {loading && <Loading isMobile={true}/>}
    <Form
        className="invoicing-form"
        onFinish={onFinish}
        form={form}
        scrollToFirstError={true}
        validateTrigger="onBlur"
        initialValues={init}
    >
        <div className="recept">
            <p><span />开票详情</p>
        </div>
        <Form.Item
            name="invoiceType"
            label="抬头类型"
            className="iform-item"
        >
            <Radio.Group
                onChange={(e) => {
                    setInvoiceType(e.target.value);
                    const initobj = titleList?.filter(item => (item.isDefault === 1 && item.invoiceType === e.target.value))[0];
                    if(initobj) {
                        let obj = {
                            invoiceType: initobj?.invoiceType,
                            invoiceTitle: initobj?.invoiceTitle,
                            taxpayerIdentifier: initobj?.unitTaxNo,
                            openingBank: initobj?.openingBank,
                            bankAccount: initobj?.bankAccount,
                            unitAddress: initobj?.unitAddr,
                            mobile: initobj?.mobile,
                        }
                        form.setFieldsValue(obj)
                    } else {
                        form.setFieldsValue({
                            invoiceTitle: '',
                            taxpayerIdentifier: '',
                            openingBank: '',
                            bankAccount: '',
                            unitAddress: '',
                            mobile: '',
                        })
                    }
                }}
            >
                <Radio value={2}>企业</Radio>
                <Radio value={1}>个人</Radio>
            </Radio.Group>
        </Form.Item>
        <div className='abs'>
        <Form.Item
            name="invoiceTitle"
            label="发票抬头"
            className="iform-item"
            rules={[{ required: true, message: '请选择发票抬头！' }]}
        >
            <Input
                placeholder={invoiceType === 2 ? '填写需要开具发票的企业名称' : '填写需要开具发票的姓名'}
                // onClick={() => {
                //     chooseTitle();
                // }}
            />
            </Form.Item>
            <i
                className="iconfont icon-caidan"
                onClick={() => {setOpen(true);}}
            />
        </div>
        {invoiceType === 2 && <Form.Item
            name="taxpayerIdentifier"
            label="税号"
            className="iform-item"
            rules={[{
                required: true,
                pattern: /^[A-Z0-9]{15}$|^[A-Z0-9]{17}$|^[A-Z0-9]{18}$|^[A-Z0-9]{20}$/,
                message: '税号应为15/17/18/20位数字或大写字母组合，请修改'
            }]}
        >
            <Input
                placeholder="纳税人识别号"
            />
        </Form.Item>}
        {invoiceType === 2 && <Form.Item
            name="openingBank"
            label="开户银行"
            className="iform-item"
        >
            <Input
                placeholder="选填"
            />
        </Form.Item>}
        {invoiceType === 2 && <Form.Item
            name="bankAccount"
            label="银行账号"
            className="iform-item"
            rules={[{
                required: false,
                pattern: /^[0-9]{9,25}$/,
                message: '请输入正确的银行账号'
            }]}
        >
            <Input
                placeholder="选填"
            />
        </Form.Item>}
        {invoiceType === 2 && <Form.Item
            name="unitAddress"
            label="企业地址"
            className="iform-item"
        >
            <Input
                placeholder="选填"
            />
        </Form.Item>}
        {invoiceType === 2 && <Form.Item
            name="mobile"
            label="企业电话"
            className="iform-item"
            rules={[{
                required: false,
                pattern:/^((0\d{2,3})-)?(\d{7,8})$|^1[3456789]\d{9}$/,
                message: '请输入正确的企业电话'
            }]}
        >
            <Input
                placeholder="选填"
            />
        </Form.Item>}
        <div className="invoice-price">
            <p className="title">发票金额</p>
            <p className="price">
                <span>{price}</span>元
            </p>
        </div>
        <div className="br" />
        <div className="recept pt">
            <p><span />接收方式</p>
        </div>
        <Form.Item
            name="email"
            label="邮箱地址"
            className="iform-item"
            rules={[
                {
                    required: true,
                    pattern: /[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/,
                    message: '请输入正确的邮箱地址'
                }
            ]}
        >
            <Input
                placeholder="必填"
            />
        </Form.Item>
        <div className="form-submit">
            <button className="confirm" htmltype="submit" >
                提交申请
            </button>
        </div>
    </Form>
   {open && <div className="dialog">
        <div
            className="btn-close"
            onClick={() => {setOpen(false)}}
        />
        <div className="box">
            <div className="close" onClick={() => {setOpen(false)}}/>
            <div className="title"><span>抬头选择</span></div>
            {titleList?.length > 0 && <ul>
                {titleList.map(item => {
                    return (
                    <li
                        key={item.id}
                        onClick={() => {
                            let obj = {
                                invoiceType: item?.invoiceType,
                                invoiceTitle: item?.invoiceTitle,
                                taxpayerIdentifier: item?.unitTaxNo,
                                openingBank: item?.openingBank,
                                bankAccount: item?.bankAccount,
                                unitAddress: item?.unitAddr,
                                mobile: item?.mobile,
                            }
                            setInvoiceType(item?.invoiceType)
                            form.setFieldsValue(obj)
                            setOpen(false);
                        }}
                    >
                        <div className="l">
                            <p className="name">{item.invoiceTitle} {item.isDefault === 1 && <span>默认</span>}</p>
                            <p className="type">{item.invoiceType === 2 ? '企业抬头' : '个人抬头'}</p>
                        </div>
                        <div
                            className="r"
                            onClick={(e) => {
                                e.stopPropagation();
                                history.push({
                                    pathname: `/addinvoicing`,
                                    search: `?id=${item.id}`
                                });
                            }}
                        />
                    </li>)
                })}
            </ul>}
            <div className="button">
                <button
                    onClick={() => {
                        history.push('/addinvoicing')
                    }}
                >
                    添加新的抬头
                </button>
            </div>
        </div>
    </div>}
    </div>;
};

export default Invoicing;
