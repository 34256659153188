export default class DataStorage {
  constructor(engine) {
    const isDOMEngine = engine instanceof Storage;
    if (isDOMEngine) {
      this.engine = engine;
    } else {
      throw Error('engine 🛰🛰🛰');
    }
  }

  set(key, value) {
    const _type = Object.prototype.toString.call(value);
    const valueType = [
      'Array',
      'Object',
      'Date',
      'Boolean',
      'Number',
      'String',
      'Null',
      'Undefined',
    ];
    const support = valueType.some(i => _type.includes(i));
    if (support) {
      // 相对的
      const isPrimitive = typeof value !== 'object' || value === null; // boolean string null undefined number 直接存储

      this.engine.setItem(
        key,
        JSON.stringify({
          value: value,
          isPrimitive,
          expires: 0,
        }),
      );
      return this;
    } else {
      throw Error(`value只支持 ${valueType.join(',')}类型`);
    }
  }
  get(key) {
    const result = this.engine.getItem(key);
    return result === null ? result : JSON.parse(result).value;
  }
  has(key) {
    return this.engine.getItem(key) !== null;
  }
  remove(key) {
    this.engine.removeItem(key);
    return this;
  }
  append(key, value) {
    const result = this.engine.getItem(key);
    const parsed = JSON.parse(result);
    if (parsed.isPrimitive) {
      this.set(key, value);
    } else {
      // TODO 完成
      if (Array.isArray(value)) {
        this.set(key, Array.from(new Set([...value, ...parsed.value])));
      }
    }
  }
}
