function noop() {}
export default function() {
  return {
    namespace: 'confirm',
    state: {
      open: false,
      content: '',
      okText: '确定',
      cancelText: '取消',
      showClose: false,
      title: '提示',
      okCallback: noop,
      cancelCallback: noop,
    },
    reducer: {
      emit(state, { payload }) {
        // 如果没有传递回调，需要重置 否则会导致之前的回调被调用
        return {
          ...state,
          ...{ okCallback: noop, cancelCallback: noop },
          ...payload,
        };
      },
      ok(state) {
        return state;
      },
      cancel(state) {
        return state;
      },
    },
  };
}
