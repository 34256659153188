import React, { useEffect, useState } from 'react';
import { addTitle, queryTitleById, updateTitle } from '../../service';
// import { history } from '../../routes';
import app, { $action } from '../../model';
import { localDS } from '../../storage';
import {
    Form,
    Input,
    DatePicker,
    Select,
    Radio,
    Upload,
    Modal,
    message,
    Checkbox,
    Switch
} from 'antd';
import './style.scss';

const AddInvoicing = () => {
    const id = new URLSearchParams(window.location.search)?.get('id');

    const [form] = Form.useForm();
    const [invoiceType, setInvoiceType] = useState(2);
    const [isDefault, setIsDefault] = useState(false);

    useEffect(() => {
        if(id) {
            queryTitleById(id).then(res => {
                const data = res?.data
                if(res?.code === 200 && data) {
                    const obj = {
                        invoiceType: data?.invoiceType,
                        invoiceTitle: data?.invoiceTitle,
                        unitTaxNo: data?.unitTaxNo,
                        openingBank: data?.openingBank,
                        bankAccount: data?.bankAccount,
                        unitAddr: data?.unitAddr,
                        mobile: data?.mobile,
                        isDefault: data?.isDefault,
                    }
                    setInvoiceType(data?.invoiceType)
                    setIsDefault(data?.isDefault === 1)
                    form.setFieldsValue(obj)
                }
            })
        }
    }, [])

    const onFinish = () => {
        let formValues = form.getFieldValue();
        let data;
        if(formValues.invoiceType === 1) {
            data = {
                invoiceType:  formValues.invoiceType,
                invoiceTitle: formValues.invoiceTitle,
                isDefault: formValues.isDefault ? 1 : 0,
            }
        } else {
            data = {
                invoiceType:  formValues.invoiceType,
                invoiceTitle: formValues.invoiceTitle,
                unitTaxNo: formValues.unitTaxNo,
                openingBank: formValues.openingBank,
                bankAccount: formValues.bankAccount,
                unitAddr: formValues.unitAddr,
                mobile: formValues.mobile,
                isDefault: formValues.isDefault ? 1 : 0,
            }
        }
        if(id) {
            data.id = id;
            updateTitle(data).then(res => {
                if(res?.code === 200) {
                    history.back()
                } else {
                    app.dispatch(
                        $action('alert').emit({
                            show: true,
                            title: '提示',
                            content: res?.msg
                        })
                    );
                }
            })
        } else {
            addTitle(data).then(res => {
                if(res?.code === 200) {
                    history.back()
                } else {
                    app.dispatch(
                        $action('alert').emit({
                            show: true,
                            title: '提示',
                            content: res?.msg
                        })
                    );
                }
            })
        }
    }

    const chooseTitle = () => {

    }
    const onChange = (val) => {
        setIsDefault(val);
      };

    return <div className="addinvoicing-page">
    <Form
        className="invoicing-form"
        onFinish={onFinish}
        form={form}
        scrollToFirstError={true}
        validateTrigger="onBlur"
        initialValues={{
            invoiceType: 2,
        }}
    >
        <Form.Item
            name="invoiceType"
            label="抬头类型"
            className="iform-item pt"
        >
            <Radio.Group
                onChange={(e) => {
                    setInvoiceType(e.target.value);
                }}
            >
                <Radio value={2}>企业</Radio>
                <Radio value={1}>个人</Radio>
            </Radio.Group>
        </Form.Item>
        <div className="br" />
        <Form.Item
            name="invoiceTitle"
            label="发票抬头"
            className="iform-item more"
            rules={[{ required: true, message: '请填写发票抬头！' }]}
        >
            <Input
                placeholder={invoiceType === 2 ? '填写需要开具发票的企业名称' : '填写需要开具发票的姓名'}
            />
        </Form.Item>
        {invoiceType === 2 && <Form.Item
            name="unitTaxNo"
            label="税号"
            className="iform-item"
            rules={[{
                required: true,
                pattern: /^[A-Z0-9]{15}$|^[A-Z0-9]{17}$|^[A-Z0-9]{18}$|^[A-Z0-9]{20}$/,
                message: '税号应为15/17/18/20位数字或大写字母组合，请修改'
            }]}
        >
            <Input
                placeholder="纳税人识别号"
            />
        </Form.Item>}
        {invoiceType === 2 && <Form.Item
            name="openingBank"
            label="开户银行"
            className="iform-item"
        >
            <Input
                placeholder="选填"
            />
        </Form.Item>}
        {invoiceType === 2 && <Form.Item
            name="bankAccount"
            label="银行账号"
            className="iform-item"
            rules={[{
                required: false,
                pattern: /^[0-9]{9,25}$/,
                message: '请输入正确的银行账号'
            }]}
        >
            <Input
                placeholder="选填"
            />
        </Form.Item>}
        {invoiceType === 2 && <Form.Item
            name="unitAddr"
            label="企业地址"
            className="iform-item"
        >
            <Input
                placeholder="选填"
            />
        </Form.Item>}
        {invoiceType === 2 && <Form.Item
            name="mobile"
            label="企业电话"
            className="iform-item"
            rules={[{
                required: false,
                pattern:/^((0\d{2,3})-)?(\d{7,8})$|^1[3456789]\d{9}$/,
                message: '请输入正确的企业电话'
            }]}
        >
            <Input
                placeholder="选填"
            />
        </Form.Item>}
        <div className="br" />

        <Form.Item
            name="isDefault"
            label="设为默认"
            className="iform-item pb"
        >
            <Switch checked={isDefault} onChange={onChange} />
        </Form.Item>

        <div className="form-submit">
            <button className="confirm" htmltype="submit" >
                保存
            </button>
        </div>
    </Form>
    </div>;
};

export default AddInvoicing;
