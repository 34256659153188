import { createBrowserHistory } from 'history';
import Entry from '../components/Entry';
import Charter from '../components/Charter';
import Dynamic from '../components/Dynamic';
import DynamicDetail from '../components/Dynamic/detail';
import Vipclassify from '../components/Vipclassify';
import VipChoose from '../components/VipChoose';
import Cardsinfor from '../components/Cardsinfor';
import Purchasecard from '../components/Purchasecard';
import Apply from '../components/Apply';
import MemberEdit from '../components/MemberEdit';
import Gifts from '../components/Gifts';
import GiftCards from '../components/GiftCards';
import ApplyRecords from '../components/ApplyRecords';
import Personal from '../components/Personal';
import Personal2 from '../components/Personal2';
import FamilyMessage from '../components/FamilyMessage';
import FamilyMemberUse from '../components/FamilyMessage/familymemberUse';
import AddFamily from '../components/AddFamily';
import MemberEquity from '../components/MemberEquity';
import MyInfo from '../components/MyInfo';
import TakeMember from '../components/TakeMember';
import AddMembers from '../components/AddMembers';
import AddShare from '../components/AddShare';
import ActiveVip from '../components/ActivemVip';
import ActiveFamilyVip from '../components/ActiveFamilyVip';
import ActivevipAddmember from '../components/ActiveFamilyVip/activevipAddmember.jsx'
import Track from '../components/Track';
import Invoicing from '../components/Invoicing';
import AddInvoicing from '../components/addInvoicing';
import InvoiceDetail from '../components/InvoiceDetail'

export const history = createBrowserHistory();
window.$history = history;

export const mainRoutes = [
    {
        path: '/',
        component: Entry,
        name: '首页',
        exact: true
    },
    {
        path: '/charter',
        component: Charter,
        name: '会员章程',
        exact: true
    },
    {
        path: '/dynamic',
        component: Dynamic,
        name: '会员动态',
        exact: true
    },{
        path: '/dynamic/:id',
        component: DynamicDetail,
        name: '会员动态',
        exact: true
    },
    {
        path: '/vipclassify',
        component: Vipclassify,
        name: '会员申请',
        exact: true
    },
    {
        path: '/vipchoose',
        component: VipChoose,
        name: '会员申请',
        exact: true
    },
    {
        path: '/cardsinfor',
        component: Cardsinfor,
        name: '送你会员礼遇',
        exact: true
    },
    {
        path: '/purchasecard',
        component: Purchasecard,
        name: '购买分享卡',
        exact: true
    },
    {
        path: '/apply',
        component: Apply,
        name: '会员申请',
        exact: true
    },
    {
        path: '/memberedit',
        component: MemberEdit,
        name: '会员申请',
        exact: true
    },
    {
        path: '/gifts',
        component: Gifts,
        name: '我的礼物',
        exact: true
    },
    {
        path: '/giftcard',
        component: GiftCards,
        name: '分享卡',
        exact: true
    },
    {
        path: '/applyrecords',
        component: ApplyRecords,
        name: '申请记录',
        exact: true
    },
    {
        path: '/personal',
        component: Personal,
        name: '会员中心',
        exact: true
    },
    {
        path: '/personal2',
        component: Personal2,
        name: '跳转页面',
        exact: true
    },
    {
        path: '/familymsg',
        component: FamilyMessage,
        name: '家人信息',
        exact: true
    },
    {
        path: '/familymemberUse',
        component: FamilyMemberUse,
        name: '成员会员电子码',
        exact: true
    },
    {
        path: '/addfamily',
        component: AddFamily,
        name: '新增成员信息',
        exact: true
    },
    {
        path: '/memberEquity',
        component: MemberEquity,
        name: '会员',
        exact: true
    },
    {
        path: '/myinfo',
        component: MyInfo,
        name: '会员信息',
        exact: true
    },
    {
        path: '/takemember',
        component: TakeMember,
        name: '携带成员信息',
        exact: true
    },
    {
        path: '/addMembers',
        component: AddMembers,
        name: '添加成员',
        exact: true
    },
    {
        path: '/addshare',
        component: AddShare,
        name: '成员信息',
        exact: true
    },
    {
        path: '/activevip',
        component: ActiveVip,
        name: '激活会员卡',
        exact: true
    },
    {
        path: '/activefamilyvip',
        component: ActiveFamilyVip,
        name: '激活会员卡',
        exact: true
    },
    {
        path: '/activevipAddmember',
        component: ActivevipAddmember,
        name: '添加家庭成员',
        exact: true
    },
    {
        path: '/track',
        component: Track,
        name: '我的足迹',
        exact: true
    },
    {
        path: '/invoicing',
        component: Invoicing,
        name: '申请开票',
        exact: true
    },
    {
        path: '/addinvoicing',
        component: AddInvoicing,
        name: '新增抬头',
        exact: true
    },
    {
        path: '/invoicedetail',
        component: InvoiceDetail,
        name: '发票详情',
        exact: true
    },
];
