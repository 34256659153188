import DataStorage from './DataStorage';
const sessionDS = new DataStorage(sessionStorage);
const localDS = new DataStorage(localStorage);
// 刘皇叔, 家庭会员
// localDS.set('memberId', '4bcdad894163e24a7b0360fc978e6b94');
// 刘备，尊享会员
// localDS.set('memberId', '5207969a29674aad505e383b533e8f21');
// 马骝，个人会员
// localDS.set('memberId', '1a07ad2f610458ccc78144c620d55ad0');
// 亚索，学生会员
// localDS.set('memberId', '9902f906358f3a31295a472135535e0c');
export { sessionDS };
export { localDS };
