import React, { useState, useEffect } from 'react';
import { queryMyFootPrint } from '../../service';
import { localDS } from '../../storage';
import {hideId} from '../../utils/validator'
import imgsrc from "@/assets/zwsj.png";
import { Pager } from '@portal-site/pager';
import { useLocation } from 'react-router';
import { history } from '../../routes';
import './style.scss';

const Gifts = () => {
    const memberId = localDS.get('memberId');
    const [tracks, setTracks] = useState([]);
    const [loading, setLoading] = useState(true);

    const location = useLocation();
    const url = new URLSearchParams(location.search);
    const [pager, setPager] = useState({ pageCount: 0, currentPage: url.get('page') ? Number(url.get('page')) : 1 });
    useEffect(() => {
        queryMyFootPrint({ pageNum: pager.currentPage, pageSize: 10, memberId }).then((res) => {
            setLoading(false);
            const { records, current, pages } = res?.data;
            if(records) {
                setTracks(res?.data?.records);
                setPager({pageCount: pages, currentPage: current});
            }
        })
    }, [pager.currentPage]);
    const arr = [
        '',
        '参观票',
        '活动票',
    ]
    return (
        !loading ? <div className='giftBox track-box allcenter'>
            {
                (tracks?.length > 0) ? <div className="trackul">{tracks?.map((item) => {
                    return (
                        <div className="track-item" key={item.id}>
                            {item.documentNumber && <p className="name">{item.memberName}<span>{`（${hideId(item.documentNumber)}）`}</span></p>}
                            {item.createTime && <p className="normal">入馆时间：{item.createTime}</p>}
                            {item.entryType && <p className="normal">票务类型：{arr[item.entryType]}</p>}
                        </div>
                    )
                })}</div>  : <div className="zwsj"><img src={imgsrc} alt="" /></div>
            }
            {(tracks?.length > 0) && <Pager
                onPageChange={(page) => {
                    url.set('page', page);
                    history.push({ pathname: location.pathname, search: '?' + url.toString() });
                    setPager((pager) => ({ ...pager, currentPage: page }));
                }}
                forcePage={pager.currentPage}
                containerClassName="mypager"
                pageCount={pager.pageCount}
                pageRange={3}
            />}
        </div> : <div className="loading">loading...</div>
    )
}

export default Gifts;
