import React, { Component } from 'react';
import { queryMemberAct } from '../../service';
import './style.scss';

class DynamicDetail extends Component {
    state = {
        data: {},
    };
    componentDidMount() {
        const id = this.props.match.params.id;

        queryMemberAct(id).then(res => {
            this.setState({data: res?.data})
        })
    }
    render() {
        const { content, actName, pubdate, creator } = this.state.data;
        return (
            <div className="dynamic-detail">
                <h2>{actName}</h2>
                <p className="time">{pubdate}</p>
                {/* <p className="tip">
                    作者：{creator}
                </p> */}
                {/* <p className="tip">
                    摄影：{}
                </p>
                <p className="tip">
                    来源：{}
                </p> */}
                <div className='cont' dangerouslySetInnerHTML={{ __html: content }} />
            </div>
        );
    }
}

export default DynamicDetail;
