import React, { useState, useEffect } from 'react';
import { history } from '../../routes';
import { queryFamilyInfo, memberBasicInfo } from '../../service';
import { localDS } from '../../storage';
import FamilyMsg from './familymsg';
import './style.scss';

const FamilyMessage = () => {
    const hasId = new URLSearchParams(window.location.search).get('memberId')
    let memberId = localDS.get('memberId') || hasId;
    let [familyList, setFamilyList] = useState([]);
    const delMember = function(id){
        let res = setFamilyList(familyList?.filter((item) => item.id !== id));
        return res;
    }
    useEffect(() => {
        let documentNumber = localDS.get('documentNumber');
        memberBasicInfo(documentNumber).then((res) => {
            const data = res?.data;
            let id = '';
            if(data) {
                if(data?.isVip === 1 && data?.typeId === 3) {
                    id = data?.id
                } else {
                    id= hasId || localDS.get('memberId')
                }
            } else {
                id = memberId;
            }
            queryFamilyInfo({memberId: id, typeId: '3'}).then((res) => {
                setFamilyList(res?.data?.records)
            })
        });
    }, []);
    return (
        <div className="familybox allcenter item-shadow">
            <h3>{familyList?.length > 0 ? '家人信息' : '暂无成员'}</h3>
            <ul className='family-list'>
                {
                    familyList?.map((item) => {
                        return (
                            <FamilyMsg data={item} key={item?.id} del={delMember} hasId={true} hasSearch={hasId}/>
                        )
                    })
                }
            </ul>
            {/* <button className="add" onClick={ () => {
                history.push('/addfamily');
            } }>+ 添加联系人</button> */}
        </div>
    )
}

export default FamilyMessage;
