import React, { useEffect, useState }  from 'react';
import { queryInvoiceInfo, downloadInvoice } from '../../service';
import imgsrc from "@/assets/zwsj.png";
import app, { $action } from '../../model';
import './style.scss';

const Text = (props) => {
    return <div className="item">
        <span className="l">{props?.name}</span>
        <span className="r">{props?.val}</span>
    </div>
}

const InvoiceDetail = (props) => {
    const id = new URLSearchParams(window.location.search)?.get('id');
    const [data, setData] = useState();
    const [url, setUrl] = useState();
    useEffect(() => {
        queryInvoiceInfo(encodeURIComponent(id)).then(res => {
            if(res?.data && res?.code === 200) {
                setData(res?.data)
                res?.data?.invoiceOrderId && res?.data?.invoiceState === 1 && downloadInvoice(res?.data?.invoiceOrderId).then(rs => {
                    console.log(rs)
                    if(rs?.code === 200 && rs?.data?.invoiceUrl) {
                        setUrl(rs?.data?.invoiceUrl)
                    }
                })
            } else {
                app.dispatch(
                    $action('alert').emit({
                        show: true,
                        title: '提示',
                        content: res?.msg || '暂无信息'
                    })
                );
            }
        })
    }, [])
    const state = [
        '申请中',
        '已开票',
        '已拒绝'
    ]
    return (
        <div className="invoicedetail">
            {data?
                <div className="box">
                    <div className="recept">
                        <p><span />开票详情</p>
                    </div>
                    <div className="item">
                        <span className="l">开票状态</span>
                        <span className="r light">{state[data?.invoiceState]}</span>
                    </div>
                    <Text
                        name='抬头类型'
                        val={data?.invoiceType === 1 ? '个人' : '企业'}
                    />
                    <Text
                        name='发票抬头'
                        val={data?.invoiceTitle}
                    />
                    {data?.invoiceType === 2 && <Text
                        name='税号'
                        val={data?.unitTaxNo}
                    />}
                    {data?.invoiceType === 2 && <Text
                        name='开户银行'
                        val={data?.openingBank}
                    />}
                    {data?.invoiceType === 2 && <Text
                        name='银行账号'
                        val={data?.bankAccount}
                    />}
                    {data?.invoiceType === 2 && <Text
                        name='企业地址'
                        val={data?.unitAddr}
                    />}
                    {data?.invoiceType === 2 && <Text
                        name='企业电话'
                        val={data?.mobile}
                    />}
                    <div className="invoice-price">
                        <p className="title">发票金额</p>
                        <p className="price">
                            <span>{data?.tradeAmount}</span>元
                        </p>
                    </div>
                    <div className="br" />
                    <div className="recept pt">
                        <p><span />接收方式</p>
                    </div>
                    <Text
                        name='邮箱地址'
                        val={data?.email}
                    />
                </div>
            : null}
        </div>
    )
}

export default InvoiceDetail;
