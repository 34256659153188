import React, { Component } from 'react';
import cls from 'classnames';
import './index.scss';
export default class Loading extends Component {
    static defaultProps = {
        isMobile: false
    };
    render() {
        return (
            <div
                className={cls('lds-ellipsis-box', { mobile: this.props.isMobile })}
                style={{
                    height: this.props.minHeight,
                    lineHeight: this.props.minHeight + 'px'
                }}
            >
                <div className="lds-ellipsis">
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
            </div>
        );
    }
}
