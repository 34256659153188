import React, { useEffect, useState, useCallback, useRef } from 'react';
import { queryMemberTypeById, querySmsVCode, receiveGiftCards, queryGiftCard } from '../../service';
import { Field, Formik } from 'formik';
import { Button }from '../../shared/Button';
import cls from 'classnames';
import memberIcon from "@/assets/member-icon.png";
import imgsrc1 from "@/assets/vip1.png";
import imgsrc2 from "@/assets/vip2.png";
import imgsrc3 from "@/assets/vip3.png";
import imgsrc4 from "@/assets/vip4.png";
import { message } from 'antd';
import { history } from '../../routes';
import app, { $action } from '../../model';
// import { localDS } from '../../storage';
import './style.scss';

// 中国大陆手机号匹配
// const phoneNumRegex = /^(?:\+?86)?1(?:3\d{3}|5[^4\D]\d{2}|8\d{3}|7(?:[01356789]\d{2}|4(?:0\d|1[0-2]|9\d))|9[189]\d{2}|6[567]\d{2}|4[579]\d{2})\d{6}$/;
const phoneNumRegex = /^1[3456789]\d{9}$/;
const isPhoneNum = (phoneNum) => phoneNumRegex.test(phoneNum);

const Vipclassify = (props) => {
    const param = new URLSearchParams(props.location.search);
    const id = param.get('id');
    const cardId = param.get('cardId');
    const imgarr = [imgsrc1,imgsrc2,imgsrc4,imgsrc3];
    const [messageApi, contextHolder] = message.useMessage();
    const [hasClick, sethasClick] = useState(false);
    const [isSending, setisSending] = useState(false);
    const [time, setTime] = useState(60);
    const [chooseType, setChooseType] = useState();
    const [handledByPhone, setHandledByPhone] = useState();
    // const cert = localDS.get('cert');
    let timer;
    const timeRef = useRef(time);
    useEffect(() => {
        queryMemberTypeById({id}).then(res => {
            const introduce = res?.data;
            setChooseType(introduce);
        })
        queryGiftCard(cardId).then(res => {
            res?.data?.handledByPhone && setHandledByPhone(res.data.handledByPhone)
        })
    }, [])
    const vCodeClock = () => {
        timer = setInterval(() => {
            timeRef.current -= 1;
            if(timeRef.current >0) {
                setTime(timeRef.current)
            } else {
                window.clearInterval(timer);
                setisSending(false);
            }
          }, 1000);

    }
    const sendVcode = useCallback ((phone) => {
        if (isSending) return;
        querySmsVCode(phone).then(res => {
            if(res?.code !== 200){
                messageApi.open({
                    type: 'error',
                    content: res?.msg,
                });
                return;
            }else{
                messageApi.info(res?.data);
                setisSending(true);
                timeRef.current = 60
                setTime(timeRef.current)
                vCodeClock();
                sethasClick(true)
            }
        })
    }, [])

    return (
        <div className="vip-choose common-bg givecardinfor">
            {contextHolder}
            <div className="title-img">
                <img src={imgarr[id - 1]} alt="" />
            </div>
            <div className="vip-choose-cont">
                    {/* <p className="cost">
                        <img src={memberIcon} alt="" />
                        <span>会员权益</span>
                    </p>
                <div
                    className="intro"
                    dangerouslySetInnerHTML={{
                        __html: chooseType?.introduce
                    }}
                /> */}
                <div className="text">
                    <h2>分享卡领取步骤</h2>
                    <p>1、请于下方输入手机号、短信验证码，点击【领取】；</p>
                    <p>2、使用领取的手机号授权登录“清华艺博”小程序，进入“我的→会员中心→分享卡”，在需领取的分享卡处点击【激活】；</p>
                    <p>3、进入激活页面后，请完善会员信息，并仔细阅读《会员章程》，点击【激活】，即成为会员；</p>
                    <p>4、激活成功后，进入“会员中心”可查看相关信息，在享受会员权益时，请出示右上角会员电子码。</p>
                    <h2 className="ts">提示</h2>
                    <p>分享卡于购买后一年内激活有效（过期失效），请您尽快激活使用。</p>
                </div>
                <Formik
                    initialValues={{
                        phoneNumber: '',
                        vcode: ''
                    }}
                    validate={(values) => {
                        let errors = {};
                        if (!isPhoneNum(values.phoneNumber)) {
                            errors.phoneNumber = '请输入正确的手机号';
                        }
                        if(values.phoneNumber === handledByPhone) {
                            errors.phoneNumber = '请勿自己领取!';
                        }
                        if (!values.vcode) {
                            errors.vcode = '请输入验证码';
                        }
                        return errors;
                    }}
                    onSubmit={async (values, {resetForm}) => {
                        if (!hasClick) {
                            messageApi.open({
                                type: 'error',
                                content: '请先发送验证码！',
                            });
                        } else if (values.phoneNumber && values.vcode){
                            receiveGiftCards({
                                receivePhone: values.phoneNumber,
                                cardId,
                                smsVCode: values.vcode
                            }).then(res => {
                                if(res?.code !== 200){
                                    // messageApi.open({
                                    //     type: 'error',
                                    //     content: res?.msg,
                                    // });
                                    app.dispatch(
                                        $action('alert').emit({
                                            show: true,
                                            title: '提示',
                                            content: res?.msg || `领取失败${res?.code}`
                                        })
                                    );
                                    return;
                                }else{
                                    // messageApi.info('领取成功');
                                    app.dispatch(
                                        $action('alert').emit({
                                            show: true,
                                            title: '提示',
                                            content: '领取成功'
                                        })
                                    );
                                    resetForm({
                                        phoneNumber: '',
                                        vcode: ''
                                    })
                                    // setTimeout(() => {
                                    //     history.push('/personal');
                                    // }, 1000);
                                }
                            })
                        }
                    }}
                >
                    {(props) => {
                        const {
                            errors,
                            isSubmitting,
                            touched
                        } = props;
                        return (
                            <form className="form-container" onSubmit={props.handleSubmit}>
                                <div className="login-box">
                                    <div className="field">
                                        <div className="control has-icon">
                                            <Field
                                                name="phoneNumber"
                                                className="input"
                                                placeholder='请输入手机号'
                                            />
                                        </div>
                                        <div
                                            style={{
                                                visibility:
                                                    errors.phoneNumber && touched.phoneNumber
                                                        ? 'visible'
                                                        : 'hidden'
                                            }}
                                            className="help"
                                        >
                                            {errors.phoneNumber}
                                        </div>
                                    </div>
                                    <div className="field">
                                        <div
                                            className="control has-icon"
                                        >
                                            <Field
                                                name="vcode"
                                                className="input"
                                                placeholder='请输入验证码'
                                            />
                                            <div
                                                onClick={() => {
                                                    sendVcode(props.values.phoneNumber);
                                                }}
                                                className={cls('vcode-btn', {
                                                    disable:
                                                        errors.phoneNumber ||
                                                        isSending ||
                                                        !props.values.phoneNumber ||
                                                        isSubmitting
                                                })}
                                            >
                                                {isSending
                                                    ? `${time}秒后重新发送`
                                                    : '获取验证码'}
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                visibility:
                                                    errors.vcode && touched.vcode
                                                        ? 'visible'
                                                        : 'hidden'
                                            }}
                                            className="help"
                                        >
                                            {errors.vcode}
                                        </div>
                                    </div>
                                </div>
                                <Button
                                    enter
                                    loading={props.isSubmitting}
                                    disable={
                                        errors.phoneNumber ||
                                        errors.vcode ||
                                        !props.values.phoneNumber ||
                                        !props.values.vcode
                                    }
                                    onClick={(e) => {
                                        props.handleSubmit();
                                    }}
                                >
                                    马上领取
                                </Button>
                                {/* <div className="wx-tips">
                                提示：会员卡与输入手机号绑定，领取后请到清华艺博小程序并用输入的手机号注册的微信进行授权登录，登录成功后到会员中心我的分享卡去激活，才能享有会员相关权益。
                                </div> */}
                            </form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
}

export default Vipclassify;
