/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { queryMemberTypeById, queryIsQingHuaPersonByCustomerId, queryMemberInfoById, checkHasUnpaidOrder } from '../../service';
import { Button } from '../../shared/Button';
import { history } from '../../routes';
import memberIcon from '@/assets/member-icon.png';
import { localDS } from '../../storage';
import { message, Modal } from 'antd';
import app, { $action } from '../../model';

import './style.scss';

const Vipclassify = (props) => {
    const param = new URLSearchParams(props.location.search);
    const id = param.get('id');

    const [chooseType, setChooseType] = useState();
    const [messageApi, contextHolder] = message.useMessage();
    const [memberInfo, setMemberInfo] = useState({});
    const [apOpen, setApOpen] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        // 清除申请缓存
        localDS.remove('orderParam');
        // 重新验证清华人信息
        const cert = localDS.get('cert');
        queryIsQingHuaPersonByCustomerId(cert?.customerCertId).then((res) => {
            localDS.set('isQ', res.data);
        });

        queryMemberTypeById({ id }).then((res) => {
            const introduce = res?.data;
            setChooseType(introduce);
            // if(records) {
            //     const choose = records.filter(item => {
            //         return item.id === id
            //     })[0]
            //     setChooseType(choose)
            // }
        });
        const memberId = localDS.get('memberId');
        memberId && queryMemberInfoById({ id: memberId }).then(res => {
            setMemberInfo(res?.data);
        })
    }, []);

    const handleApply = () => {
        const memberId = localDS.get('memberId');
        checkHasUnpaidOrder(memberId).then(res => {
            if(res?.data) {
                app.dispatch(
                    $action('confirm').emit({
                        open: true,
                        content: '您有一笔待支付的会员申请订单，请勿重复提交，并于15分钟内完成支付。若想取消该订单，请点击“申请记录”取消订单后再进行其他操作。',
                        okText: '申请记录',
                        cancelText: '关闭',
                        okCallback: () => {
                            history.push('/applyrecords');
                        }
                    })
                );
            } else {
                history.push(`/memberEdit?type=${chooseType?.typeName}&id=${id}`);
            }
        })
        // queryMemberOperationRecord(memberId, 'pendingOrders').then((res) => {
        //     // 先判断不是分享卡订单
        //     const arr =
        //         res?.data?.records &&
        //         res?.data?.records.filter((item) => {
        //             return item.operationType !== 'POI0106';
        //         });
        //         console.log(arr)
        //     if (arr?.length > 0) {
        //         setOpen(true);
        //     } else {
        //         history.push(`/memberEdit?type=${chooseType?.typeName}&id=${id}`);
        //     }
        // });
    };

    return (
        <div className="vip-choose common-bg">
            {contextHolder}
            <Modal
                title="提示"
                open={open}
                onOk={() => {
                    setOpen(false);
                    history.push('/applyrecords');
                }}
                onCancel={() => {
                    setOpen(false);
                }}
                okText="申请记录"
                cancelText="关闭"
            >
                <p>
                    您有待支付的会员申请订单，请勿重复提交会员申请。请您点击“申请记录”按钮查询待支付的会员申请订单，点击“关闭”将返回到信息填写界面。
                </p>
            </Modal>
            <Modal
                title="提示"
                open={apOpen && memberInfo?.state === 1}
                onOk={() => {
                    setApOpen(false);
                    handleApply();
                }}
                onCancel={() => {
                    setApOpen(false);
                }}
                okText="确认"
                cancelText="取消"
            >
                <p>您当前已是会员，再次申请则为续费，续费后的会员权益将在当前会员过期后立即生效，是否确认续费？</p>
            </Modal>
            {chooseType && (
                <div className="vip-choose-cont">
                    <p className="cost">
                        <img src={memberIcon} alt="" />
                        <span>会费为{chooseType?.memberCost}元/年</span>
                    </p>
                    <div
                        className="intro"
                        dangerouslySetInnerHTML={{
                            __html: chooseType?.introduce
                        }}
                    />

                    <div className="btn-group-inner">
                        <Button
                            onClick={() => {
                                // if(memberInfo?.state === 1) {
                                //     // setApOpen(true)
                                //     app.dispatch(
                                //         $action('confirm').emit({
                                //             open: true,
                                //             content: '您当前已是会员，再次申请则为续费，续费后的会员权益将在当前会员过期后立即生效，是否确认续费？',
                                //             okText: '确认',
                                //             cancelText: '取消',
                                //             okCallback: () => {
                                //                 handleApply();
                                //             }
                                //         })
                                //     );
                                // } else {
                                //     handleApply();
                                // }
                                handleApply();
                            }}
                        >
                            {`申请成为${chooseType?.typeName}`}
                        </Button>
                        {/* <Button
                         onClick={() => {
                            history.push(`/purchasecard?type=${chooseType?.typeName}&id=${id}`)

                            // 判断
                            // wx.miniProgram.navigateTo({url: "../userCenter/userCenter?isWebviewBack=true&id=2&memberId=5aee32778db915c6d4663bb80aab42fb&cost=0.01&orderId=d32b85c5455fe88f5d2da7bd3ffa1270"})

                            // 支付
                            // const data = {
                            //     "bizContent": "{\"extraCommonParam\":\"poi\",\"moneyTypeId\":\"0\",\"outTradeNo\":\"202306090941431606666609796040#yKe15aUp\",\"partnerId\":\"295\",\"paymentSceneId\":\"0108\",\"timeout\":\"15\",\"tradeAmount\":\"0.01\",\"tradeName\":\"会员申请\"}",
                            //     "charset": "UTF-8",
                            //     "method": "trade.pay.microapp",
                            //     "notifyUrl": "https://qhtest.cncis.com.cn:8118/sapi/qh/recieveMessage",
                            //     "paymentItemId": "201510",
                            //     "sign": "XyCFLQT9bFaFGWO6t8fG/rt+ughvaV2gsaBED/bcPUriKThS7l1ayaz4JZU556xM1PzSuvtONtbqoIj9OA5a3Jk+MATkazeiX3XgLXVRNSi3BkGMDnj/hEXenos1Gvw4gd3DM9h6rvXptJqQASZxXlfD4XI8ElSmbIiFQ8QqVE7hiZb/JTBEOsUxtpbdoZSiu01b4au99nkPiHYjiUD5Jas1MdneYngJcKyxM0Ix8JHFY2Z9kb6NWVQ+UVDplinDXmVsvCsZP1IcvCo0QgZAvlQKZbB70AExExspSD1aYLxr8xci8ysUEZxir+P5sE05m3Uy6+t1385QZaK+yr2h2Q==",
                            //     "signType": "RSA",
                            //     "version": "2.0"
                            // }
                            // const obj = encodeURIComponent(JSON.stringify(data))
                            // wx.miniProgram.navigateTo({url: `../main/vipPay?isWebviewBack=true&obj=${obj}&orderId=e527b0e6b9768fcdd35c3fe7aa1b7e03&type=applypass`})

                            // 分享
                            // const jumpUrl=encodeURIComponent('/addshare?memberid=3804348f823c2318663c7648288a11db&orderId=aadc816232d3b7ef7b6d6431c73dffe2');
                            // const initUrl=encodeURIComponent('/addMembers?orderId=aadc816232d3b7ef7b6d6431c73dffe2');
                            // wx.miniProgram.navigateTo({url: `../main/shareMembers?isWebviewBack=true&jumpUrl=${jumpUrl}&initUrl=${initUrl}`})
                        }}
                     >
                        买卡送人
                     </Button> */}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Vipclassify;
