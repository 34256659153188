import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import app, { $action } from './model';
import App from './App';
import { history } from './routes';
import './index.scss';
import '@portal-site/pager/dist/style.css';
import zhCN from 'antd/locale/zh_CN';
// import { localDS } from './storage';

// eslint-disable-next-line no-unused-vars
import * as Sentry from '@sentry/react';
import { ThemeProvider } from 'emotion-theming';
import { colors } from './_fix_/theme';

import { ConfigProvider } from 'antd';
// if (process.env.NODE_ENV === 'production') {
//     Sentry.init({
//         dsn: 'https://e2f7e2a095f64417861fc452ce61c4cf@o335011.ingest.sentry.io/5338806',
//         release: `${process.env.APP_NAME}${process.env.APP_VERSION}`
//     });
// }
function setup() {
    // if (localDS.has('token')) {
    //     app.dispatch($action('auth').reSignIn());
    // }
    // app.dispatch($action('rules').getRules());
    // app.dispatch($action('teamRules').getTeamRules());
}

class ErrorBoundary extends React.Component {
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // eslint-disable-next-line no-console
        // console.log(error, errorInfo);
    }

    render() {
        //   if (this.state.hasError) {
        //     // You can render any custom fallback UI
        //     return <h1>Something went wrong.</h1>;
        //   }

        return this.props.children;
    }
}

ReactDOM.render(
    <Provider store={app.store}>
        <ErrorBoundary>
            <ConfigProvider
                theme={{
                    token: { colorPrimary: '#f8c056' }
                }}
                locale={zhCN}
            >
                <Router history={history}>
                    <ThemeProvider
                        theme={{
                            colors: { ...colors }
                        }}
                    >
                        <App />
                    </ThemeProvider>
                </Router>
            </ConfigProvider>
        </ErrorBoundary>
    </Provider>,
    document.getElementById('root')
);
setup();
