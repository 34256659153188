/* eslint-disable complexity */
/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './style.scss';

const Personal = () => {
    useEffect(() => {
        const ticket = new URLSearchParams(window.location.search).get('ticket');
        const obj = encodeURIComponent(JSON.stringify(ticket));
        wx.miniProgram.navigateTo({url: `../act/activityAppoint?ticket=${obj}`});
    }, [])
    return (
        <>
            <div className="personal2">
                <div className="personal-box">
                <div className="personmsg" />
                </div>
            </div>
            <ul className="lists">
                <li className="list-item">
                    <Link to="/personal2">
                        <i className="iconfont icon-wodeshenqing" />
                        <span>申请记录</span>
                        <i className='iconfont icon-jiantou' />
                    </Link>
                </li>

                <li className="list-item">
                    <Link to="/personal2">
                        <i className="iconfont icon-lipinka" />
                        <span>我的分享卡</span>
                        <i className='iconfont icon-jiantou' />
                    </Link>
                </li>
                <li className="list-item">
                    <Link to="/personal2">
                        <i className="iconfont icon-lipin" />
                        <span>我的礼物</span>
                        <i className='iconfont icon-jiantou' />
                    </Link>
                </li>
                <li className="list-item">
                    <Link to="/personal2">
                        <i className="iconfont icon-zhanghaobangding" />
                        <span>绑定会员卡</span>
                        <i className='iconfont icon-jiantou' />
                    </Link>
                </li>
            </ul>
        </>

    )
}

export default Personal;
