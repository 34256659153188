import React, { Component } from 'react';
import { Layer } from '@portal-site/layer';
import './style.scss';
import { Button } from '../Button';
import { connect } from 'react-redux';
import { $action } from '../../model';
class Confirm extends Component {
    render() {
        const {
            open,
            okText = '确定',
            cancelText = '取消',
            title = '提示',
            onOKClick,
            onCancelClick,
            content = '',
            okCallback,
            cancelCallback
        } = this.props;
        return (
            <Layer
                // maskStyle={{
                // //   backgroundColor: 'transparent',
                // }}
                layerClassName="g-confirm"
                isOpen={open}
                handleClose={close}
            >
                <div className="confirm-box">
                    <header>{title}</header>
                    <div className="body">{content}</div>
                    <div className="confirm-btn-group">
                        <Button
                            onClick={() => {
                                onOKClick();
                                okCallback();
                            }}
                        >
                            {okText}
                        </Button>
                        <Button
                            onClick={() => {
                                onCancelClick();
                                cancelCallback();
                            }}
                        >
                            {cancelText}
                        </Button>
                    </div>
                </div>
            </Layer>
        );
    }
}
const mapStateToProps = (state) => ({
    open: state.confirm.open,
    title: state.confirm.title,
    okText: state.confirm.okText,
    cancelText: state.confirm.cancelText,
    content: state.confirm.content,
    okCallback: state.confirm.okCallback,
    cancelCallback: state.confirm.cancelCallback
});

const mapDispatchToProps = (dispatch, owsProps) => {
    return {
        onClose: () => dispatch($action('confirm').emit({ open: false })),
        onOKClick: () => {
            dispatch($action('confirm').emit({ open: false }));
            dispatch($action('confirm').ok());
        },
        onCancelClick: () => {
            dispatch($action('confirm').emit({ open: false }));
            dispatch($action('confirm').cancel());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Confirm);
