import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { memberBasicInfo } from '../../service';
import { localDS } from '../../storage';
import './style.scss';

class Entry extends Component {
    state = {
        openLayer: false,
        notice: '',
    };
    componentDidMount() {
        let documentNumber = localDS.get('documentNumber');
        memberBasicInfo(documentNumber).then(res => {
            // console.log(res)
        })
    }
    render() {
        return (
            <div className="entry">
                <div className="entry-item">
                    <Link to="/charter">
                        <h3>
                            <i className='iconfont icon-zhangcheng' />
                        </h3>
                        <p>会员章程</p>
                    </Link>
                </div>
                <div className="entry-item">
                    <Link to="/vipclassify">
                        <h3>
                            <i className='iconfont icon-huiyuanshenqing' />
                        </h3>
                        <p>会员申请</p>
                    </Link>
                </div>
                <div className="entry-item">
                    <Link to="/dynamic">
                        <h3>
                            <i className='iconfont icon-bianji'/>
                        </h3>
                        <p>会员动态</p>
                    </Link>
                </div>
                <div className="entry-item">
                    <Link to="personal">
                        <h3>
                            <i className='iconfont icon-gerenzhongxin'/>
                        </h3>
                        <p>会员中心</p>
                    </Link>
                </div>
                {/* <div className='cnm'>
                    <input type="file" className="ComImgFileObj" capture="camera" accept="image/*"/>
                </div> */}
            </div>
        );
    }
}

export default Entry;
