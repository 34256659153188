/* eslint-disable complexity */
/* eslint-disable no-undef */
import React from 'react';
import { history } from '../../routes';
import grImgsrc from "@/assets/lpk-gr.png";
import jtImgsrc from "@/assets/lpk-jt.png";
import zxImgsrc from "@/assets/lpk-zx.png";
import './style.scss';

const Gift = (props) => {
    const data = props?.data;
    return (
        <div className="item-shadow giftcarditem">
            <div className="cardtype">
                {data?.typeId === '2' && <img src={grImgsrc} alt="" />}
                {data?.typeId === '3' && <img src={jtImgsrc} alt="" />}
                {data?.typeId === '4' && <img src={zxImgsrc} alt="" />}
            </div>
            <ul>
                {/* <li>
                    <span>卡号</span><span>{data?.cardNo ? data.cardNo : data?.virtualCardNo}</span>
                </li> */}
                <li>
                    <span>会员期限</span><span>1年</span>
                </li>
                {(data?.state === 0 || (data?.state === 1 && data?.isReceiver !== 1)) && <li>
                    <span>费用</span><span>￥{data.cost}</span>
                </li>}
                {(data?.state === 0 || data?.state === 1 && data?.isReceiver !== 1) && <li>
                    <span>购买时间</span><span>{data.createTime}</span>
                </li>}
                {(data?.state === 1 || data?.state === 2) && data.receiveTime && <li>
                    <span>领取时间</span><span>{data.receiveTime}</span>
                </li>}
                {data?.isActive === 1 && <li>
                    <span>激活时间</span><span>{data.activeTime}</span>
                </li>}
                {(data?.state === 0 || (data?.state === 1 && data?.isReceiver === 1)) && data.createTime && <li className='long'>
                    <span>最迟激活时间</span><span>{(Number(data.createTime.slice(0, 4)) + 1) + data.createTime.slice(4, 10)}</span>
                </li>}
                {data?.state === 3 && <li>
                    <span>到期时间</span><span>{(Number(data.createTime.slice(0, 4)) + 1) + data.createTime.slice(4, 10)}</span>
                </li>}
            </ul>
            <div className="btn-box">
                {data?.state === 0 && <>
                    <button
                        className="giftBtn lightorange"
                        onClick={() => {
                            // const pre = (data?.typeId === '3') ? 'activefamilyvip' : 'activevip';
                            // history.push(`/${pre}?id=${data?.cardId}&typeId=${data?.typeId}`);
                            // eslint-disable-next-line no-unused-expressions
                            props?.handleActive(data?.cardId, data?.typeId);
                        }}
                    >去激活</button>
                    <button
                        className="giftBtn lightorange"
                        onClick={(e) => {
                            // history.push(`/cardsinfor?id=${data?.typeId}&cardId=${data?.cardId}`);
                            // 赠送跳转
                            const jumpUrl=encodeURIComponent(`/cardsinfor?id=${data?.typeId}&cardId=${data?.cardId}`);
                            const initUrl=encodeURIComponent(`/giftcard`);
                            wx.miniProgram.navigateTo({url: `../main/shareVipCard?isWebviewBack=true&jumpUrl=${jumpUrl}&initUrl=${initUrl}`})
                        }}
                    >去赠送</button>
                </>}
                {data?.state === 1 && (data?.isReceiver === 1 ?
                <button
                    className="giftBtn lightorange"
                    onClick={(e) => {
                        // const pre = (data?.typeId === '3') ? 'activefamilyvip' : 'activevip';
                        // history.push(`/${pre}?id=${data?.cardId}&typeId=${data?.typeId}`);
                        // eslint-disable-next-line no-unused-expressions
                        props?.handleActive(data?.cardId, data?.typeId);
                    }}
                >去激活</button> : <button className="giftBtn gray">已领取</button>)}
                {data?.state === 2 && <button className="giftBtn gray">已激活</button>}
                {data?.state === 3 && <button className="giftBtn gray">已到期</button>}
            </div>
        </div>
    )
}

export default Gift;
