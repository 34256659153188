/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { createOrder } from '../../service';
import { history } from '../../routes';
import { message } from 'antd';
import app, { $action } from '../../model';
import './style.scss';

const Pay = (props) => {
    // console.log(props)
    // console.log(props?.isschool, Number(props?.isschool) === 1 ? 3 : 2)
    // const id = new URLSearchParams(window.location.search).get('id');
    const orderId = new URLSearchParams(window.location.search).get('orderId');
    const [messageApi, contextHolder] = message.useMessage();
    const { cost, payInfor, orderInfo } = props;
    // const timeCount = 10;
    useEffect(() => {
        setCountdown(orderInfo?.ttl);
    }, [orderInfo]);
    let [countdown, setCountdown] = useState(0);
    useEffect(() => {
        const timer = setInterval(() => {
            if (countdown > 0) {
                setCountdown(countdown - 1);
            } else {
                props.changeSubmit();
            }
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, [countdown]);
    const timeFormat = (countdown) => {
        const minute = Math.floor(countdown / 60);
        const second = countdown % 60;
        return `${minute >= 10 ? minute : '0' + minute}分${second >= 10 ? second : '0' + second}秒`;
    };
    const payCost = () => {
        if(!props?.isConfirm) {
            app.dispatch(
                $action('alert').emit({
                    show: true,
                    title: '提示',
                    content: '请认真阅读《会员章程》，勾选确认后再申请办理。'
                })
            );
            props.handleCheckScroll();
            return;
        }
        if (cost) {
            createOrder({ id: orderId }).then((res) => {
                if (res?.code !== 200) {
                    messageApi.open({
                        type: 'error',
                        content: res?.msg
                    });
                    return;
                } else {
                    // 支付跳转
                    // alert(props?.isschool)
                    const openType = Number(props?.isschool) === 1 ? 3 : 2;
                    const obj = encodeURIComponent(JSON.stringify(res?.data));
                    const id = orderId || props?.orderId;
                    wx.miniProgram.navigateTo({
                        url: `../main/vipPay?isWebviewBack=true&openType=${openType}&obj=${obj}&orderId=${id}&type=applypass`
                    });
                }
            });
        } else {
            // 支付跳转
            // alert(props?.isschool)
            const openType = Number(props?.isschool) === 1 ? 3 : 2;
            const obj = encodeURIComponent(JSON.stringify(payInfor));
            const id = orderId || props?.orderId;
            wx.miniProgram.navigateTo({
                url: `../main/vipPay?isWebviewBack=true&openType=${openType}&obj=${obj}&orderId=${id}&type=applypass`
            });
        }
        // addMember(props?.memberMsg).then((res) => {
        //     if(res.code === 200){
        //         messageApi.info("申请成功！");
        //         setTimeout(() => {
        //             history.push(`/memberEquity?type=applypass&id=${id}`);
        //         }, 1.5 * 1000);
        //     }
        // })
    };
    return (
        <div className="pay">
            {contextHolder}
            <div className="pay-countdown">
                支付费用{' '}
                <span className="pricenum"> ￥{cost ? cost : props?.data?.memberCost}</span>
                {countdown > 0 ? (
                    <p>{`支付中（付款倒计时：${timeFormat(countdown)}）`}</p>
                ) : (
                    <p>订单已超时取消</p>
                )}
            </div>

            <div className="pay-btn">
                {countdown > 0 ? (
                    <button
                        className="confirm"
                        onClick={() => {
                            payCost();
                        }}
                    >
                        立即支付
                    </button>
                ) : (
                    <button
                        className="confirm"
                        onClick={() => {
                            history.push('/vipclassify');
                        }}
                    >
                        重新申请
                    </button>
                )}
            </div>
        </div>
    );
};

export default Pay;
