import { take, fork } from 'redux-saga/effects';
function noop() {}
const toast = {
  namespace: 'alert',
  state: {
    show: false,
    btnText: '确定',
    title: '提示',
    content: '',
    callback: noop,
  },
  reducer: {
    emit(state, { payload }) {
      return {
        ...state,
        ...{ callback: noop },
        ...payload,
      };
    },
  },
  effect(action) {
    function* worker() {
      try {
        while (true) {
          yield take('alert/emit');

          //   if (typeof payload.callback === 'function' && !payload.show) {
          //     payload.callback();
          //   }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }
    return function*() {
      yield fork(worker);
    };
  },
};
export default toast;
