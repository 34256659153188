import React, { Component } from 'react';
import { Layer } from '@portal-site/layer';
import './style.scss';
import { Button } from '../Button';
import { connect } from 'react-redux';
import { $action } from '../../model';

class Alert extends Component {
    render() {
        const {
            show,
            btnText = '确定',
            title = '提示',
            close,
            content = '',
            callback,
        } = this.props;
        return (
            <Layer
                maskStyle={{
                    backgroundColor: 'rgba(0, 0, 0, 0.3)'
                }}
                layerClassName="g-alert"
                isOpen={show}
                handleClose={close}
            >
                <div className="alert-box">
                    {/* <span onClick={close} className="alert-close"> */}
                    {/*  + */}
                    {/* </span> */}
                    <header>{title}</header>
                    <div className="body">{content}</div>
                    <div className="alert-btn-group">
                        <Button
                            onClick={() => {
                                close();
                                callback();
                            }}
                        >
                            {btnText}
                        </Button>
                    </div>
                </div>
            </Layer>
        );
    }
}

const mapStateToProps = (state) => ({
    show: state.alert.show,
    title: state.alert.title,
    btnText: state.alert.btnText,
    content: state.alert.content,
    callback: state.alert.callback,
});

const mapDispatchToProps = (dispatch) => {
    return {
        close: () => dispatch($action('alert').emit({ show: false }))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Alert);
