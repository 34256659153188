import React from 'react';
import Physical from './physical';
import ApplyPass from './applypass';
import CardPass from './cardspass';
import './style.scss';

const MemberEquity = (props) => {
    const type = new URLSearchParams(window?.location?.search)?.get("type");
    const orderId = new URLSearchParams(window?.location?.search)?.get("orderId");
    return (
        <div className="member-equity">
            <div className='equity-box allcenter'>
                { type === "physical" && <Physical /> }
                { type === "applypass" && <ApplyPass orderId={orderId} /> }
                { type === "cardspass" && <CardPass orderId={orderId} /> }
            </div>
        </div>
    )
}

export default MemberEquity;
