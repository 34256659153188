// 主色
export const primary = '#6394d6';
// 次色
export const secondary = '#3265aa';
// 其他辅助颜色
export const secondary_light = '#d4e1ef';
export const secondary_lighter = '#edf3fa';

// 字体颜色
export const dark = '#727171';
export const dark_grey = '#aaabab';
export const grey = '#aaabab';
export const light = '#efefef';

// 功能颜色
export const active = primary;
export const disable = dark_grey;
export const error = '#d7000f';
export const link = '#2e58ff';

export const colors = {
    primary,
    secondary,
    secondary_light,
    secondary_lighter,
    dark,
    dark_grey,
    grey,
    light,
    active,
    disable,
    error,
    link
};
