import React, { useEffect } from 'react';
import { getDictionaryByCode } from '../../service';
import './style.scss';

const Vipclassify = () => {
    useEffect(() => {
        // 证件类型
        getDictionaryByCode('RLY_DOCUMENTTYPE_OPTION', 'RLY01').then(res => {
            // eslint-disable-next-line no-console
            console.log(res)
        })
        // 学历分类
        getDictionaryByCode('RLY_EDUCATION_TYPE_OPTION', 'RLY03').then(res => {
            // eslint-disable-next-line no-console
            console.log(res)
        })
    }, [])
    return (
        <div className="vip-classify">
            <div className="top-bg" />
            {/* <FileInput
        //   onTouched={() => setTouched({ businessLicense: true })}
          name="businessLicense"
        //   feedback={touched.businessLicense ? errors.creditCode : ''}
          onFinished={info =>{
            const fileUrl = `${info?.filePath}${info?.fileName}`
            // setFieldValue('businessLicense', fileUrl); ${IMG_PREURL}
          }
          }
        //   imgsrc={data?.businessLicense}
          label="会员照片"
          code="VIP_PHOTO"
         /> */}
        </div>
    );
}

export default Vipclassify;
