import React, { useState, useEffect, useRef } from 'react';
import { localDS } from '../../storage';
import { uploadAvatar, getDictionaryByCode, addFamily, queryFamilyInfoById, updateFamily, memberBasicInfo, queryMemberTypeById, queryMemberById, getOrderById, queryMemberInfoById } from '../../service';
import { Select, Input, Upload, Form, DatePicker, message, Modal, Image } from 'antd';
import dayjs from 'dayjs';
import { DocToBirth, isIDNum } from '../../utils/validator';
import 'dayjs/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import './style.scss';

// 不同证件号码的正则表达式
// 这里主要根据type值与接口的字段进行匹配，决定使用哪种正则表达式进行校验，接口字段变化时，改type的值即可
const regs = [
    {
        type: '身份证',
        require: { required: true, message: '请输入身份证号!' },
        pattern: { pattern: /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|30|31)|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/, message: '请输入正确的证件号！' }
    },
    {
        type: '护照',
        require: { required: true, message: '请输入护照号码!' },
        pattern: { pattern: /^([a-zA-z]|[0-9]){5,17}$/, message: '请输入正确的护照号码!' }
    },
    {
        type: '台胞',
        require: { required: true, message: '请输入台湾居民来往大陆通行证号码!' },
        pattern: { pattern: /^\d{8}|^[a-zA-Z0-9]{10}|^\d{18}$/, message: '请输入正确的台湾居民来往大陆通行证号码!' }
    },
    {
        type: '港澳',
        require: { required: true, message: '请输入港澳居民来往内地通行证!' },
        pattern: { pattern: /^([A-Z]\d{6,10}(\(\w{1}\))?)$/, message: '请输入正确的港澳居民来往内地通行证!'}
    },
    {
        type: '军官',
        require: { required: true, message: '请输入军官证!' },
        pattern: { pattern: /^[\u4E00-\u9FA5](字第)([0-9a-zA-Z]{4,8})(号?)$/, message: '请输入正确的军官证!' }
    }
]

const AddShare = () => {
    const formRef = useRef();
    const [cardType, setCardType] = useState([]);
    const [cardDirectionary, setCardDirectionary] = useState([]);
    const [picUrl, setPicUrl] = useState('');
    // const [cardId, setCardId] = useState('');
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [familyInfo, setFamilyInfo] = useState({});
    const [photoShow, setPhotoShow] = useState(true);
    const [orderInfor, setOrderInfor] = useState();
    const [vipinfor, setVipinfor] = useState();
    const [canAddFamily, setCanAddFamily] = useState(true);
    const [tempInfo, setTempInfo] = useState();
    const [memberInfo, setMemberInfo] = useState();
    const [canChooseBirth, setCanChooseBirth] = useState(false);
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const classId = new URLSearchParams(window.location.search).get('id');
    const type = new URLSearchParams(window.location.search).get('type');
    const memberid = new URLSearchParams(window.location.search).get('memberid');
    const orderId = new URLSearchParams(window.location.search).get('orderId');
    const typeId = new URLSearchParams(window.location.search).get('typeId');
    // 将文件信息保存到FileReader中
    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    // 控制图片预览窗口关闭
    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    useEffect(() => {
        // 获取会员家庭成员信息或者携带成员信息
        (async function(){
            if(classId){
                const familyInfo = await queryFamilyInfoById({id: classId});
                setFamilyInfo(familyInfo?.data);
                formRef.current.setFieldsValue({
                    username: familyInfo?.data?.name,
                    // age: familyInfo?.data?.ageType,
                    cardType: familyInfo?.data?.documentType,
                    cardId: familyInfo?.data?.documentNumber,
                    phonenumber: familyInfo?.data?.phone
                })
            }
            const cardType = await getDictionaryByCode("RLY_DOCUMENTTYPE_OPTION", "RLY01");
            let selectOpt = [];
            if(cardType.data && cardType.data.forEach){
                cardType.data.forEach((item) => {
                   if(item?.text?.includes('行驶')){
                        return;
                    }
                selectOpt.push({value: item?.id, label: item?.text});
            })
            setCardDirectionary(selectOpt);
            }
        })();
        queryMemberById(memberid).then(res => {
            const data = res?.data
            if(data) {
                setCanAddFamily(data?.canAddFamily)
            }

            console.log(data?.canAddFamily)
        })
        getOrderById(orderId).then(res => {
            const data = res?.data;
            if(data) {
                const tempInfo = JSON.parse(data?.tempInfo);
                setTempInfo(tempInfo)
                setOrderInfor(data);
                queryMemberTypeById({id: data?.memberType}).then(res => {
                    setVipinfor(res?.data)
                })
                queryMemberInfoById({id:data?.memberId}).then(res => {
                    res?.data && setMemberInfo(res.data);
                })
            }
        })
    }, []);

    const onFinish = function(){
        if(!canAddFamily){
            // 无法添加
            messageApi.open({
                type: 'warning',
                content: '该会员申请已提交，无法继续添加您的信息，请联系申请人核实。',
            });
            return;
        }
        if(!picUrl){
            messageApi.open({
                type: 'error',
                content: '请正确上传图片！',
            });
            return;
        }
        let data = form.getFieldValue();
        let filterData = {
            memberId: orderInfor?.memberId,
            // ageType: data?.age,
            name: data?.username,
            documentType: data?.cardType,
            documentNumber: data?.cardId,
            phone: data?.phonenumber,
            photo: picUrl,
            birthDate: `${data?.birthDate?.$y}/${Number(data?.birthDate?.$M) + 1}/${data?.birthDate?.$D}`,
        };
        if(classId){
            let familyData = {
                id: classId,
                // ageType: filterData?.age,
                name: filterData?.username,
                documentType: filterData?.cardType,
                documentNumber: filterData?.cardId,
                phone: filterData?.phonenumber,
                photo: picUrl
            }
            updateFamily(familyData).then((res) => {
                if(res?.code !== 200){
                    messageApi.open({
                        type: 'error',
                        content: res?.msg
                    })
                    return;
                }else{
                    messageApi.info('提交成功！');
                }
            })
        }else{
            let param = {...filterData}
            if(typeId) {
                param.typeId = typeId
            }
            console.log(param)
            // addFamily(param).then((res) => {
            //     if(res?.code !== 200){
            //         messageApi.open({
            //             type: 'error',
            //             content: res?.msg,
            //         });
            //         return;
            //     }else{
            //         messageApi.info("提交成功！");
            //     }
            // })
        }

    }

    const chooseCardType = function(e){
        let label = '';
        let cardLabel = cardDirectionary.filter((item) => {
            return item?.value === e;
        })
        label = cardLabel?.[0]?.label;
        let filterCardType = regs.filter((item) => {
            return label.includes(item?.type);
        })
        let type = [filterCardType?.[0]?.require, filterCardType?.[0]?.pattern];
        setCardType(type);
        // setCardId(cardLabel?.[0]?.value);
    }
    const choosePic = function(e){
        const size = e.file.size / 1024 / 1024;
        if(size > 5) {
            messageApi.open({
                type: 'error',
                content: '上传图片过大，请上传小于5M的图片',
            });
            e.onError()
        } else {
            setPhotoShow(false);
            let formData = new FormData();
            formData.append('trackData', e.file);
            uploadAvatar(formData, 'VIP_PHOTO').then((res) => {
                setPicUrl(res?.data?.data?.filePath + res?.data?.data?.fileName);
                e.onSuccess(res, e.file);
            })
        }
    }

    const dateFormat = 'YYYY/MM/DD'; // 日期格式
    // 身份证提取出生日期
    const onValuesChange = (val) => {
        let data = form.getFieldValue();
        if(data?.cardType === "RLY0101") {
            setCanChooseBirth(true)
            if(data?.cardId && isIDNum(data?.cardId)) {
                const birthDate = dayjs(DocToBirth(data?.cardId), dateFormat);
                form.setFieldsValue({birthDate})
            }
        } else if(data?.cardType !== "RLY0101") {
            setCanChooseBirth(false)
        }
    }
    return (
        <div className='add-share'>
            <div className="apply-infor">
                <p className="title">申请会员信息</p>
                {vipinfor && <ul>
                    <li><span>会员类型</span>：{vipinfor?.typeName}</li>
                    <li><span>会员姓名</span>：{memberInfo?.isVip === 1? tempInfo?.memberName : memberInfo?.memberName}</li>
                    <li><span>证件号</span>：{memberInfo?.isVip === 1? tempInfo?.documentNumber : memberInfo?.documentNumber}</li>
                    <li><span>手机号码</span>：{memberInfo?.isVip === 1? tempInfo?.phone : memberInfo?.phone}</li>
                </ul>}
                <p className="tip">注：请先确认主会员信息，确认无误后，请如实填写您的信息，否则可能会影响会员相关服务。</p>
            </div>
            {contextHolder}
            <div className="familyEdit">
                <p className="title">您的信息</p>
                <Form
                onFinish={onFinish}
                form={form}
                validateTrigger="onBlur"
                ref={formRef}
                onValuesChange={onValuesChange}
                >
                    {/* <h3>类型</h3> */}
                    {/* <Form.Item
                     className="edit-item"
                     name="age"
                     rules={[
                        { required: true, message: '请选择其中一项！' }
                     ]}
                     >
                        <Radio.Group>
                            <Radio value="1">成年人</Radio>
                            <Radio value="0">未成年</Radio>
                        </Radio.Group>
                    </Form.Item> */}
                    <h3>姓名</h3>
                    <Form.Item
                        name="username"
                        className="edit-item"
                        rules={[
                            { required: true, message: '请输入姓名!' },
                            { pattern: form.getFieldValue()?.cardType === 'RLY0101' ? /^[\u4E00-\u9FA5\uf900-\ufa2d·s]{2,20}$/ : /^[a-zA-Z\u4e00-\u9fa5]+$/, message: '请输入正确的姓名！' },
                        ]}
                        >
                        <Input placeholder="请输入姓名" disabled={!!classId}/>
                    </Form.Item>
                        <h3>证件类型</h3>
                        <Form.Item
                            className="edit-item"
                            name="cardType"
                            rules={[
                                { required: true, message: '请选择其中一项！' }
                            ]}
                        >
                            <Select
                                defaultValue="请选择类型"
                                className="edit-select"
                                onChange={ chooseCardType }
                                options={cardDirectionary}
                                disabled={!!classId}
                            />
                        </Form.Item>
                    <h3>证件号码</h3>
                    <Form.Item
                        name="cardId"
                        className="edit-item"
                        rules = {
                            cardType
                        }
                        >
                        <Input placeholder={ cardType?.[0]?.message?.slice(0, cardType?.[0]?.message?.length - 1) } disabled={!!classId}/>
                    </Form.Item>
                    <h3>出生日期</h3>
                    <Form.Item
                        name="birthDate"
                        className="edit-item"
                        rules={[{ required: true, message: '请选择出生日期！' }]}
                    >
                        <DatePicker
                            locale={locale}
                            format={dateFormat}
                            placeholder="请选择出生日期"
                            inputReadOnly
                            disabled={canChooseBirth}
                        />
                    </Form.Item>
                    <h3>联系电话</h3>
                    <Form.Item
                        name="phonenumber"
                        className="edit-item"
                        rules={[
                            { required: true, message: '请输入手机号!' },
                            { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号！' },
                        ]}
                        >
                        <Input placeholder="请输入手机号"/>
                    </Form.Item>
                    <h3>照片<span>（请上传本人照片，否则影响相关会员服务)</span></h3>
                    <div className="photoGroup">
                        {photoShow && familyInfo?.photo && (<Image src={`${window.FILE_IMG_URL}${familyInfo?.photo}`} />)}
                        <Form.Item
                            className="edit-item"
                            name="photo"
                            rules={[
                                { required: true, message: '请上传一张照片' }
                            ]}
                        >
                            <Upload
                                action={`${window.FILE_IMG_URL}/file/normalUploadByCode/VIP_PHOTO/vip/800af9b25ca04edcae85ea8616c6ddac`}
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader uploadBox"
                                showUploadList={true}
                                onPreview={handlePreview}
                                // beforeUpload={choosePic}
                                customRequest={choosePic}
                                maxCount={1}
                                capture="user"
                                accept="image/*"
                            ><span className='upload-icon'>+</span></Upload>
                        </Form.Item>
                    </div>

                    <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                        <img
                            alt="example"
                            style={{
                                width: '100%',
                            }}
                            src={previewImage}
                        />
                    </Modal>
                    <div className='btn-group'>
                        <button className="confirm" htmltype="submit">提交</button>
                    </div>
                </Form>
                <div className="empty" />
            </div>
        </div>

    )
}

export default AddShare;
