const err = {
  namespace: 'err',
  state: {
    show: false,
  },
  reducer: {
    emit(state, { payload }) {
      return {
        ...payload,
      };
    },
  },
};
export default err;
